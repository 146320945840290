import { Formik, Form } from 'formik';
import { useState, ChangeEvent, useEffect } from 'react';
import Select from 'components/inputs/Select';
import { usePharmacies } from 'data/usePharmacies';
import { Prescription, updatePrescription } from 'data/usePrescriptions';

type Pharmacy = {
  id: number;
  name: string;
};

export default function PrescriptionDocumentDeliveryForm({
  prescription,
  handleRefetchData,
}: {
  prescription: Prescription;
  handleRefetchData: () => void;
}) {
  const [documentDestination, setDocumentDestination] = useState({
    value: prescription?.document_destination || '',
    label: '',
  });
  const [selectedPharmacy, setSelectedPharmacy] = useState({
    value: prescription?.pharmacy || '',
    label: '',
  });
  const [pharmacies, setPharmacies] = useState([]) as any;
  const { data } = usePharmacies();
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [finishedLoadingDestination, setFinishedLoadingDestination] = useState(true);
  const [loadingPharmacy, setLoadingPharmacy] = useState(false);
  const [finishedLoadingPharmacy, setFinishedLoadingPharmacy] = useState(true);

  const handleSelect = async (e: ChangeEvent) => {
    e.preventDefault();
    const value = (e.target as HTMLSelectElement).value;
    const name = e.target.getAttribute('name');
    if (name === 'documentDestination') {
      const payload = { id: prescription.id, document_destination: value };
      setDocumentDestination({ label: value, value: value });
      try {
        setLoadingDestination(true);
        setFinishedLoadingDestination(false);
        await updatePrescription(payload);
        // clear any selected pharmacies
        if (selectedPharmacy.value !== '') {
          setSelectedPharmacy({ label: '', value: '' });
          await updatePrescription({ id: prescription.id, pharmacy: null });
        }
        handleRefetchData();
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoadingDestination(false);
      }
    } else {
      const payload = { id: prescription.id, pharmacy: value === '' ? null : value };
      setSelectedPharmacy({ label: '', value: value });
      try {
        setLoadingPharmacy(true);
        setFinishedLoadingPharmacy(false);
        await updatePrescription(payload);
        handleRefetchData();
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoadingPharmacy(false);
      }
    }
  };

  useEffect(() => {
    if (data?.length) {
      const pharmacies = data.map((item: Pharmacy) => ({
        value: item.id,
        label: item.name,
      }));
      pharmacies.unshift({ value: '', label: '' });
      setPharmacies(pharmacies);
    }
  }, [data]);

  useEffect(() => {
    let timeout: any;
    if (!loadingDestination && !finishedLoadingDestination) {
      timeout = setTimeout(() => setFinishedLoadingDestination(true), 2000);
    }
    if (!loadingPharmacy && !finishedLoadingPharmacy) {
      timeout = setTimeout(() => setFinishedLoadingPharmacy(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [
    finishedLoadingDestination,
    setFinishedLoadingDestination,
    loadingDestination,
    finishedLoadingPharmacy,
    setFinishedLoadingPharmacy,
    loadingPharmacy,
  ]);

  return (
    <Formik onSubmit={() => {}} initialValues={{}} enableReinitialize>
      {(formikProps) => (
        <Form className=" grid grid-cols-3 gap-9 items-end">
          <Select
            name="documentDestination"
            label="Rezept an"
            options={[
              { value: '', label: '' },
              { value: 'patient', label: 'Patient' },
              { value: 'pharmacy', label: 'Apotheke' },
            ]}
            onChange={handleSelect}
            loading={loadingDestination}
            finished={finishedLoadingDestination}
            value={documentDestination.value}
          />
          {documentDestination.value === 'pharmacy' && (
            <Select
              name="selectPharmacy"
              label="Apotheke wählen"
              options={pharmacies}
              onChange={handleSelect}
              defaultValue={selectedPharmacy.value}
              loading={loadingPharmacy}
              finished={finishedLoadingPharmacy}
              value={selectedPharmacy.value}
            />
          )}
        </Form>
      )}
    </Formik>
  );
}
