import classNames from "classnames";
import TextArea from "components/inputs/TextArea";
import Spinner from "components/Spinner";
import useUser from "data/useUser";
import {format } from 'date-fns';
import { useFormik } from "formik";
import { useState } from "react";
import { createNote, useNotes } from 'data/useNotes';

export default function NoteCreateForm(
  {
    setShowForm, patientId
  } : {
    setShowForm: (value: boolean) => any;
    patientId?: number | any;
  }
) {
  const { user: { managed_doctor } } = useUser();
  const date = format(new Date(), 'dd.MM.yyyy');
  const [loading, setLoading] = useState(false);
  const { mutate: mutateNotes } = useNotes(patientId);
  const formik = useFormik({
    initialValues: {
      note: '',
    },
    onSubmit: async (values: any) => {
      if (values.note && values.note !== '') {
        setLoading(true);
        await createNote({ patient: patientId, content: values.note });
        await mutateNotes();
        setShowForm(false);
        setLoading(false);
      }
    },
  });

  return (
    <div className="px-4 py-2 pb-5 border-b border-swopa-border mb-3">
      <div className="flex">
        <div className="grow">
          <span>{managed_doctor.label}</span>
        </div>
        <div className="flex-none text-swopa-grey-4">{date}</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextArea
          label={""}
          name="note"
          id="note"
          placeholder="Neue Notiz..."
          className="mb-4"
          loading={loading}
          finished={!loading}
          onChange={formik.handleChange}
          value={formik.values.note}
        />
      </form>
      <div className="grid grid-cols-2 gap-4">
        <button
          className={classNames(
            'bg-swopa-secondary-light-blue text-white rounded py-3 px-8 mr-4 font-radikal hover:ease-in-out duration-300 hover:bg-[#2B81EC] text-xs uppercase min-w-[130px] flex justify-center',
          )}
          onClick={() => formik.submitForm()}
        >
          {loading ? <Spinner className="fill-white w-4 h-4" /> : 'Speichern'}
        </button>
        <button
          className={'text-swopa-grey-4'}
          onClick={() => setShowForm(false)}
        >
          Abbrechen
        </button>
      </div>
    </div>
  );
}
