import { useState, useEffect, useRef } from 'react';
import { getDiagnosisCertaintyLetter } from 'utils/getDiagnosisCertaintyLetter';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { diagnosisCertaintyLevels } from 'utils/diagnosisCertaintyLevels';
import { Patient } from 'data/usePatients';
import CertaintySymbol from './CertaintySymbol';

type Props = {
  diagnosis: any;
  patient: Patient | undefined;
  deletePatientDiagnosis: (obj: any) => any;
  updatePatientDiagnosis: (obj: any) => any;
  mutatePatient: () => any;
  refreshState: () => any;
};

export default function DiagnosisInfoBox(props: Props) {
  const {
    diagnosis,
    patient,
    deletePatientDiagnosis,
    mutatePatient,
    refreshState,
    updatePatientDiagnosis,
  } = props;
  const [showCertaintyList, setShowCertaintyList] = useState(false);
  const listElemRef = useRef() as any;
  const triggerElemRef = useRef() as any;

  const handleShowCertaintyList = () => setShowCertaintyList(!showCertaintyList);

  const handleHideCertaintyList = () => setShowCertaintyList(false);

  const handleClickOutside = (e: any) => {
    const listElem = listElemRef.current;
    const button = triggerElemRef.current;
    if (listElem) {
      if (
        button === e.target ||
        button.contains(e.target) ||
        listElem === e.target ||
        listElem.contains(e.target)
      ) {
        return;
      }
      setShowCertaintyList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleUpdateDiagnosisCertainty = async (payload: any) => {
    const { certainty, diagnosisId, patient } = payload;
    await updatePatientDiagnosis({
      certainty,
      diagnosis: diagnosisId,
      patient,
    });
    diagnosis.certainty = certainty;
    setShowCertaintyList(false);
    await mutatePatient();
    await refreshState();
  };

  return (
    <div className="relative">
      <button
        ref={triggerElemRef}
        onClick={handleShowCertaintyList}
        className="inline-flex group px-4 py-2 uppercase font-radikal font-bold text-xxs rounded-md text-swopa-secondary-light-blue bg-opacity-10 bg-swopa-secondary-light-blue"
      >
        <CertaintySymbol
          certainty={getDiagnosisCertaintyLetter(diagnosis.certainty)}
          className="mr-1 group-hover:hidden"
        />
        <EditIcon className="mr-1 stroke-swopa-secondary-light-blue hidden group-hover:block w-4 h-4" />
        <span>
          {diagnosis.diagnose.name} ({diagnosis.diagnose.icd_10_gm})
        </span>
      </button>
      {showCertaintyList && (
        <div ref={listElemRef} className="absolute z-10 left-0 top-10 bg-white rounded shadow-md">
          <ul>
            <span className="arrow_box !absolute -z-[1] left-5"></span>
            {diagnosisCertaintyLevels.map((level, i) => (
              <li
                className="py-2 px-3 flex items-center hover:bg-swopa-grey-1 cursor-pointer first-of-type:rounded-t-[4px]"
                onClick={() =>
                  handleUpdateDiagnosisCertainty({
                    certainty: level.value,
                    diagnosisId: diagnosis.id,
                    patient,
                  })
                }
                key={i}
              >
                <CertaintySymbol certainty={level.shortname} className="mr-1" />
                <span className="text-swopa-primary-dark">{level.name}</span>
              </li>
            ))}
          </ul>
          <div className="py-3 px-4 border-t-[1px] text-center flex flex-col">
            <button
              onClick={async () => {
                if (patient) {
                  await deletePatientDiagnosis({
                    patient,
                    diagnosis: diagnosis.id,
                  });
                  await mutatePatient();
                  refreshState();
                }
              }}
              className="mb-2 py-1 px-4 bg-swopa-warning-red hover:bg-swopa-warning-red-hover text-xxs font-radikal uppercase text-white rounded"
            >
              Löschen
            </button>
            <button onClick={handleHideCertaintyList} className="text-xs text-swopa-grey-4">
              Abbrechen
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
