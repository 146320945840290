import classNames from 'classnames';
import { ReactComponent as Refresh } from 'icons/refresh.svg';

export type BmiMeterProps = {
  bmi: Array<string | number>;
  handleRefetchData: () => any;
};

export default function BmiMeter(props: BmiMeterProps) {
  const { bmi, handleRefetchData } = props;

  const calculateBMI = (e: any) => {
    e.preventDefault();
    handleRefetchData();
  };

  return (
    <div className="inline-block">
      <label className="text-xs mb-2 flex items-center">
        BMI{' '}
        <button onClick={calculateBMI} className="ml-2 flex items-center">
          <Refresh className="w-4 h-4 fill-swopa-secondary-grey" />
        </button>
      </label>
      <div className="text-swopa-primary-dark-blue w-[254.5px] mb-4">
        <div
          className={classNames(
            'mb-[4px] rounded-[4px] leading[24px] w-full text-center font-bold h-[24px]',
            {
              'bg-[#16DF9F14] text-[#16DF9F]': bmi[1] === 'ideal',
              'bg-[#ECECEC]': bmi[1] === 'na',
              'bg-[#E9082A14] text-[#E9082A]': bmi[1].toString().indexOf('weight') !== -1,
            },
          )}
        >
          {bmi[0] > 0 ? bmi[0] : ''}
        </div>
        <div className="grid grid-cols-4 gap-[4px]">
          <div
            className={classNames('border-b-[2px]', {
              'border-[#E9082A]': bmi[1] === 'underweight',
            })}
          ></div>
          <div
            className={classNames('border-b-[2px] col-span-2', {
              'border-[#16DF9F]': bmi[1] === 'ideal',
            })}
          ></div>
          <div
            className={classNames('border-b-[2px]', {
              'border-[#E9082A]': bmi[1] === 'overweight',
            })}
          ></div>
        </div>
      </div>
    </div>
  );
}
