import classNames from 'classnames';

import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg';
import { Doctor } from '../../types/doctor';

type SelectProps = {
  options: Array<Doctor>;
  handleChange: (e: any) => any;
  name: string;
  label?: string;
  selectedValue?: any;
  error?: boolean;
  className?: string;
};

export default function Select(props: SelectProps) {
  const { options, handleChange, name, label, error, className, selectedValue } = props;
  return (
    <div className="flex flex-col max-w-[50%] mb-6">
      {label && (
        <span
          className={classNames('text-swopa-primary-dark-blue text-xs mb-2 block', {
            'text-swopa-warning-red': error,
          })}
        >
          {label}
        </span>
      )}
      <div className="relative">
        <ArrowDown className="absolute top-[50%] translate-y-[-50%] right-4 stroke-swopa-secondary-grey pointer-events-none" />
        <select
          onChange={handleChange}
          className={classNames(
            'w-full border-solid border-swopa-grey-3 border bg-white rounded-md py-3 px-3 bg-swopa-white text-xs focus:outline-none focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue mr-4',
            className,
          )}
          name={name}
          value={selectedValue}
        >
          {options.map((option, i) => (
            <option key={i} value={option.id}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
