import { ReactComponent as Warning } from '../../icons/warning.svg';
import { ReactComponent as Bracket } from '../../icons/bracket-rounded.svg';

export const TherapyStatusErrorMessage = ({ error }: { error: any }) => {
  const hasPrescriptionError = [
    error.amount_error,
    error.share_of_thc_error,
    error.share_of_cbd_error,
    error.procedure_error,
  ].some((item) => Boolean(item) === true);
  return (
    <div className="bg-[#fbd8dd] border border-swopa-warning-red rounded p-4 mt-4">
      <span className="text-swopa-warning-red text-xs font-semibold mb-2 flex">
        <Warning className="mr-2" /> Bitte füllen Sie folgende Felder aus, um den Therapie-Status
        setzen zu können:
      </span>
      <div className="pl-8">
        {error.diagnosis_error && (
          <div className="mb-2">
            <ul className="pl-4 list-disc">
              <li className="text-swopa-warning-red text-xs mb-2">Diagnose:</li>
            </ul>
            <ul className="pl-4">
              <li className="text-swopa-warning-red font-semibold text-xs flex">
                <Bracket className="mr-2" /> {error.diagnosis_error}
              </li>
            </ul>
          </div>
        )}
        {error.indication_for_cannabis_therapy_error && (
          <div className="mb-2">
            <ul className="pl-4 list-disc">
              <li className="text-swopa-warning-red text-xs mb-2">Indikation:</li>
            </ul>
            <ul className="pl-4">
              <li className="text-swopa-warning-red font-semibold text-xs flex">
                <Bracket className="mr-2" /> {error.indication_for_cannabis_therapy_error}
              </li>
            </ul>
          </div>
        )}
        {hasPrescriptionError && (
          <div>
            <ul className="pl-4 list-disc">
              <li className="text-swopa-warning-red text-xs mb-2">Verschreibung:</li>
            </ul>
            <ul className="pl-4">
              {error.amount_error && (
                <li className="text-swopa-warning-red font-semibold text-xs mb-2 flex">
                  <Bracket className="mr-2" /> {error.amount_error}
                </li>
              )}
              {error.share_of_thc_error && (
                <li className="text-swopa-warning-red font-semibold text-xs mb-2 flex">
                  <Bracket className="mr-2" /> {error.share_of_thc_error}
                </li>
              )}
              {error.share_of_cbd_error && (
                <li className="text-swopa-warning-red font-semibold text-xs mb-2 flex">
                  <Bracket className="mr-2" /> {error.share_of_cbd_error}
                </li>
              )}
              {error.procedure_error && (
                <li className="text-swopa-warning-red font-semibold text-xs flex">
                  <Bracket className="mr-2" /> {error.procedure_error}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
