import { ReactComponent as TrashcanIcon } from 'icons/trashcan.svg';
import classNames from 'classnames';

export default function TrashIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <TrashcanIcon
      {...props}
      className={classNames(
        'text-swopa-grey-4 hover:text-swopa-warning-red cursor-pointer',
        props.className
      )}
    />
  );
}
