import { format, isToday } from 'date-fns';
import { useState, useContext, useMemo } from 'react';
import classnames from 'classnames';

import StatusForm from './forms/StatusForm';
import { Patient } from 'data/usePatients';
import PatientActions from './PatientActions';
import SectionFeed from './sections';
import {
  updateAppointmentState,
  updateTherapyState,
  Appointment as AppointmentInfo,
} from 'data/useAppointments';
import { GlobalErrorContext } from 'contexts/GlobalError';
import http from 'components/Profile/http';
import useValidateTherapyStatuses from 'hooks/useValidateTherapyStatuses';
import { TherapyStatusErrorMessage } from './ErrorMessages';
import Consultation from 'components/Consultation/Consultation';

const appointmentStatuses = [
  {
    value: 'created',
    label: 'Bitte auswählen',
  },
  {
    value: 'attended',
    label: 'Erschienen',
    color: 'bg-swopa-accent-green',
  },
  {
    value: 'not_attended',
    label: 'Nicht erschienen',
    color: 'bg-swopa-primary-orange',
  },
  {
    value: 'cancelled',
    label: 'Annulliert',
    color: 'bg-swopa-warning-red',
  },
];

const therapyStatuses = [
  {
    value: 'not_set',
    label: 'Bitte auswählen',
  },
  {
    value: 'waiting_for_feedback',
    label: 'Warten auf Patientenrückmeldung',
    color: 'bg-swopa-primary-orange',
  },
  {
    value: 'started_continued',
    label: 'Starten/fortführen',
    color: 'bg-swopa-secondary-light-blue',
  },
  {
    value: 'denied',
    label: 'Ablehnen',
    color: 'bg-swopa-warning-red',
  },
  {
    value: 'paused',
    label: 'Pausieren',
    color: 'bg-swopa-primary-orange',
  },
  {
    value: 'finished',
    label: 'Abgeschlossen',
    color: 'bg-swopa-accent-green',
  },
  {
    value: 'consultation_requested',
    label: 'Konsil ausstehend',
    color: 'bg-swopa-primary-orange',
  },
];

export default function Appointment({
  appointmentInfo,
  patient,
  handleRefetchData,
}: {
  appointmentInfo: AppointmentInfo;
  patient: Patient;
  handleRefetchData: () => void;
}) {
  const { handleValidate } = useValidateTherapyStatuses({
    patientId: patient.id,
    appointmentId: appointmentInfo.id,
  });
  const { created_at, format: meetingFormat, doctor, state, therapy_state, id , practice} = appointmentInfo;
  const doctorFirstName = doctor?.first_name || '';
  const doctorLastName = doctor?.last_name || '';
  const [savingAppointmentState, setSavingAppointmentState] = useState(false);
  const [finishedSavingAppointmentState, setFinishedSavingAppointmentState] = useState(true);
  const [savingTherapyState, setSavingTherapyState] = useState(false);
  const [finishedSavingTherapyState, setFinishedSavingTherapyState] = useState(true);
  const [therapyValidationError, setTherapyValidationError] = useState(false) as any;
  const { setError } = useContext(GlobalErrorContext);

  const handleMeetingFormat = (meetingFormat: string) => {
    switch (meetingFormat) {
      case 'in_person':
        return 'Vor Ort';
      case 'video_consultation':
        return 'Videoanruf';
      default:
        return 'n/a';
    }
  };

  const handleUpdateAppointmentState = async (state: string) => {
    try {
      setSavingAppointmentState(true);
      setFinishedSavingAppointmentState(false);
      await updateAppointmentState({ id, state });
      setSavingAppointmentState(false);
      handleRefetchData();
    } catch (e: any) {
      setSavingAppointmentState(false);
      setError(e);
    }
  };

  const handleUpdateTherapyState = async (state: string) => {
    let error = false;
    if (state === 'started_continued') {
      error = (await handleValidate()) as any;
      setTherapyValidationError(error);
    } else {
      error = false;
      setTherapyValidationError(false);
    }
    if (error) return;
    try {
      setSavingTherapyState(true);
      setFinishedSavingTherapyState(false);
      await updateTherapyState({ id, state });
      setSavingTherapyState(false);
      handleRefetchData();
    } catch (e: any) {
      setSavingTherapyState(false);
      setError(e);
    }
  };

  const hasAppointmentToday = useMemo(() => isToday(new Date(created_at)), [created_at]);

  const loadAppointmentLogin = async (appointmentId: number) => {
    const res = await http.get(`medical/appointments/${appointmentId}/auto-login-url/`);
    if (res && res.url) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: res.url,
        rel: 'noopener noreferrer',
      }).click();
    }
  };

  return (
    <>
      <div className="bg-white p-4 border rounded mb-4">
        <ul className="grid grid-cols-4 gap-x-4">
          <li className="flex flex-col">
            <span className="text-swopa-grey-4 text-xs">Datum</span>
            <span className="text-swopa-primary-dark-blue">
              {format(new Date(created_at), 'dd.MM.yyyy')}
            </span>
          </li>
          <li className="flex flex-col">
            <span className="text-swopa-grey-4 text-xs">Uhrzeit</span>
            <span className="text-swopa-primary-dark-blue">
              {format(new Date(created_at), 'HH:mm').concat(' Uhr')}
            </span>
          </li>
          <li className="flex flex-col">
            <span className="text-swopa-grey-4 text-xs">Durchführung</span>
            <span className="text-swopa-primary-dark-blue">
              {handleMeetingFormat(meetingFormat)}
            </span>
          </li>
          <li className="flex flex-col">
            <span className="text-swopa-grey-4 text-xs">Arzt/Ärztin</span>
            <span className="text-swopa-primary-dark-blue">
              {doctorFirstName} {doctorLastName}
            </span>
          </li>
        </ul>
        {meetingFormat === 'video_consultation' && (
          <button
            disabled={!hasAppointmentToday}
            onClick={() => loadAppointmentLogin(id)}
            className={classnames(
              'w-full bg-swopa-secondary-light-blue rounded block text-center p-4 hover:ease-in-out duration-300 hover:bg-[#2B81EC] mt-6 text-white uppercase font-radikal text-xs',
              { 'opacity-50': !hasAppointmentToday },
              { 'pointer-events-none': !hasAppointmentToday },
            )}
          >
            Videoanruf starten
          </button>
        )}
      </div>
      <div className="bg-white p-4 border rounded mb-4">
        <ul className="grid grid-cols-2 gap-x-4">
          <li>
            <StatusForm
              options={appointmentStatuses}
              selectedOption={state}
              tooltip="Patient/-innen werden automatisch per E-Mail informiert."
              label="Patient erschienen?"
              name="erschienen"
              handleSubmit={handleUpdateAppointmentState}
              loading={savingAppointmentState}
              finished={finishedSavingAppointmentState}
              setFinished={setFinishedSavingAppointmentState}
            />
          </li>
          <li>
            <StatusForm
              options={therapyStatuses}
              selectedOption={therapy_state}
              tooltip="Patient/-innen werden automatisch per E-Mail informiert."
              label="Therapie"
              name="therapie"
              handleSubmit={handleUpdateTherapyState}
              loading={savingTherapyState}
              finished={finishedSavingTherapyState}
              setFinished={setFinishedSavingTherapyState}
              error={therapyValidationError}
            />
          </li>
        </ul>
        {therapyValidationError && <TherapyStatusErrorMessage error={therapyValidationError} />}
      </div>
      <div className="relative">
        {(appointmentInfo.state === 'created') && (
          <div className="absolute top-0 left-0 w-full h-full bg-white/70 grid place-items-center z-50">
            <div className="text-swopa-primary-dark-blue text-2xl font-radikal p-10 text-center">
              Bitte wählen Sie aus ob der Patient erschienen ist und den Status der Therapie.
            </div>
          </div>
        )}
        <Consultation appointmentId={id} patientId={patient.id} />
        <PatientActions appointmentId={id} patient={patient} doctor={doctor} practice={practice} />
        <SectionFeed appointmentId={id} patient={patient} />
      </div>
    </>
  );
}
