import Button from 'components/Button';
import Input from 'components/inputs/Input';
import BasicInformationForm from 'components/Profile/BasicInformationForm';
import ChangePasswordForm from 'components/Profile/ChangePasswordForm';
import useUser from 'data/useUser';
import { Helmet } from 'react-helmet-async';

export default function Profile() {
  const { logout, user } = useUser();
  return (
    <div>
      <Helmet>
        <title>Enmedify | Ihr Profil</title>
      </Helmet>
      <h1 className="mb-10">Ihr Profil</h1>{' '}
      <label className="text-swopa-secondary-light-blue uppercase text-pre-title font-radikal">
        E-Mail Adresse
      </label>
      <div className="flex flex-col sm:flex-row gap-8">
        <div className="flex flex-col w-[384px] shrink-0">
          <Input bgWhite className="border-swopa-border border" disabled placeholder={user.email} />
        </div>
        <span className="text-swopa-primary-dark-blue">
          Bitte schreiben Sie uns eine E-Mail an service@help.enmedify.com, falls Sie Ihre
          E-Mail-Adresse ändern möchten.
        </span>
      </div>
      <hr className="bg-swopa-border my-10" />
      <BasicInformationForm />
      <hr className="bg-swopa-border my-10" />
      <ChangePasswordForm />
      <hr className="bg-swopa-border my-10" />
      <div className="w-[220px]">
        <Button onClick={logout}>Logout</Button>
      </div>
    </div>
  );
}
