import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useUser from 'data/useUser';
import Login from 'components/Login';
import Layout from 'components/Layout';
import Patients from 'pages/Patients';
import Profile from 'pages/Profile';
import Patient from 'pages/Patient';
import Appointments from 'pages/Appointments';
import NotFound from 'pages/NotFound';
import PublicLayout from 'components/LightboxLayout';
import ResetPassword from 'pages/ResetPassword';
import SWRProvider from 'components/SWRProvider';
import { HelmetProvider } from 'react-helmet-async';
import ResetPasswordConfirm from 'pages/ResetPasswordConfirm';

function App() {
  return (
    <HelmetProvider>
      <SWRProvider>
        <Routes>
          <Route
            path="/"
            element={
              <IndexRedirect>
                <PublicLayout />
              </IndexRedirect>
            }
          >
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="passwort-zuruecksetzen" element={<ResetPasswordConfirm />} />
          </Route>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="patients/:id" element={<Patient />} />
            <Route path="patients" element={<Patients />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </SWRProvider>
    </HelmetProvider>
  );
}

const IndexRedirect = ({ children }: any) => {
  const { loggedOut, loading } = useUser();
  const location = useLocation();

  if (!loggedOut && !loading && location.pathname === '/') {
    return <Navigate to="/patients" />;
  }

  return children;
};

const RequireAuth = ({ children }: any) => {
  const { loggedOut, loading } = useUser();
  const location = useLocation();
  const isPublicRoute = ['/create-account', '/reset-password'].includes(location.pathname);

  if (loading) {
    return null;
  }

  if (loggedOut && !isPublicRoute) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default App;
