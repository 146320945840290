import classNames from 'classnames';
import useLocalizedMarketingPageLinks from '../hooks/useLocalizedMarketingPageLinks';
import useLocalizedCompanyInformation from '../hooks/useLocalizedCompanyInformation';

type Props = {
  invert?: boolean;
};

export default function Footer({ invert }: Props) {
  const year = new Date().getFullYear();
  const { privacy_policy, impressum } = useLocalizedMarketingPageLinks();
  const { company_name } = useLocalizedCompanyInformation();

  return (
    <footer
      className={classNames('self-stretch border-t text-xs', {
        'bg-swopa-secondary-light-blue text-white': invert,
        'text-swopa-secondary-grey': !invert,
      })}
    >
      <div className="container mx-auto flex justify-center items-center flex-col md:flex-row md:justify-between pt-2 pb-6">
        <span>{company_name}</span>
        <span className="flex justify-center items-center flex-col md:flex-row md:pb-0">
          <span className="px-3">
            <a href={impressum} target="_blank" rel="noopener noreferrer">
              Impressum
            </a>
          </span>
          <span className="px-3">
            <a href={privacy_policy} target="_blank" rel="noopener noreferrer">
              Datenschutz
            </a>
          </span>
          <span className="pl-3">©{year}</span>
        </span>
      </div>
    </footer>
  );
}
