import { useState, useEffect, useCallback } from 'react';

export default function useShowUploadOverlayState(resetSignal: any) {
  // any child element fires a dragenter and dragleave when dragging a file around
  // counting drag events is an easy way to make sure to not make the screen "flicker"
  const [dragging, setDragging] = useState(0);
  const onDragEnter = useCallback(() => setDragging((p) => p + 1), []);
  const onDragLeave = useCallback(() => setDragging((p) => p - 1), []);

  useEffect(() => setDragging(0), [resetSignal]);

  useEffect(() => {
    document.body.addEventListener('dragenter', onDragEnter, true);
    document.body.addEventListener('dragleave', onDragLeave, true);
    return () => {
      document.body.removeEventListener('dragenter', onDragEnter, true);
      document.body.removeEventListener('dragleave', onDragLeave, true);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return dragging !== 0;
}
