import classNames from 'classnames';
import { ReactNode } from 'react';
import Spinner from '../Spinner';
import UnderlineInput from './UnderlineInput';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { error?: string; value: number; label: ReactNode; loading?: boolean; finished?: boolean };

export default function ProgressInput({ label, loading, finished, ...rest }: Props) {
  return (
    <div className="text-swopa-secondary-light-blue">
      <label className="text-swopa-primary-dark-blue">{label}</label>
      <div className="flex items-center">
        <input type="range" {...rest} className={classNames('mr-2')} />
        <UnderlineInput
          inputClassName="px-2 text-center text-lg max-w-[60px]"
          type="number"
          {...rest}
        />
        {loading ? (
          <Spinner className="fill-current ml-1" />
        ) : (
          <>{!finished && <CheckMark className="ml-1" />}</>
        )}
      </div>
    </div>
  );
}
