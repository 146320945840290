import classNames from 'classnames';
import { ReactNode, useRef, useState, useEffect, ChangeEvent } from 'react';
import Spinner from './Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import PublicTooltip from './PublicTooltip';
import { ReactComponent as Info } from 'icons/info3.svg';

export type SuffixInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  error?: string;
  loading?: boolean;
  finished?: boolean;
  isPublic?: boolean;
  isFullWidth?: boolean;
  overwriteList?: string[];
  avoidOverwriteList?: string[];
  label: string;
  suffix: ReactNode;
};

export default function SuffixInput(props: SuffixInputProps) {
  const {
    error,
    label,
    loading,
    finished,
    suffix,
    isPublic,
    overwriteList,
    avoidOverwriteList,
    ...rest
  } = props;

  const { onChange } = rest;
  const [isOverwritten, setIsOverwritten] = useState<boolean>(false);

  const doChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setIsOverwritten(false);
    onChange && onChange(ev);
  };

  useEffect(() => {
    if (overwriteList) {
      setIsOverwritten(overwriteList?.indexOf(props.name || '') !== -1);
    } else {
      setIsOverwritten(false);
    }
  }, [overwriteList]);

  const className = classNames(
    `rounded-tl-md rounded-bl-md text-xs p-2 bg-swopa-white text-sm focus:outline-none focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue`,
    {
      'text-swopa-primary-dark-blue': !error,
      'text-swopa-warning-red border-current': error,
      'w-full': rest.isFullWidth || false,
    },
    {
      'border-solid border-swopa-grey-3 border-l border-t border-b bg-white': !isOverwritten,
    },
    {
      'border-solid  border-l border-t border-b bg-white border-swopa-secondary-light-blue bg-sky-100':
        isOverwritten,
    },
  );
  const errorClassNames = classNames({ 'border text-swopa-warning-red border-current': error });
  const suffixClassName = classNames(
    `p-1  rounded-tr-md rounded-br-md select-none
          min-w-[80px] text-center group-focus-within:bg-swopa-secondary-light-blue  group-focus-within:text-white group-focus-within:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue
          cursor-text
          ${errorClassNames}
    `,
    {
      'border border-l-solid border-solid border-swopa-grey-3  bg-swopa-grey-1': !isOverwritten,
    },
    {
      'border border-l-solid border-solid border-swopa-secondary-light-blue  bg-sky-200':
        isOverwritten,
    },
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div className={classNames(props.className, 'inline-block')}>
      <div className="flex">
        {label && (
          <div className="text-xs mb-2 grow flex">
            <label htmlFor={rest.name}>{label}</label>
            {avoidOverwriteList && avoidOverwriteList?.indexOf(rest.name as string) !== -1 && (
              <div className="ml-1">
                <Info />
              </div>
            )}
          </div>
        )}
        {isPublic && <PublicTooltip />}
      </div>
      <div className="flex group text-swopa-primary-dark-blue">
        <input
          {...rest}
          ref={inputRef}
          className={className}
          onChange={doChange}
          onWheel={(e: any) => e.target.blur()}
        />
        <div
          className={suffixClassName}
          onClick={() => inputRef.current?.focus()}
          onChange={doChange}
        >
          {suffix}
        </div>
        {loading ? (
          <Spinner className="self-center fill-current ml-2" />
        ) : (
          <>{!finished && <CheckMark className="ml-2 self-center" />}</>
        )}
      </div>
      {error && <div className="text-swopa-warning-red">{error}</div>}
    </div>
  );
}
