import useSWR from 'swr';

import http from 'components/Profile/http';

export type ConsultationMessage = {
  id: number;
  created_at: string;
  updated_at: string;
  from_doctor: number;
  to_doctor: any;
  appointment: number;
  patient: number;
  message: string;
  parent: any;
  short_name: string;
  sender: any;
  reciever: any;
};

export async function createConsultationRequest({
  from_doctor,
  to_doctor,
  appointment,
  patient,
  message,
  parent = null,
}: {
  from_doctor: number;
  to_doctor: number;
  appointment: number;
  patient: number;
  message: string;
  parent?: number | null;
}) {
  await http.post('medical/consultation-requests/', {
    from_doctor,
    to_doctor,
    appointment,
    patient,
    message,
    parent,
  });
}

export function useConsultations(appointmentId: number) {
  const { data, error, mutate } = useSWR<any>(
    `medical/consultation-requests?appointment=${appointmentId}`,
  );
  return { data: data?.results as any, error, mutate };
}
