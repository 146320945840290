import { Outlet } from 'react-router-dom';
import ErrorAlert from './ErrorAlert';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Layout() {
  return (
    <div className="flex flex-col min-h-screen bg-swopa-grey-1 z-0">
      <div className="bg-swopa-secondary-light-blue text-swopa-primary-white">
        <header className="container mx-auto z-10">
          <Navigation />
        </header>
        <ErrorAlert />
      </div>
      <main className="container mx-auto flex-grow p-2">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
