import { GlobalErrorContext } from 'contexts/GlobalError';
import { useContext, useEffect } from 'react';

export default function ErrorAlert() {
  const { error, success, clearError, clearSuccess } = useContext(GlobalErrorContext);

  useEffect(() => {
    if (success) {
      const id = setTimeout(clearSuccess, 5000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [success, clearSuccess]);

  useEffect(() => {
    if (error) {
      const id = setTimeout(clearError, 5000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [error, clearError]);

  return (
    <>
      {error && (
        <div className="fixed top-0 left-0 w-full bg-red-600 text-white text-center font-semibold py-1 z-50">
          {typeof error === 'string'
            ? error
            : 'Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.'}
        </div>
      )}
      {success && (
        <div className="fixed w-full bg-swopa-accent-green text-white text-center font-semibold py-1">
          {success}
        </div>
      )}
    </>
  );
}
