import { useContext, useState } from 'react';
import format from 'date-fns/format';

import { GlobalErrorContext } from 'contexts/GlobalError';
import PreassessmentSection from './sections/PreassessmentSection';
import PreassessmentNoteSection from './sections/PreassessmentNoteSection';
import StatusForm from './forms/StatusForm';
import { updatePatientPreassessmentState, Patient } from 'data/usePatients';

const statuses = [
  {
    value: 'submitted',
    color: 'bg-swopa-secondary-light-blue',
    label: 'Ausstehend',
  },
  {
    value: 'positive',
    label: 'Bestätigt',
    color: 'bg-swopa-accent-green',
  },
  {
    value: 'negative',
    label: 'Abgelehnt',
    color: 'bg-swopa-warning-red',
  },
  {
    value: 'consultation',
    label: 'Nachprüfung',
    color: 'bg-swopa-primary-orange',
  },
];

type Props = {
  patient: Patient;
  handleRefetchData: () => void;
};

export default function Preassessment({ patient, handleRefetchData }: Props) {
  const { setError } = useContext(GlobalErrorContext);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);

  const preassessment = {
    created_at: patient?.pre_assesment_date || '2022-03-01',
    questionnaire: patient?.pre_assesment_payload,
  };

  const handleUpdatePreassessmentState = async (state: string) => {
    try {
      setLoading(true);
      setFinished(false);
      await updatePatientPreassessmentState({ id: patient.id, state });
      setLoading(false);
      handleRefetchData();
    } catch (e: any) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <div data-type="preassessment">
      <div className="p-4 rounded border mb-10 grid grid-cols-2">
        <StatusForm
          options={statuses}
          selectedOption={patient.pre_assessment}
          tooltip="Patient/-innen werden automatisch per E-Mail informiert, sobald die Statusänderung gespeichert wird."
          label="Eignung"
          name="eignung"
          handleSubmit={handleUpdatePreassessmentState}
          loading={loading}
          finished={finished}
          setFinished={setFinished}
        />
      </div>
      <div className="mt-12">
        <PreassessmentNoteSection patient={patient} expanded />
      </div>
      <div className="w-full relative border-b my-12">
        <span className="absolute left-2/4 -translate-x-1/2 -translate-y-1/2 bg-white px-2 py-1 border rounded font-semibold text-swopa-secondary-light-blue">
          {format(new Date(preassessment.created_at), 'dd.MM.yyyy')}
        </span>
      </div>
      <PreassessmentSection item={preassessment} expanded />
    </div>
  );
}
