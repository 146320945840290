import { ReactComponent as Thrash } from 'icons/trash.svg';
import DeleteIcon from './PatientEventFeed/sections/DeleteIcon';
type Props = {
  doctors: Array<any>;
  assignedDoctors?: Array<any>;
  removeAssigned?: boolean;
  onClick: (item: any) => void;
  onDelete?: (item: any) => void;
  allowDelete: boolean;
};

const DoctorsList = (props: Props) => {
  const { doctors, onClick, assignedDoctors, removeAssigned, onDelete, allowDelete } = props;
  let dataset = doctors;
  if (removeAssigned) {
    dataset = doctors.filter(doctor => assignedDoctors?.indexOf(doctor.id) === -1);
  }
  return (
    <>
      {
        dataset.map(doctor => (
          <div className="flex items-center px-2 py-1 hover:bg-swopa-hover-grey">
            <div className="flex items-center grow" onClick={() => onClick(doctor)}>
              <div className="w-6 h-6 overflow-hidden text-xs bg-[#16DF9F] leading-6 rounded-full mr-2 text-center text-white flex-none">
                {doctor.profile_picture && (<img alt="" className="rounded-full" src={doctor.profile_picture} />)}
                {!doctor.profile_picture && (<span>{doctor.short_name}</span>)}
              </div>
              <span className="grow text-xs block text-swopa-primary-dark-blue">{doctor.label}</span>
            </div>
            {
              allowDelete && (
                <div className="flex-none" onClick={() => onDelete && onDelete(doctor)}>
                  <DeleteIcon className="hover:bg-swopa-warning-red-hover" />
                </div>
              )
            }

          </div>
        ))
      }
    </>
  )
}

export default DoctorsList;
