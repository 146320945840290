import classnames from 'classnames';
import React, { PropsWithChildren, TableHTMLAttributes } from 'react';

type Props = PropsWithChildren<{}>;

export function Table({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableElement>) {
  return (
    <table className={classnames(className, 'w-full')} {...rest}>
      {children}
    </table>
  );
}

export function Thead({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead
      className={classnames(
        'border-b-solid border-b-[1px] border-b-swopa-grey-3 font-radikal text-xs',
        className,
      )}
      {...rest}
    >
      {children}
    </thead>
  );
}

export function Tbody({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody className={classnames(className)} {...rest}>
      {children}
    </tbody>
  );
}

export function Tr({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr
      className={classnames(
        className,
        'even:bg-swopa-grey-1 odd:bg-swopa-grey-2 hover:bg-swopa-secondary-light-blue text-swopa-primary-dark-blue stroke-swopa-primary-dark-blue hover:text-white hover:stroke-white hover:cursor-pointer text-xs',
      )}
      {...rest}
    >
      {children}
    </tr>
  );
}

export function Td({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableCellElement>) {
  return (
    <td className={classnames(className, 'p-3')} {...rest}>
      {children}
    </td>
  );
}
export function Th({
  children,
  className,
  ...rest
}: Props & TableHTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      className={classnames(className, 'text-swopa-grey-4 uppercase font-bold p-2 text-left')}
      {...rest}
    >
      {children}
    </th>
  );
}
