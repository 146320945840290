import { PatientDiagnosis, deletePatientDiagnosis, usePatient, updatePatientDiagnosis } from 'data/usePatients';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import SectionWrapper from './SectionWrapper';
import SectionFooter from './SectionFooter';
import DeleteMenu from './DeleteMenu';
import { useAppointmentContext } from 'contexts/Appointment';
import Autocomplete from '../../../components/Autocomplete';
import useDiagnosisSearch from 'hooks/useDiagnosisSearch';
import DiagnosisInfoBox from 'components/DiagnosisInfoBox';

export type PatientDiagnosisItem = {
  patient: number;
  appointmentId: number;
  created_at: string;
  diagnosis: Array<PatientDiagnosis>;
  type: 'diagnosis';
};

export default function DiagnosisSection({
  item,
  expanded,
}: {
  item: PatientDiagnosisItem;
  expanded: boolean;
}) {
  const { mutate: mutatePatient, data: patient } = usePatient(item.patient);
  const { setShowEmptyDiagnosis, refreshState } = useAppointmentContext();
  const {
    results,
    handleSearch,
    handleClearSearch,
    handleSelect,
    handleRender,
    handleKeyDown,
    activeSuggestion,
    userInput,
  } = useDiagnosisSearch({
    patient,
    appointmentId: item.appointmentId,
    mutatePatient,
    setShowEmptyDiagnosis,
  });

  const renderDoctorName = ({ diagnosis }: PatientDiagnosisItem) => {
    const names = diagnosis.map((item: PatientDiagnosis) => {
      const doctorFirstName = item?.doctor?.first_name || '';
      const doctorLastName = item?.doctor?.last_name || '';
      return `${doctorFirstName} ${doctorLastName}`;
    });
    if (names.length > 0) {
      const uniqueNames: Array<string> = Array.from(new Set(names));
      if (uniqueNames.length === 1) {
        return uniqueNames[0];
      }

      if (uniqueNames.length > 1 && uniqueNames.length - 1 === 1) {
        return `${uniqueNames[0]}, +1 anderer Arzt`;
      } else {
        return `${uniqueNames[0]}, +${uniqueNames.length - 1} andere Ärzte`;
      }
    }
    return '';
  };

  const handleRefetchData = async () => await mutatePatient();

  const handleDeleteDiagnosesByAppointmentId = async () => {
    if (patient) {
      const diagnosesForDeletion = item.diagnosis.filter(
        (diagnose) => diagnose.appointment?.id === item.appointmentId,
      );
      await Promise.all(
        diagnosesForDeletion.map(async (diagnose) => {
          await deletePatientDiagnosis({
            patient,
            diagnosis: diagnose.id,
          });
        }),
      );
      await handleRefetchData();
      refreshState();
    }
  };

  return (
    <SectionWrapper
      expanded={expanded}
      handleRefetchData={handleRefetchData}
      headline="Diagnose"
      content={
        <div>
          <Autocomplete
            results={results}
            handleSearch={handleSearch}
            handleClearSearch={handleClearSearch}
            handleSelect={handleSelect}
            handleRender={handleRender}
            handleKeyDown={handleKeyDown}
            activeSuggestion={activeSuggestion}
            userInput={userInput}
            isDiagnosisSearch
          />
          <ul className="-m-2 mt-2 mb-2 flex flex-wrap">
            {item.diagnosis.map((diagnosis: any, i: any) => (
              <li key={i} className="m-2">
                <DiagnosisInfoBox
                  diagnosis={diagnosis}
                  patient={patient}
                  mutatePatient={mutatePatient}
                  refreshState={refreshState}
                  deletePatientDiagnosis={deletePatientDiagnosis}
                  updatePatientDiagnosis={updatePatientDiagnosis}
                />
              </li>
            ))}
          </ul>
        </div>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.created_at), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    setShowEmptyDiagnosis(false);
                    refreshState();
                    if (!isEmpty(patient?.diagnosis) && patient) {
                      handleDeleteDiagnosesByAppointmentId();
                    }
                  }}
                />
              </div>
            </div>
          }
          right={<span className="text-xs">{renderDoctorName(item)}</span>}
        />
      }
    ></SectionWrapper>
  );
}
