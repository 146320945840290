import useSWR from 'swr';
import { baseFetcher } from './baseFetcher';

type LoginParams = { email: string; password: string };

export default function useUser() {
  const { data, mutate, error } = useSWR('auth/users/me/');
  const loading = !data && !error;
  const loggedOut = data && data.status === 401;

  const login = async ({ email, password }: LoginParams) => {
    const res = await baseFetcher('auth/jwt/create', {
      method: 'POST',
      body: JSON.stringify({ email: email.toLowerCase(), password }),
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    });
    localStorage.setItem('token', res.access);
    return res;
  };

  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  return {
    loading,
    loggedOut,
    mutate,
    user: data,
    login,
    logout,
  };
}
