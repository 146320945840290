import { useEffect, useState, KeyboardEvent } from 'react';

export default function useTextareaAutosize(id: string) {
  const [textarea, setTextarea] = useState(null as any);
  const borderWidth = 2;

  const handleKeyUp = (event: KeyboardEvent) => {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = 'inherit';
    target.style.height = `${target.scrollHeight + borderWidth}px`;
  };

  useEffect(() => {
    const element = document.getElementById(`${id}`) as HTMLTextAreaElement;
    setTextarea(element);
  }, [id]);

  useEffect(() => {
    if (textarea) {
      textarea.style.height = `${textarea.scrollHeight + borderWidth}px`;
    }
  }, [textarea, textarea?.scrollHeight]);

  return { handleKeyUp };
}
