import classNames from 'classnames';

export type UnderlineInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { error?: string; inputClassName?: string };

export default function UnderlineInput(props: UnderlineInputProps) {
  const { error, inputClassName, ...rest } = props;
  const className = classNames(
    'w-full bg-transparent outline-none border-b border-transparent focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue placeholder-swopa-grey-4',
    inputClassName,
    { 'placeholder-swopa-warning-red !border-swopa-warning-red': error },
  );
  return (
    <div className={props.className}>
      <input {...rest} className={className} />
      {error && <div className="text-swopa-warning-red">{error}</div>}
    </div>
  );
}
