import { useEffect, useState, ChangeEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';

import { usePaginatedPatients } from 'data/usePatients';
import { Table, Thead, Tbody, Tr, Td, Th } from 'components/Table';
import Button from 'components/Button';
import { ReactComponent as RightArrow } from 'icons/arrow_right.svg';
import PaginationFooter from './PaginationFooter';
import Spinner from './Spinner';
import StatusSelect from './inputs/StatusSelect';
import usePatientNameSearch from 'hooks/usePatientNameSearch';
import Autocomplete from 'components/Autocomplete';
import DoctorsCircles from './DoctorsCircles';
import useSessionStorage from 'hooks/useSessionStorage';
import { preassessmentStatuses } from 'constants/patientStatuses';

export default function PatientSearch() {
  const { getItem, setItem } = useSessionStorage();
  const cachedPreassessmentStatus = getItem('status', null);
  const storedSearch = getItem('search', '');
  const [search, setSearch] = useState(storedSearch || '');
  const [activeColor, setActiveColor] = useState('bg-transparent');
  const [selectedStatus, setFilterPreassessmentStatus] = useState(cachedPreassessmentStatus || '');
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1');
  const { patients, paginationInfo, isLoading } = usePaginatedPatients({
    search,
    page,
    pre_assessment: selectedStatus,
  });
  const [pagination, setPagination] = useState(paginationInfo);
  const [filtering, setFiltering] = useState(false);
  const navigate = useNavigate();

  const setSessionStorage = (key: string, value: any) => {
    setItem(key, value);
  };

  const {
    results,
    handleSearch,
    handleClearSearch,
    handleSelect,
    handleRender,
    handleKeyDown,
    activeSuggestion,
    userInput,
    handleClearInputField,
  } = usePatientNameSearch({
    setSearch,
    setFilterPreassessmentStatus,
    setSessionStorage,
    storedSearch,
  });

  const resetPagination = () => {
    setSearchParams('');
  };

  const handleClearFilter = () => {
    setFilterPreassessmentStatus('');
    setSessionStorage('status', '');
    setSearch('');
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = preassessmentStatuses.filter(
      (status) => status.value === e.target.value,
    )[0].value;
    setFiltering(true);
    setSearch('');
    handleClearInputField();
    setFilterPreassessmentStatus(selectedStatus);
    setSessionStorage('status', selectedStatus);
    setSessionStorage('search', '');
    setFiltering(false);
  };

  useEffect(() => {
    resetPagination();
  }, [search]);

  useEffect(() => {
    setPagination(paginationInfo);
  }, [isLoading, searchParams, search, selectedStatus]);

  useEffect(() => {
    setActiveColor(
      preassessmentStatuses.find((option) => selectedStatus === option.value)?.color as string,
    );
  }, [selectedStatus]);

  // set filters
  useEffect(() => {
    const storedSearch = getItem('search');
    const storedStatus = getItem('status');
    if (!storedSearch) setFilterPreassessmentStatus(storedStatus);
  }, []);

  return (
    <>
      <div className="mt-4 flex justify-start">
        <div className="mr-4">
          <Autocomplete
            results={results}
            handleSearch={handleSearch}
            handleClearSearch={handleClearSearch}
            handleSelect={handleSelect}
            handleRender={handleRender}
            isPatientSearch
            handleKeyDown={handleKeyDown}
            activeSuggestion={activeSuggestion}
            userInput={userInput}
          />
        </div>
        <div className="mr-4">
          <StatusSelect
            name="pre_assessment"
            options={preassessmentStatuses}
            handleChange={handleChange}
            activeColor={activeColor}
            defaultValue={selectedStatus}
            selectedValue={selectedStatus}
            className="border-swopa-border text-swopa-primary-dark-blue"
          />
        </div>
      </div>
      {filtering ? (
        <div className="mx-auto mt-12 flex justify-center items-center flex-col max-w-xs">
          <Spinner className="fill-swopa-secondary-light-blue w-5 h-5" />
        </div>
      ) : (
        <>
          <Table className="mt-4">
            <Thead>
              <tr>
                <Th>ID</Th>
                <Th className="flex items-center">Name</Th>
                <Th>Vorbeurteilung</Th>
                <Th>Geb.</Th>
                <Th>Arzt/Ärztin</Th>
                <Th />
              </tr>
            </Thead>
            <Tbody>
              {patients?.map((patient, i) => (
                <Tr
                  key={`${patient.id}-${i}`}
                  onClick={() => {
                    navigate(`/patients/${patient?.id}`);
                  }}
                >
                  <Td>{patient.id}</Td>
                  <Td className="font-bold">
                    {patient.first_name} {patient.last_name}
                  </Td>
                  <Td>
                    <div className="flex flex-row items-center">
                      <div
                        className={classNames('w-2 h-2  rounded-full mr-2', {
                          'bg-swopa-secondary-light-blue': patient.pre_assessment === 'submitted',
                          'bg-swopa-accent-green': patient.pre_assessment === 'positive',
                          'bg-swopa-grey-4': patient.pre_assessment === 'negative',
                          'bg-swopa-primary-orange': patient.pre_assessment === 'consultation',
                        })}
                      />
                      {patient.pre_assessment === 'submitted' && 'Ausstehend'}
                      {patient.pre_assessment === 'positive' && 'Bestätigt'}
                      {patient.pre_assessment === 'negative' && 'Abgelehnt'}
                      {patient.pre_assessment === 'consultation' && 'Nachprüfung'}
                    </div>
                  </Td>
                  <Td>
                    {patient.date_of_birth && format(new Date(patient.date_of_birth), 'dd.MM.yyyy')}
                  </Td>
                  <Td>
                    <DoctorsCircles
                      patient={patient}
                      moreOnLeft={true}
                      sizeClass="w-6 h-6 leading-[1.4rem] text-[0.6rem] ml-[-12px]"
                      assignedDoctors={patient.doctors_list}
                    />
                  </Td>
                  <Td>
                    <RightArrow />
                  </Td>
                </Tr>
              ))}
            </Tbody>
            {patients.length > 0 && pagination && pagination.totalPages > 1 && (
              <tfoot>
                <tr>
                  <td colSpan={6}>
                    <PaginationFooter
                      className="mt-4"
                      paginationInfo={pagination}
                      onChange={(p) => {
                        searchParams.set('page', p.toString());
                        setSearchParams(searchParams, { replace: false });
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
          {patients.length === 0 && (
            <div className="mx-auto mt-12 flex justify-center items-center flex-col max-w-xs">
              {isLoading ? (
                <Spinner className="fill-swopa-secondary-light-blue w-5 h-5" />
              ) : (
                <>
                  <span className="text-swopa-grey-4 text-base mb-4">Kein Ergebnis</span>
                  <Button onClick={handleClearFilter}>Alle Filter zurücksetzen</Button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
