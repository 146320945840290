import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export default function InfoBox({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <span
      className={classNames(
        'm-2 px-4 py-2 uppercase font-radikal font-bold text-xxs tracking-[0.03em] rounded-md text-swopa-secondary-light-blue bg-opacity-10 bg-swopa-secondary-light-blue inline-block',
        className,
      )}
    >
      {children}
    </span>
  );
}
