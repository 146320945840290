import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';
import { useAppointmentContext } from 'contexts/Appointment';

export default function SectionWrapper({
  headline,
  content,
  icon,
  footer,
  handleRefetchData,
  expanded,
  ...rest
}: {
  headline: ReactNode;
  content: ReactNode;
  icon?: ReactNode;
  footer?: ReactNode;
  id?: string;
  expanded: boolean;
  handleRefetchData?: () => void;
}) {
  const [open, setOpen] = useState(expanded);
  const { refreshState } = useAppointmentContext();

  const headerBorderColorHover = open ? 'hover:border' : 'hover:border-swopa-secondary-light-blue';
  const rotateArrow180 = open && 'rotate-180';
  const headerBgColor = open ? 'bg-swopa-grey-1' : 'bg-white';
  const headerBorderRadius = open ? 'rounded-t' : 'rounded';

  const handleToggle = () => {
    setOpen(!open);
  };
  
  useEffect(() => {
    if (open && handleRefetchData) handleRefetchData();
  }, [open])

  useEffect(() => {
    setOpen(expanded);
    refreshState && refreshState();
  }, [expanded]);

  return (
    <div
      className={classNames('bg-white rounded-lg border border-solid border-swopa-grey-2')}
      {...rest}
    >
      <div
        onClick={handleToggle}
        className={classNames(
          headerBgColor,
          headerBorderRadius,
          headerBorderColorHover,
          'group cursor-pointer flex px-4 py-2 items-center justify-between border border-solid m-[-1px] border-swopa-grey-2',
        )}
      >
        <h2 className="text-base font-radikal">{headline}</h2>
        <ArrowDown
          className={classNames(
            rotateArrow180,
            'stroke-swopa-grey-4 group-hover:stroke-swopa-secondary-light-blue-hover hover:stroke-swopa-secondary-light-blue-hover',
          )}
        />
      </div>
      {open && (
        <>
          <div className="p-4">{content}</div>
          {footer && (
            <div className="relative px-4 py-2 border-t border-solid border-swopa-grey-2 text-swopa-grey-4 text-xs">
              {footer}
            </div>
          )}
        </>
      )}
    </div>
  );
}
