import { useState } from 'react';
import useSWR from 'swr';
import { Doctor } from 'types/doctor';
import { post, patch, httpDelete } from './http';
import { useInfiniteScroll } from './useInfiniteScroll';

type NoteData = {
  results: Array<Note>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Note = {
  id: number;
  patient: number;
  content: string;
  created_at: string;
  doctor: Doctor;
  appointment: number;
};

export function useNotes(patientId: number, appointmentId?: number) {
  const [search, setSearch] = useState('');

  const paginationData = useInfiniteScroll<NoteData>({
    url: `medical/notes/?search=${search}&patient_id=${patientId}&appointment_id=${appointmentId}`,
    pageSize: 99,
  });

  return { search, setSearch, ...paginationData };
}

export function useNote(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Note>(`medical/notes/${id}/`);

  return { data, error, mutate };
}

export function createNote({
  patient,
  appointmentId,
  content = '',
  created_at = new Date(),
}: {
  patient: number;
  appointmentId?: number;
  content?: string;
  created_at?: Date;
}) {
  return post('medical/notes/', { patient, appointment: appointmentId, content, created_at });
}

export function updateNote(note: { id: number } & Partial<Note>) {
  return patch(`medical/notes/${note.id}/`, note);
}

export const deleteNote = (id: number) => {
  return httpDelete(`medical/notes/${id}/`);
};
