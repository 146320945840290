import useSWR from 'swr';
import { Doctor } from '../types/doctor';

type DocumentData = {
  results: Array<Doctor>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export function useDoctors() {
  const { data, error, mutate } = useSWR<DocumentData>('medical/doctors/');
  return { data: data?.results as any, error, mutate };
}

export function useConsultingDoctors() {
  const { data, error, mutate } = useSWR<DocumentData>('medical/doctors/?is_consultation_doctor=True');
  return { data: data?.results as any, error, mutate };
}
