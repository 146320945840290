import { useContext, createContext, useReducer, ReactNode } from 'react';
import useAppointment from 'hooks/useAppointment';

const AppointmentContext = createContext({});

export type AppointmentContextState = {
  anamnesisActive: boolean;
  examinationActive: boolean;
  diagnosisActive: boolean;
  indicationActive: boolean;
  prescriptionActive: boolean;
  noteActive: boolean;
  refresh: null | number;
  selectedSection: string;
  showEmptyDiagnosis: boolean;
};

export type Action = { type: string; payload: any };

type State = {
  state: AppointmentContextState;
  dispatch: (obj: Action) => void;
  sectionExistsInDOM: (section: string) => boolean;
  setActive: (type: string) => void;
  setSelectedSection: (type: string) => void;
  setShowEmptyDiagnosis: (value: boolean) => void;
  refreshState: () => void;
};

const reducer = (state: AppointmentContextState, action: Action) => {
  switch (action.type) {
    case 'anamnesis':
      return {
        ...state,
        anamnesisActive: action.payload,
      };
    case 'examination':
      return {
        ...state,
        examinationActive: action.payload,
      };
    case 'diagnosis':
      return {
        ...state,
        diagnosisActive: action.payload,
      };
    case 'indication':
      return {
        ...state,
        indicationActive: action.payload,
      };
    case 'prescription':
      return {
        ...state,
        prescriptionActive: action.payload,
      };
    case 'note':
      return {
        ...state,
        noteActive: action.payload,
      };
    case 'refresh':
      return {
        ...state,
        refresh: action.payload,
      };
    case 'selectedSection':
      return {
        ...state,
        selectedSection: action.payload,
      };
    case 'setShowEmptyDiagnosis':
      return {
        ...state,
        showEmptyDiagnosis: action.payload,
      };
    default:
      return state;
  }
};

export const AppointmentProvider = ({ children }: { children: ReactNode }) => {
  const initialState = {
    anamnesisActive: false,
    examinationActive: false,
    diagnosisActive: false,
    indicationActive: false,
    prescriptionActive: false,
    noteActive: false,
    refresh: null,
    selectedSection: '',
    showEmptyDiagnosis: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { sectionExistsInDOM, setActive, setSelectedSection, setShowEmptyDiagnosis, refreshState } =
    useAppointment({
      state,
      dispatch,
    });

  return (
    <AppointmentContext.Provider
      value={{
        state,
        sectionExistsInDOM,
        setActive,
        setSelectedSection,
        setShowEmptyDiagnosis,
        refreshState,
        dispatch,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};

export const useAppointmentContext = () => useContext(AppointmentContext) as State;
