import { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import { has } from 'lodash';
import { string } from 'yup';

import useUser from 'data/useUser';
import Button from './Button';
import LoginInput from './inputs/LoginInput';
import { GlobalErrorContext } from 'contexts/GlobalError';

type LoginFormValues = {
  email: string;
  password: string;
};

export default function Login() {
  const { login, mutate } = useUser();
  const location: any = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/patients';
  const [loading, setLoading] = useState(false);
  const { setError } = useContext(GlobalErrorContext);

  const handleSubmit = async (values: LoginFormValues, actions: any) => {
    try {
      setLoading(true);
      actions.setSubmitting(true);
      const res = await login(values);
      if (has(res, 'error')) {
        return setError(res.msg);
      }
      await mutate();
      navigate(from, { replace: true });
      window.location.reload(); // to make sure axios authHeaders are set
    } catch (e) {
      // TODO
      console.log(e);
    } finally {
      setLoading(false);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validateOnBlur={false}
      validate={async (values: LoginFormValues) => {
        let errors: FormikErrors<LoginFormValues> = {};
        if (!values.email) {
          errors.email = 'Bitte geben Sie ihre E-Mail Adresse ein';
        } else if (values.email) {
          await string()
            .email()
            .validate(values.email)
            .catch(() => {
              errors.email = 'Ungültige E-Mail Adresse';
            });
        }
        if (!values.password) {
          errors.password = 'Bitte geben sie Ihr Passwort ein';
        }
        return errors;
      }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ handleSubmit, touched, errors }) => (
        <form className="text-left" onSubmit={handleSubmit}>
          <h2 className="text-swopa-secondary-light-blue mb-6">Anmelden</h2>
          <LoginInput
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            error={!!(touched.email && errors.email)}
            errorMessage={errors.email}
          />
          <LoginInput
            id="password"
            name="password"
            type="password"
            placeholder="Passwort"
            className="mt-2"
            error={!!(touched.password && errors.password)}
            errorMessage={errors.password}
            isPasswordInput
          />
          <Button className="mt-4" type="submit" disabled={loading}>
            Anmelden
          </Button>
          <div className="flex justify-between mt-2">
            <Link
              className="text-swopa-secondary-grey hover:text-swopa-accent-green"
              to="/reset-password"
            >
              Passwort vergessen?
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}
