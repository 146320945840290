export const diagnosisCertaintyLevels = [
  {
    shortname: 'G',
    name: 'Gesichert',
    value: 'secured',
  },
  {
    shortname: 'V',
    name: 'Verdacht auf',
    value: 'suspicion_of',
  },
  {
    shortname: 'Z',
    name: 'Zustand nach',
    value: 'state_after',
  },
  {
    shortname: 'A',
    name: 'Ausgeschlossen',
    value: 'excluded',
  },
];
