import { baseFetcher, baseUrl } from './baseFetcher';

export async function resetPassword({ email }: { email: string }) {
  return baseFetcher('auth/users/reset-password/', {
    method: 'POST',
    body: JSON.stringify({ email }),
  });
}

export async function resetPasswordConfirm({
  uid,
  token,
  password,
}: {
  uid: string;
  token: string;
  password: string;
}) {
  return baseFetcher('auth/users/reset-password-confirm/', {
    method: 'POST',
    body: JSON.stringify({ uid, token, password }),
  });
}

export function validatePassword({ password }: { password: string }) {
  const url = new URL('auth/users/validate-password/', baseUrl).toString();
  return fetch(url, {
    method: 'POST', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  });
}
