import { createContext } from 'react';

export const GlobalErrorContext = createContext<{
  error: null | Error | string;
  success: null | string;
  setError: (e: null | Error | string) => void;
  clearError: () => void;
  setSuccess: (s: null | string) => void;
  clearSuccess: () => void;
}>({
  error: null,
  success: null,
  setError: () => {},
  clearError: () => {},
  setSuccess: () => {},
  clearSuccess: () => {},
});
