import { useEffect } from 'react';
import classNames from 'classnames';

import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useSidebarContext } from 'contexts/Sidebar';
import BlurryBackground from 'components/BlurryBackground';

const PatientDataFormActions = ({
  handleToggle,
  open,
  setOpen,
}: {
  handleToggle: () => any;
  open: boolean;
  setOpen: () => any;
}) => {
  const { dispatch, state } = useSidebarContext();

  const handleEdit = (e: any) => {
    setOpen();
    dispatch({ type: 'SET_IS_EDITING', payload: true });
  };

  const handleClickOutside = async (e: any) => {
    const wrapper = document.querySelector('[data-panel-type="stammdaten"]');
    const closeBtn = document.querySelector('.cancel-btn');
    if (wrapper) {
      if (!wrapper.contains(e.target) || closeBtn === e.target) {
        dispatch({ type: 'SET_IS_EDITING', payload: false });
        dispatch({ type: 'SET_IS_CANCELING', payload: true });
      }
    }
  };

  useEffect(() => {
    if (state.isEditing) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [state.isEditing]);

  return (
    <>
      {state.isEditing && (
        <button className="cancel-btn cursor-pointer absolute -right-8 top-2">
          <CloseIcon className="stroke-black w-6 h-6 pointer-events-none" />
        </button>
      )}
      <button className="outline-none py-3 px-2 h-full flex items-center" onClick={handleEdit}>
        <EditIcon className="stroke-swopa-grey-4" />
      </button>
      <button onClick={handleToggle} className="py-3 pl-2 pr-4 h-full">
        <ArrowDown
          className={classNames('stroke-swopa-grey-4 cursor-pointer', {
            'rotate-180': open,
          })}
        />
      </button>
      {state.isEditing && <BlurryBackground />}
    </>
  );
};

export default PatientDataFormActions;
