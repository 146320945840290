import ExpandPanel from './ExpandPanel';
import PatientDataForm from './PatientDataForm';
import DocumentsList from './DocumentsList';
import NotesList from './NotesList';
import { Patient } from 'data/usePatients';
import { useDocuments } from 'data/useDocuments';
import { SidebarProvider } from 'contexts/Sidebar';
import { useNotes } from '../../data/useNotes';


type Props = {
  patient: Patient;
  handleRefetchPatientData: () => void;
};

export default function Sidebar({ patient, handleRefetchPatientData }: Props) {
  const { data: documents, mutate: refetchDocuments } = useDocuments(patient.id);
  const { results: notes, mutate: refetchNotes } = useNotes(patient.id);
  return (
    <SidebarProvider>
      <ExpandPanel
        title="Stammdaten"
        content={<PatientDataForm patient={patient} />}
        handleRefetchPatientData={handleRefetchPatientData}
        panelType="stammdaten"
      />
      <ExpandPanel
        title="Dokumente"
        documents={documents}
        refetchDocuments={refetchDocuments}
        patientId={patient.id}
        content={<DocumentsList documents={documents} />}
      />
      <ExpandPanel
        title="Notizen"
        notes={notes}
        refetchNotes={refetchNotes}
        patientId={patient.id}
        content={<NotesList notes={notes} />}
      />
    </SidebarProvider>
  );
}
