import { useState } from 'react';
import useSWR from 'swr';
import { httpDelete, post, patch } from './http';
import { useInfiniteScroll } from './useInfiniteScroll';
import { paginate } from './paginationHelper';
import { Diagnosis } from './useDiagnosis';
import { Doctor } from 'types/doctor';
import { Appointment } from './useAppointments';

type PatientsData = {
  results: Array<Patient>;
  count: number;
  next: string;
  previous: string;
};

export type PatientDiagnosis = {
  created_at: string;
  diagnose: Diagnosis;
  doctor: Doctor;
  id: number;
  appointment: Appointment;
  certainty: string;
};

export type Preassessment = {
  questionnaire: {
    [key: string]: string;
  };
  created_at: string;
  type?: string;
};

export type DoctorShort = {
  id: number;
  name: string;
  short_name: string;
};

export type Patient = {
  city: string;
  country_code: string;
  email: string;
  existing_medication: string;
  first_name: string;
  insurance_type: string;
  insurance_company: null | number;
  health_insurance_number: string;
  date_of_birth: string;
  id: number;
  informed_about_accompanying_survey: boolean;
  informed_about_being_banned_from_driving: boolean;
  informed_about_off_label_use: boolean;
  informed_about_risks_and_side_effects: boolean;
  last_name: string;
  lead_gen_information: null | string;
  phone_number: string;
  postal_code: string;
  pre_assessment: string;
  pre_assessment_status: string;
  pre_assessment_note: string | null;
  street: string;
  diagnosis: Array<PatientDiagnosis>;
  doctors_list: Array<DoctorShort>;
  doctors: Array<number>;
  next_appointment: null | {
    assessment: string;
    doctor: {
      id: number;
      city: string;
      country_code: string;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      postal_code: string;
      street: string;
    };

    id: number;
    format: string;
    patient: number;
    practise: number;
    state: string;
    created_at: string;
    video_consultation_tan: string;
  };
  pre_assesment_payload: Preassessment['questionnaire'];
  pre_assesment_date: string;
  appointments: Array<Appointment>;
  billing_address_is_different: boolean;
  billing_street: string;
  billing_postal_code: string;
  billing_city: string;
  billing_first_name: string;
  billing_last_name: string;
  height: number;
  weight: number;
};

type Document = {
  id: number;
  name: string;
};

export type PatientSurvey = {
  symptoms: Array<string>;
  symptoms_description?: string;
  symptoms_diagnosis_document?: Document;
  symptom_severity: number;
  previous_treatment: boolean;
  previous_treatment_details?: string;
  previous_treatment_document?: Document;
  cannabis_treatment: boolean;
  cannabis_treatment_details?: string;
  cannabis_treatment_document?: Document;
  existing_conditions?: Array<string>;
  additional_notes?: string;
  created_at: string;
};

const pageSize = 25;

export function usePaginatedPatients({
  search,
  page = 1,
  pre_assessment,
}: {
  search: string;
  page?: number;
  pre_assessment?: string | null;
}) {
  const { data, mutate, error } = useSWR<PatientsData>(
    `medical/patients/?search=${encodeURIComponent(search)}&limit=${pageSize}&offset=${
      pageSize * (page - 1)
    }${pre_assessment ? `&pre_assessment=${pre_assessment}` : ''}`,
  );

  const paginationInfo = data ? paginate(data?.count ?? 0, page, pageSize) : null;

  const isLoading = !error && !data;

  return { patients: data?.results ?? [], mutate, paginationInfo, isLoading };
}

export function usePatients() {
  const [search, setSearch] = useState('');
  const paginationData = useInfiniteScroll<PatientsData>({
    url: `medical/patients/?search=${encodeURIComponent(search)}`,
    pageSize: 4,
  });

  return { search, setSearch, ...paginationData };
}

export function usePatient(id: undefined | null | string | number) {
  const { data, error, mutate } = useSWR<Patient>(`medical/patients/${id}/`);

  return { data, error, mutate };
}

type PaginationResult<T> = {
  results: Array<T>;
  count: number;
  next: string;
  previous: string;
};

export function usePatientSurveys(id: undefined | null | string | number) {
  const { data, error, mutate } = useSWR<PaginationResult<PatientSurvey>>(
    `medical/patient-survey/?patient=${id}`,
  );

  const survey = data?.results[0];

  return { survey, error, mutate };
}

export function useMutatePatient(
  id: undefined | null | string | number,
  shouldFetch: boolean = false,
) {
  const { mutate } = useSWR<Patient>(shouldFetch ? `medical/patients/${id}/` : null);

  return { mutate };
}

export function updatePatient(patient: { id: number } & Partial<Patient>) {
  return patch(`medical/patients/${patient.id}/`, patient);
}

export function updatePatientPreassessmentState({ id, state }: { id: number; state: string }) {
  return patch(`medical/patients/${id}/`, { pre_assessment: state });
}

export function addPatientDiagnosis({
  patient,
  diagnosis,
  appointmentId,
  certainty = 'secured',
}: {
  patient: Patient;
  diagnosis: number;
  appointmentId: number;
  certainty: string;
}) {
  return post(`medical/patients/${patient.id}/diagnosis/`, {
    ...patient,
    diagnosis,
    appointment: appointmentId,
    certainty,
  });
}

export function deletePatientDiagnosis({
  patient,
  diagnosis,
}: {
  patient: Patient;
  diagnosis: number | 'all';
}) {
  return httpDelete(`medical/patients/${patient.id}/diagnosis/`, {
    id: diagnosis,
  });
}

export function updatePatientDiagnosis({
  patient,
  diagnosis,
  certainty,
}: {
  patient: Patient;
  diagnosis: number | 'all';
  certainty: string;
}) {
  return patch(`medical/patients/${patient.id}/diagnosis/`, {
    diagnosis: diagnosis,
    certainty: certainty,
  });
}

export function assignDoctor({ patient, doctors }: { patient: number; doctors: Array<number> }) {
  return patch(`medical/patients/${patient}/`, {
    doctors,
  });
}
