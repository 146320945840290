import { ReactComponent as AddIcon } from 'icons/plus-circle.svg';
import classNames from 'classnames';
import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';
import Popper from 'components/Popper';
import useUser from 'data/useUser';

export default function NoteActions({
  patientId,
  handleToggle,
  open,
  setShowForm,
  showForm,
}: {
  patientId: number;
  handleToggle: () => any;
  open: boolean;
  setShowForm: (value: boolean) => any;
  showForm: boolean;
}) {
  const {
    user: { managed_doctor },
  } = useUser();
  return (
    <>
      {
        managed_doctor && managed_doctor.id && (
          <Popper
            triggerOnHover
            trigger={
              <div  onClick={() => open && setShowForm(!showForm)}  className=" cursor-pointer outline-none py-3 mt-1 px-2 h-full">
                <AddIcon  className="stroke-swopa-grey-4" />
              </div>
            }
            content={() => (
              <div className="bg-white p-3 drop-shadow rounded-lg max-w-[275px] text-center">
                <span className="text-swopa-primary-dark-blue block text-xs">Neue Notiz</span>
              </div>
            )}
          />
        )
      }

      <button onClick={handleToggle} className="py-3 pl-2 pr-4 h-full">
        <ArrowDown
          className={classNames('stroke-swopa-grey-4 cursor-pointer', {
            'rotate-180': open,
          })}
        />
      </button>
    </>
  );
}
