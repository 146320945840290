import http from 'components/Profile/http';

export default function useValidateTherapyStatuses({
  patientId,
  appointmentId,
}: {
  patientId: number;
  appointmentId: number;
}) {
  const handleValidate = async () => {
    let indication_for_cannabis_therapy_error = true;
    let amount_error = true;
    let share_of_thc_error = true;
    let share_of_cbd_error = true;
    let procedure_error = true;
    let diagnosis_error = true;

    const { results: indications } = await http.get(
      `medical/indications/?patient_id=${patientId}&appointment_id=${appointmentId}`,
    );

    const { results: prescription } = await http.get(
      `medical/prescriptions/?patient_id=${patientId}&appointment_id=${appointmentId}`,
    );

    const { diagnosis } = await http.get(`medical/patients/${patientId}/`);

    if (indications.length) {
      // if there are many of the same cards, only one of them needs to meet the condition
      indication_for_cannabis_therapy_error = indications.find(
        (item: any) => Boolean(item.indication_for_cannabis_therapy) === true,
      )
        ? false
        : true;
    }

    if (prescription.length) {
      // if there are many of the same cards, only one of them needs to meet the condition
      amount_error = prescription.find((item: any) => Boolean(item.amount) === true) ? false : true;
      share_of_thc_error = prescription.find((item: any) => Boolean(item.share_of_thc) === true)
        ? false
        : true;
      share_of_cbd_error = prescription.find((item: any) => Boolean(item.share_of_cbd) === true)
        ? false
        : true;
      procedure_error = prescription.find((item: any) => Boolean(item.procedure) === true)
        ? false
        : true;
    }

    if (diagnosis.length) {
      diagnosis_error = false;
    } else {
      diagnosis_error = true;
    }

    const hasError = [
      indication_for_cannabis_therapy_error,
      amount_error,
      share_of_thc_error,
      share_of_cbd_error,
      procedure_error,
      diagnosis_error,
    ].some((item) => Boolean(item) === true);

    return hasError
      ? {
          indication_for_cannabis_therapy_error: indication_for_cannabis_therapy_error
            ? 'Indikation für Therapieversuch mit med. Cannabis ist (weiterhin) gegeben.'
            : false,
          amount_error: amount_error ? 'Menge' : false,
          share_of_thc_error: share_of_thc_error ? 'Anteil THC' : false,
          share_of_cbd_error: share_of_cbd_error ? 'Anteil CBD' : false,
          procedure_error: procedure_error ? 'Empfohlene Dosierung/Einnahme' : false,
          diagnosis_error: diagnosis_error
            ? 'Bitte wählen Sie mindestens eine Diagnose aus.'
            : false,
        }
      : false;
  };

  return { handleValidate };
}
