interface ICertaintySymbol extends React.SVGAttributes<HTMLOrSVGElement> {
  certainty: 'A' | 'G' | 'Z' | 'V' | string;
}

export default function CertaintySymbol({ certainty, ...props }: ICertaintySymbol) {
  if (certainty === 'A') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="8" cy="8" r="8" fill="#4091F7" />
        <path
          d="M8.02051 3.52H7.98051L4.52051 11H5.88051L6.46051 9.67H9.54051L10.1205 11H11.4805L8.02051 3.52ZM8.00051 6.09L9.11051 8.64H6.89051L8.00051 6.09Z"
          fill="white"
        />
      </svg>
    );
  }
  if (certainty === 'G') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="8" cy="8" r="8" fill="#4091F7" />
        <path
          d="M8.2 4C6.05 4 4.5 5.62 4.5 7.81C4.5 9.98 6.09 11.61 8.33 11.61C9.42 11.61 10.35 11.28 11.04 10.63V7.49H8.05V8.57H9.79V10.01C9.4 10.29 8.91 10.46 8.33 10.46C6.79 10.46 5.76 9.34 5.76 7.81C5.76 6.26 6.75 5.15 8.2 5.15C9.01 5.15 9.63 5.51 10.07 5.99L10.86 5.09C10.16 4.38 9.35 4 8.2 4Z"
          fill="white"
        />
      </svg>
    );
  }
  if (certainty === 'Z') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="8" cy="8" r="8" fill="#4091F7" />
        <path
          d="M5.4598 4.3V5.41H8.6598L5.2998 11.61V11.65H10.6898V10.54H7.2498L10.5798 4.34V4.3H5.4598Z"
          fill="white"
        />
      </svg>
    );
  }
  if (certainty === 'V') {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="8" cy="8" r="8" fill="#4091F7" />
        <path
          d="M10.1361 4.64999L8.00609 9.50999L5.86609 4.64999H4.49609L7.98609 12.16H8.02609L11.5061 4.64999H10.1361Z"
          fill="white"
        />
      </svg>
    );
  }
  return <div></div>;
}
