import { ChangeEvent, useState, useEffect, ReactNode } from 'react';
import { ReactComponent as Search } from 'icons/search.svg';

type Props = {
  setUserInput: (value: string) => void;
  userInput: string;
  results: Array<any>;
  renderResults: (results: Array<any>) => ReactNode;
  placeholder?: string;
};

export default function SearchableList(props: Props) {
  const { results, renderResults, placeholder, userInput, setUserInput } = props;
  const [ localResults, setLocalResults ] = useState<Array<any>>([]);
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => setUserInput(e.target.value) ;

  useEffect(() => {
    const filtered = results.filter((item: any) => item.label.toLowerCase().indexOf(userInput.toLowerCase()) !== -1);
    if (filtered.length > 0){
      setLocalResults(filtered);
    } else {
      setLocalResults([{ id: 0, short_name: 'KE', label: 'Kein Ergebnis'}]);
    }
  }, [userInput]);

  return (
    <div className="shadow rounded searchable-list absolute w-64 z-50 bg-white top-[40px] right-0">
      <div className="list-header border-b p-2 ">
      <div className="relative">
        <input
          className={` w-full pl-8 pr-2 rounded border text-swopa-primary-dark-blue focus:outline-none focus:border-swopa-secondary-light-blue placeholder:text-swopa-grey-4`}
          type="search"
          placeholder={placeholder || 'Assign Doctor'}
          onChange={handleSearch}
          value={userInput}
        />
        <Search className="absolute left-3 top-[50%] translate-y-[-50%]" />
      </div>
      </div>
      <div className="list-body py-1 overflow-y-auto max-h-[260px]">
        {renderResults(localResults)}
      </div>
    </div>
  );
}
