import { useState, ChangeEvent } from 'react';

import { Patient } from '../data/usePatients';

type Props = {
  setSearch: (query: string) => void;
  setFilterPreassessmentStatus: (option: string) => void;
  setSessionStorage: (key: string, value: any) => void;
  storedSearch: string;
};

export default function usePatientNameSearch(props: Props) {
  const { setSearch, setFilterPreassessmentStatus, setSessionStorage, storedSearch } = props;
  const [results, setResults] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [userInput, setUserInput] = useState(storedSearch);

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserInput(value);
    if (value === '') {
      setSearch('');
      setSessionStorage('search', '');
    }
  };

  const handleClearSearch = () => {
    setResults([]);
    setSearch('');
    setUserInput('');
    setSessionStorage('search', '');
    setActiveSuggestion(0);
  };

  const handleSelect = () => {
    setFilterPreassessmentStatus(''); // remove any restricting filters before searching
    setSearch(userInput);
    setSessionStorage('search', userInput);
    setSessionStorage('status', '');
  };

  const handleRender = (patient: Patient | string) => {
    if (typeof patient === 'string') {
      return patient;
    }
    return `${patient.first_name} ${patient.last_name}`;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSelect();
    }
  };

  const handleClearInputField = () => {
    setUserInput('');
  };

  return {
    results,
    handleSearch,
    handleClearSearch,
    handleSelect,
    handleRender,
    handleClearInputField,
    handleKeyDown,
    activeSuggestion,
    userInput,
  };
}
