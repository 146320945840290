import { useState } from 'react';
import useSWR from 'swr';
import { Doctor } from 'types/doctor';
import { post, patch, httpDelete } from './http';
import { useInfiniteScroll } from './useInfiniteScroll';

type ExaminationData = {
  results: Array<Examination>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Examination = {
  id: number;
  patient: number;
  general_state_of_health: string;
  height: number;
  weight: number;
  rr: number;
  spo2: number;
  pulse_rate: number;
  ausculation_lungs: string;
  mouth_tongue_faucal: string;
  neurological_examination: string;
  created_at: string;
  doctor: Doctor;
  bmi: Array<string | number>;
};

export function useExaminations(patientId: number, appointmentId: number) {
  const [search, setSearch] = useState('');

  const paginationData = useInfiniteScroll<ExaminationData>({
    url: `medical/examinations/?search=${search}&patient_id=${patientId}&appointment_id=${appointmentId}`,
    pageSize: 99,
  });

  return { search, setSearch, ...paginationData };
}

export function useExamination(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Examination>(`medical/examinations/${id}/`);

  return { data, error, mutate };
}

export function createExamination({
  id,
  appointmentId,
  created_at = new Date(),
}: {
  id: number;
  appointmentId: number;
  created_at?: Date;
}) {
  return post('medical/examinations/', { patient: id, appointment: appointmentId, created_at });
}

export function updateExamination(examination: { id: number } & Partial<Examination>) {
  return patch(`medical/examinations/${examination.id}/`, examination);
}

export const deleteExamination = (id: number) => {
  return httpDelete(`medical/examinations/${id}/`);
};
