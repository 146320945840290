import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Patient } from '../data/usePatients';
import { Prescription } from '../data/usePrescriptions';
import { Doctor } from '../types/doctor';
import { Practice } from '../types/practice';
import { ReceiptPrescription } from '../types/receiptPrescription';

interface ReceiptState {
  prescriptions: ReceiptPrescription[],
  signature: string,
  pharmacy: string,
}

const convert = (prescription: Prescription): ReceiptPrescription => ({
  id: prescription.id,
  date: prescription.date,
  title: prescription.pharmaceutical_free_text ? prescription.pharmaceutical_free_text : prescription.title,
  amount: prescription.amount,
  unit: prescription.unit,
  share_of_thc: prescription.share_of_thc,
  share_of_cbd: prescription.share_of_cbd,
  form: prescription.form,
  duration: prescription.duration,
  prescription_text: prescription.prescription_text,
  producer: prescription.producer,
});

export function useReceipt(
  prescriptions: Prescription[],
  patient: Patient,
  doctor: Doctor,
  practice: Practice | null,
) {

  const [receipt, setReceipt] = useState<ReceiptState>({
    prescriptions: prescriptions.map(convert),
    signature: '',
    pharmacy: '',
  });

  const prescribingDoctor: Doctor = useMemo(() => ({
    id: doctor.id,
    occupational_title: doctor.occupational_title,
    first_name: doctor.first_name,
    last_name: doctor.last_name,
    street: (practice || doctor).street,
    city: (practice || doctor).city,
    postal_code: (practice || doctor).postal_code,
    country_code: (practice || doctor).country_code,
    email: (practice || doctor).email,
    phone_number: (practice || doctor).phone_number,
    is_consultation_doctor: doctor.is_consultation_doctor,
    label: doctor.label,
    short_name: doctor.short_name,
  }), [doctor, practice]);


  useEffect(() => {
    setReceipt(prevState => ({
      ...prevState,
      prescriptions: prescriptions.map(convert),
    }));
  }, [prescriptions]);

  const onSignatureUpdate = (signature: string) => {
    setReceipt(prevState => ({
      ...prevState,
      signature: signature,
    }));
  };

  const onPharmacyUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceipt(prevState => ({
      ...prevState,
      pharmacy: e.target.value,
    }));
  };

  const isValid = useMemo(
    () => receipt.signature.length > 0 && receipt.pharmacy.length > 0,
    [receipt.pharmacy, receipt.signature],
  );

  const fileName = useMemo(() => `${format(new Date(), 'yyyy_MM_dd')}_${patient.last_name}.pdf`, [patient]);

  const formattedIssueDate = useMemo(() => format(new Date(), 'dd.MM.yyyy'), []);

  return {
    patient,
    doctor: prescribingDoctor,
    ...receipt,
    onSignatureUpdate,
    onPharmacyUpdate,
    isValid,
    fileName,
    formattedIssueDate,
  };
}