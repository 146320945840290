export const getDiagnosisCertaintyLetter = (name: string) => {
  switch (name) {
    case 'secured':
      return 'G';
    case 'suspicion_of':
      return 'V';
    case 'state_after':
      return 'Z';
    case 'excluded':
      return 'A';
    default:
      return 'G';
  }
};
