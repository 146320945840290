import classNames from 'classnames';
import { ChangeEvent, TextareaHTMLAttributes, useState } from 'react';
import Spinner from '../Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import useTextareaAutosize from 'hooks/useTextareaAutosize';
import PublicTooltip from 'components/PublicTooltip';
import { ReactComponent as MagicWand } from 'icons/magic_wand.svg';

export type TextAreaProps = {
  label: string;
  loading?: boolean;
  finished?: boolean;
  isPublic?: boolean;
  isMagic?: boolean;
  id: string;
  onMagicFill?: () => void;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea({
  loading,
  label,
  finished,
  isPublic,
  isMagic,
  onMagicFill,
  id,
  ...rest
}: TextAreaProps) {
  const { handleKeyUp } = useTextareaAutosize(id);
  const { onChange } = rest;
  const [ isOverwritten, setIsOverwritten ] = useState(false);

  const doMagicFill = () => {
    setIsOverwritten(true);
    onMagicFill && onMagicFill();
  };

  const doChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setIsOverwritten(false);
    onChange && onChange(event);
  };

  return (
    <>
      <div className="flex">
        <div className="grow flex">
          <label htmlFor={id} className="text-xs mb-1">
            {label}
          </label>
          {
            isMagic && (
              <div className="ml-1 mb-1 cursor-pointer" onClick={doMagicFill}>
                <MagicWand />
              </div>
            )
          }
        </div>
        {isPublic && <PublicTooltip />}
      </div>
      <div className="text-swopa-primary-dark-blue relative ">
        <textarea
          id={id}
          onKeyUp={handleKeyUp}
          {...rest}
          onChange={doChange}
          className={classNames(
            'block w-full  rounded-md text-sm p-2',
            {
              'border border-solid border-swopa-secondary-light-blue bg-sky-100': isOverwritten,
              'border-solid border-swopa-grey-3 border bg-white': !isOverwritten,
            },
            rest.className
          )}
        />
        {loading ? (
          <Spinner className="absolute fill-current top-2 right-2" />
        ) : (
          <>{!finished && <CheckMark className="ml-1 absolute top-2 right-2" />}</>
        )}
      </div>
    </>
  );
}
