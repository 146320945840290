import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import useComponentVisible from 'hooks/useComponentVisible';

type Props = {
  trigger: ReactNode;
  triggerOnHover?: boolean;
  content: (props: { setVisible: (value: boolean) => void }) => ReactNode;
  className?: string;
  isDoctorCircle?: boolean;
};

export default function Popper({
  trigger,
  triggerOnHover = false,
  content,
  className,
  isDoctorCircle = false,
}: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [isDoctorCircle ? -8 : 0, 2] } }],
  });

  const handleSetVisible = () => {
    setIsComponentVisible(true);
    if (update) update();
  };

  return (
    <div ref={ref} className="z-auto">
      <button
        {...(!triggerOnHover && { onClick: handleSetVisible })}
        {...(triggerOnHover && { onMouseEnter: handleSetVisible })}
        {...(triggerOnHover && { onMouseLeave: () => setIsComponentVisible(false) })}
        ref={setReferenceElement}
        className={classNames(className)}
        type="button"
      >
        {trigger}
      </button>
      <div
        ref={setPopperElement}
        className={classNames({ hidden: !isComponentVisible, 'z-10': isComponentVisible })}
        style={styles.popper}
        {...attributes.popper}
      >
        {content({ setVisible: setIsComponentVisible })}
      </div>
    </div>
  );
}
