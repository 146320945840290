import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { usePatient, usePatientSurveys } from 'data/usePatients';
import { usePatientAppointments } from 'data/useAppointments';
import { ReactComponent as ArrowLeft } from 'icons/arrow_left.svg';
import PatientHeader from 'components/PatientHeader';
import PatientEventFeed from 'components/PatientEventFeed';
import Sidebar from 'components/Sidebar';

export default function Patient() {
  const { id } = useParams();
  const { data, mutate: mutatePatient } = usePatient(id);
  const { survey } = usePatientSurveys(id);

  const { data: appointments, mutate: mutateAppointment } = usePatientAppointments(id);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRefetchPatientData = async () => await mutatePatient();
  const handleRefetchAppointmentData = async () => await mutateAppointment();

  return (
    <div>
      <Helmet>
        <title>{data ? `${data?.first_name} ${data?.last_name} - ` : ''}Enmedify</title>
      </Helmet>
      <button
        onClick={() => navigate(location.key === 'default' ? '/patients' : (-1 as any))}
        className="inline-flex py-8 items-center text-swopa-grey-4 hover:text-swopa-secondary-light-blue"
      >
        <ArrowLeft className="stroke-current mr-2" />
        Zurück
      </button>
      {data && (
        <>
          <PatientHeader patient={data} />
          <div className="mb-4" />
          <div className="grid grid-cols-12 mt-12">
            <div className="col-span-12 lg:col-span-9 lg:mr-8">
              <PatientEventFeed
                patient={data}
                appointments={appointments?.results}
                survey={survey}
                handleRefetchAppointmentData={handleRefetchAppointmentData}
                handleRefetchPatientData={handleRefetchPatientData}
              />
            </div>
            <div className="col-span-12 order-first lg:col-span-3 lg:order-last">
              <Sidebar patient={data} handleRefetchPatientData={handleRefetchPatientData} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
