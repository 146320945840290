import { Formik } from 'formik';
import { format } from 'date-fns';

import TextArea, { TextAreaProps } from 'components/inputs/TextArea';
import {
  Examination,
  useExaminations,
  deleteExamination,
  updateExamination,
} from 'data/useExaminations';
import SectionWrapper from './SectionWrapper';
import { useFieldAutosave } from 'hooks/useAutoSave';
import SuffixInput from 'components/SuffixInput';
import SectionFooter from './SectionFooter';
import DeleteMenu from './DeleteMenu';
import { useAppointmentContext } from 'contexts/Appointment';
import BmiMeter from 'components/BmiMeter';

export default function ExaminationSection({
  item,
  expanded,
  appointmentId,
}: {
  item: Examination;
  expanded: boolean;
  appointmentId: number;
}) {
  const { mutate } = useExaminations(item.patient, appointmentId);
  const handleRefetchData = async () => await mutate();
  const { refreshState } = useAppointmentContext();

  const doctorFirstName = item?.doctor?.first_name || '';
  const doctorLastName = item?.doctor?.last_name || '';

  return (
    <SectionWrapper
      handleRefetchData={handleRefetchData}
      expanded={expanded}
      headline="Körperliche Untersuchung"
      content={
        <Formik initialValues={item} onSubmit={() => {}} enableReinitialize>
          {({ values, handleChange }) => (
            <form>
              <AutoSaveTextArea
                label="Allgemeinzustand / Ernährungszustand"
                name="general_state_of_health"
                id="general_state_of_health"
                placeholder="Hier eintippen ..."
                className="mb-4"
                value={values.general_state_of_health}
                onChange={handleChange}
              />
              <div className="flex flex-col">
                <AutoSaveInput
                  label="Größe"
                  name="height"
                  id="height"
                  suffix="cm"
                  type="number"
                  className="mb-4"
                  value={values.height}
                  onChange={handleChange}
                />
                <AutoSaveInput
                  label="Gewicht"
                  name="weight"
                  id="weight"
                  type="number"
                  suffix="kg"
                  className="mb-4"
                  value={values.weight}
                  onChange={handleChange}
                />
                <BmiMeter bmi={item.bmi} handleRefetchData={handleRefetchData} />
                <AutoSaveInput
                  label="RR (Blutdruck)"
                  type="text"
                  name="rr"
                  id="rr"
                  suffix="mmHg"
                  className="mb-4"
                  value={values.rr}
                  maxLength={7}
                  onChange={handleChange}
                />
                <AutoSaveInput
                  label="SpO2"
                  name="spo2"
                  id="spo2"
                  type="number"
                  suffix="%"
                  className="mb-4"
                  value={values.spo2}
                  onChange={handleChange}
                />
                <AutoSaveInput
                  label="Pulsfrequenz"
                  name="pulse_rate"
                  id="pulse_rate"
                  suffix="bpm"
                  type="number"
                  className="mb-4"
                  value={values.pulse_rate}
                  onChange={handleChange}
                />
              </div>
              <AutoSaveTextArea
                label="Auskultation Lunge"
                name="ausculation_lungs"
                id="ausculation_lungs"
                placeholder="Lunge frei, vesikuläres Atemgeräusch, …"
                className="mb-4"
                value={values.ausculation_lungs}
                onChange={handleChange}
              />
              <AutoSaveTextArea
                label="Mund, Zunge, Rachen"
                name="mouth_tongue_faucal"
                id="mouth_tongue_faucal"
                placeholder="Lippen rosig, Mund- und Rachenschleimhaut unauffällig, …"
                className="mb-4"
                value={values.mouth_tongue_faucal}
                onChange={handleChange}
              />
              <AutoSaveTextArea
                label="Neurologisch/Pupillen"
                name="neurological_examination"
                id="neurological_examination"
                placeholder="Neurologisch motorisch und sensorisch o.p.B.; Pupillen rund, isokor, seitengleich lichtreagibel, …"
                className="mb-4"
                value={values.neurological_examination}
                onChange={handleChange}
              />
            </form>
          )}
        </Formik>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.created_at), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    await deleteExamination(item.id);
                    await mutate();
                    refreshState();
                  }}
                />
              </div>
            </div>
          }
          right={
            <span className="text-xs">
              {doctorFirstName} {doctorLastName}
            </span>
          }
        />
      }
    />
  );
}

function AutoSaveTextArea(props: TextAreaProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updateExamination, name: props.name });

  return <TextArea finished={finished} {...props} loading={loading} />;
}

function AutoSaveInput(props: any) {
  const { loading, finished, error } = useFieldAutosave({
    update: updateExamination,
    name: props.name,
  });
  return <SuffixInput finished={finished} {...props} error={error} loading={loading} />;
}
