import { useState, ReactNode } from 'react';

import DocumentActions from './DocumentActions';
import NoteActions from './NoteActions';
import PatientDataFormActions from './PatientDataFormActions';
import { Document } from 'data/useDocuments';
import Spinner from 'components/Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import useLoading from 'hooks/useLoading';
import { useSidebarContext } from 'contexts/Sidebar';
import classNames from 'classnames';
import { Note } from 'data/useNotes';
import NoteCreateForm from './NoteCreateForm';

type Props = {
  title: string;
  content: ReactNode;
  handleRefetchPatientData?: () => void;
  documents?: Array<Document>;
  refetchDocuments?: () => any;
  notes?: Array<Note>;
  refetchNotes?: () => any;
  patientId?: number | any;
  panelType?: string;
};

export default function ExpandPanel(props: Props) {
  const {
    title,
    content,
    handleRefetchPatientData,
    documents,
    patientId,
    refetchDocuments,
    panelType,
    refetchNotes,
    notes
  } = props;
  const [open, setOpen] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const { loading, setLoading, finished, setFinished } = useLoading();
  const { state } = useSidebarContext();

  const handleToggle = () => {
    if (!state.isEditing) {
      setOpen(!open);
      if (open) handleRefetchPatientData && handleRefetchPatientData();
    }
  };

  const renderActions = () => {
    if (documents && refetchDocuments) {
      return (
        <DocumentActions
          documents={documents}
          refetchDocuments={refetchDocuments}
          patientId={patientId}
          setLoading={setLoading}
          setFinished={setFinished}
          handleToggle={handleToggle}
          open={open}
        />
      );
    } else if (notes && refetchNotes) {
      return (
        <NoteActions
          patientId={patientId}
          handleToggle={handleToggle}
          open={open}
          setShowForm={setShowForm}
          showForm={showForm}
        />
      );
    } else {
      return (
        <PatientDataFormActions
          handleToggle={handleToggle}
          open={open}
          setOpen={() => setOpen(true)}
        />
      );
    }
  };

  const renderContent = () => {
    if (notes && refetchNotes) {
      return [showForm && <NoteCreateForm patientId={patientId} setShowForm={setShowForm} />, content];
    } else {
      return content;
    }
  };

  return (
    <>
      <div
        className={classNames(
          'text-swopa-primary-dark-blue mb-2 bg-white rounded shadow w-full relative',
          { 'z-[9999]': state.isEditing && panelType === 'stammdaten' },
        )}
        data-panel-type={panelType}
      >
        <div className="flex items-center justify-between w-full">
          <button className="flex-grow py-3 pl-4" onClick={handleToggle}>
            <h4 className="flex items-center text-swopa-secondary-light-blue text-sm font-radikal">
              {title}
              {loading ? (
                <Spinner className="ml-2" />
              ) : (
                <>{!finished && <CheckMark className="ml-2" />}</>
              )}
            </h4>
          </button>
          <div className="grid grid-flow-col items-center">
            {renderActions()}
          </div>
        </div>
        {open && renderContent()}
      </div>
    </>
  );
}
