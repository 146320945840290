import { ReactNode, useState, useEffect } from 'react';
import { ReactComponent as ArrowUp } from 'icons/arrow_up.svg';
import classNames from 'classnames';

type Props = {
  headline: ReactNode;
  content: ReactNode;
  footer: ReactNode;
  className?: string;
  handleRefetchData: () => void;
};

export default function ExpandPanel({
  className,
  headline,
  content,
  footer,
  handleRefetchData,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleOnClick = () => {
    setOpen(!open);
    if (open) {
      // always show the latest server state when expanding the panel
      handleRefetchData();
    }
  };

  return (
    <div
      className={classNames(
        'bg-swopa-grey-1 rounded-lg border border-solid border-swopa-grey-3',
        className,
      )}
    >
      <div
        className={classNames(
          'bg-white px-4 py-2 cursor-pointer rounded-lg border border-solid mt-[-1px] mr-[-1px] ml-[-1px] border-swopa-grey-3',
          { 'mb-[-1px]': !open },
        )}
      >
        <button onClick={handleOnClick} className="w-full flex items-center justify-between">
          <h2 className="text-base">{headline}</h2>
          <ArrowUp
            className={classNames('transition-transform stroke-swopa-grey-4 cursor-pointer', {
              'rotate-180': !open,
            })}
          />
        </button>
      </div>
      {open && (
        <>
          <div className={classNames('p-4 bg-swopa-grey-1')}>{content}</div>
          <div className="px-4 py-2 border-t border-solid border-swopa-grey-3 text-swopa-grey-4 text-xs">
            {footer}
          </div>
        </>
      )}
    </div>
  );
}
