import PreassessmentEvent from './Preassessment';
import AppointmentEvent from './Appointment';
import EventWrapper from './EventWrapper';
import { Patient, PatientSurvey } from 'data/usePatients';
import { Appointment } from 'data/useAppointments';
import { AppointmentProvider } from '../../contexts/Appointment';
import SurveyResult from './SurveyResult';
import { useState } from 'react';

type EventProps = {
  patient: Patient;
  appointments: Array<Appointment> | undefined;
  survey?: PatientSurvey;
  handleRefetchPatientData: () => void;
  handleRefetchAppointmentData: () => void;
};

export default function PatientEventFeed({
  patient,
  appointments,
  survey,
  handleRefetchPatientData,
  handleRefetchAppointmentData,
}: EventProps) {
  const sortedFeed = appointments
    
  let initialAppointmentDate: string | number = -1;
  sortedFeed?.forEach((item, index) => {
    if (['attended', 'not_attended', 'created'].indexOf(item.state) !== -1 && initialAppointmentDate === -1) {
      initialAppointmentDate = item.created_at;
    }
  });

  const sortedFeedModified = sortedFeed?.map((item, index) => ({
      ...item, 
      title: item.created_at <= initialAppointmentDate ? 'Erstgespräch' : 'Folgetermin' 
    })
  )?.sort((a: any, b: any) => (new Date(a.created_at) as any) - (new Date(b.created_at) as any))
  .reverse();

  return (
    <>
      {sortedFeedModified?.map((appointment: Appointment & { title: string }, i: number) => {
        return (
          <EventWrapper
            key={i}
            status={appointment.state}
            date={appointment.created_at}
            doctor={appointment.doctor}
            format={appointment.format}
            title={appointment.title}
            appointment_id={appointment.id}
            content={
              <AppointmentProvider>
                <AppointmentEvent
                  appointmentInfo={appointment}
                  patient={patient}
                  handleRefetchData={handleRefetchAppointmentData}
                />
              </AppointmentProvider>
            }
            expanded={false}
            patient={patient}
            eventType="appointment"
          />
        );
      })}
      {survey && (
        <EventWrapper
          status={'submitted'}
          date={survey.created_at}
          doctor={null}
          title="Fragebogen"
          content={<SurveyResult survey={survey} patientId={patient.id} />}
          expanded={false}
          patient={patient}
          eventType="preassessment"
        />
      )}
      {patient.pre_assesment_payload && !survey && (
        <EventWrapper
          status={patient.pre_assessment}
          date={patient.pre_assesment_date}
          doctor={null}
          title="Vorbeurteilung"
          content={
            <PreassessmentEvent patient={patient} handleRefetchData={handleRefetchPatientData} />
          }
          expanded={false}
          patient={patient}
          eventType="preassessment"
        />
      )}
    </>
  );
}
