import { Form, Formik } from 'formik';

import SectionWrapper from './SectionWrapper';
import TextArea, { TextAreaProps } from 'components/inputs/TextArea';
import { useFieldAutosave } from 'hooks/useAutoSave';
import { Patient, updatePatient, usePatient } from 'data/usePatients';

export default function PreassessmentNoteSection({
  patient,
  expanded,
}: {
  patient: Patient;
  expanded: boolean;
}) {
  const { mutate } = usePatient(patient.id);
  const handleRefetchData = async () => await mutate();

  return (
    <SectionWrapper
      expanded={expanded}
      handleRefetchData={handleRefetchData}
      headline={'Notiz'}
      content={
        <Formik initialValues={patient} onSubmit={() => {}} enableReinitialize>
          {({ values, handleChange }) => (
            <Form>
              <AutoSaveTextArea
                label="Inhalt"
                name="pre_assessment_note"
                id="pre_assessment_note"
                placeholder="Neue Notiz..."
                className="mb-4"
                value={values.pre_assessment_note || ''}
                onChange={handleChange}
              />
            </Form>
          )}
        </Formik>
      }
    />
  );
}

function AutoSaveTextArea(props: TextAreaProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updatePatient, name: props.name });
  return <TextArea {...props} loading={loading} finished={finished} />;
}
