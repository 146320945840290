import { useCallback } from 'react';
import { KeyedMutator } from 'swr';
import useSWRInfinite from 'swr/infinite';

interface PaginationResult<T> {
  next?: string | null;
  previous?: string | null;
  count: number;
  results: Array<T>;
}
const baseUrl = process.env.REACT_APP_BACKEND_URL;
export function useInfiniteScroll<T extends PaginationResult<unknown>>({
  url,
  pageSize = 10,
}: {
  url: string;
  pageSize?: number;
}): {
  results: T['results'];
  next: () => void;
  isReachingEnd: boolean;
  isRefreshing: boolean;
  isLoadingMore: boolean;
  mutate: KeyedMutator<T[]>;
} {
  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<T>(
    (pageIndex, previousPageData) => {
      // reached the end
      if (previousPageData && !previousPageData.results) return null;

      // first page, we don't have `previousPageData`
      if (pageIndex === 0) {
        const urlWithLimit = new URL(url, baseUrl);
        urlWithLimit.searchParams.append('limit', pageSize.toString());
        urlWithLimit.searchParams.append('offset', pageIndex.toString());
        return urlWithLimit.toString();
      }

      // add the cursor to the API endpoint
      return previousPageData?.next?.replace('http://', 'https://');
    },
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const next = useCallback(() => setSize(size + 1), [size]);

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    (isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined')) ?? false;
  const isEmpty = data?.[0]?.results?.length === 0;
  const isReachingEnd =
    isEmpty ||
    data?.[data?.length - 1]?.next == null ||
    (data && (data[data.length - 1]?.results?.length || -1) < pageSize);
  const isRefreshing = (isValidating && data && data.length === size) ?? false;

  const results = data?.map(({ results }) => results).flat() ?? [];

  return {
    results,
    next,
    isReachingEnd,
    isRefreshing,
    isLoadingMore,
    mutate,
  };
}
