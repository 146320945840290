import classnames from 'classnames';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  bgColor?: string;
  disabled?: boolean;
}

export default function Button({
  children,
  bgColor = 'blue',
  disabled = false,
  ...props
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      {...props}
      className={classnames(
        props.className,
        'px-10 py-3 font-radikal rounded-[4px] shadow-xl w-full uppercase text-button-text',
        {
          'swopa-secondary-light-blue text-swopa-primary-dark hover:swopa-secondary-light-blue':
            bgColor === 'green' && !disabled,
          'text-swopa-primary-grey-light': disabled,
          'bg-swopa-secondary-light-blue hover:bg-swopa-secondary-light-blue-hover text-white':
            bgColor === 'blue' && !disabled,
          'swopa-secondary-light-blue': bgColor === 'green' && disabled,
          'bg-swopa-secondary-light-blue-hover': bgColor === 'blue' && disabled,
        },
      )}
    >
      {children}
    </button>
  );
}
