import { GlobalErrorContext } from 'contexts/GlobalError';
import { resetPasswordConfirm } from 'data/resetPassword';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from './Button';
import Input from './inputs/Input';
import {
  validationSchema as passwordValidationSchema,
  defaultValues,
  PASSWORD,
  PASSWORD_REPEAT,
} from 'components/Profile/PasswordRepeat';

export default function ResetPasswordConfirmForm() {
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { setError, setSuccess } = useContext(GlobalErrorContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: passwordValidationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, { resetForm }) => {
      const uid = params.get('uid');
      const token = params.get('token');
      if (uid && token) {
        setLoading(true);
        try {
          await resetPasswordConfirm({ uid, token, password: values.password });
          setSuccess('Passwort erfolgreich zurückgesetzt');
          resetForm();
          setSubmitted(true);
        } catch (e: any) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const { values, handleChange, handleSubmit, handleBlur, touched, errors, isValid } = formik;
  return submitted ? (
    <div>
      <h2 className="text-swopa-secondary-light-blue mb-6">Erfolgreich</h2>
      <p className="mb-4">
        Ihr Passwort wurde erfolgreich zurückgesetzt. Gehen Sie zur Login-Seite und melden Sie sich
        mit Ihren neuen Zugangsdaten an.
      </p>
      <Button onClick={() => navigate('/login')}>Zur Anmeldung</Button>
    </div>
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <h2 className="text-swopa-secondary-light-blue mb-6">Passwort wählen</h2>
        <Input
          onChange={handleChange}
          value={values[PASSWORD]}
          type="password"
          id={PASSWORD}
          name={PASSWORD}
          onBlur={handleBlur}
          autoComplete="new-password"
          placeholder="Neues Passwort"
          touched={touched[PASSWORD]}
          error={errors[PASSWORD]}
          errorDots
        />
        <Input
          onChange={handleChange}
          value={values[PASSWORD_REPEAT]}
          className="mt-4"
          type="password"
          id={PASSWORD_REPEAT}
          name={PASSWORD_REPEAT}
          onBlur={handleBlur}
          autoComplete="new-password"
          placeholder="Neues Passwort bestätigen"
          touched={touched[PASSWORD_REPEAT]}
          error={errors[PASSWORD_REPEAT]}
          errorDots
        />
        <Button className="mt-4" type="submit" disabled={loading}>
          Zurücksetzen
        </Button>
      </form>
    </>
  );
}
