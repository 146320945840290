import { Helmet } from 'react-helmet-async';

export default function NotFound() {
  return (
    <div>
      <Helmet>
        <title>Enmedify | Seite nicht gefunden</title>
      </Helmet>
      <h1>404 - Seite nicht gefunden</h1>
    </div>
  );
}
