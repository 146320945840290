import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Analytics } from '@vercel/analytics/react';

Sentry.init({
  dsn: 'https://fef801ab07f64ed68794dfdde3b7db89@o1171261.ingest.sentry.io/6265713',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  enabled: process.env.REACT_APP_BRANCH === 'MAIN',
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const isProd = process.env.REACT_APP_BRANCH === 'MAIN';

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
      <Analytics mode={isProd ? 'production' : 'development'} />
    </React.StrictMode>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
