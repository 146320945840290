import { MouseEvent, useRef, useState, ChangeEvent, useEffect, useContext } from 'react';
import { format } from 'date-fns';
import classNames from 'classnames';
import Box from './Box';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';
import { ReactComponent as FileIcon } from 'icons/file.svg';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import DocumentCategorySelect from 'components/inputs/DocumentCategorySelect';
import http from 'components/Profile/http';
import Spinner from 'components/Spinner';
import { GlobalErrorContext } from 'contexts/GlobalError';
import Popper from 'components/Popper';
import TrashIcon from './TrashIcon';
import classnames from 'classnames';

interface DocumentProps {
  filename: string;
  filetype: string;
  size?: string;
  uploadDate: Date;
  downloadLink: string;
  deleteCallback?: () => void;
  category: string;
  patientId: number;
  documentId: number;
  refetchDocuments: () => void;
  uploaded_by_shortname?: string;
  uploaded_by_fullname?: string;
  uploaded_by_picture?: any;
}

const fileCategories = [
  { label: 'Kategorie auswählen', value: '' },
  { label: 'Arztbrief stationär', value: 'doctor_letter_inpatient' },
  { label: 'Arztbrief ambulant', value: 'doctor_letter_outpatient' },
  { label: 'Gutachten', value: 'assessment' },
  { label: 'Rechnung', value: 'bill' },
  { label: 'Rezept', value: 'recipe' },
  { label: 'Überweisung', value: 'transfer' },
  { label: 'Datenschutzerklärung', value: 'privacy_policy' },
  { label: 'Datenverarbeitungsvereinbarung', value: 'data_processing_agreement' },
  { label: 'AGB', value: 'agb' },
  { label: 'Aufklärung', value: 'enlightenment' },
  { label: 'Information über privatärztliche Behandlung', value: 'private_medical_treatment' },
  { label: 'Personalausweis', value: 'id' },
  { label: 'Reisepass', value: 'passport' },
  { label: 'Krankenkassenkarte', value: 'health_insurance_card' },
  { label: 'Sonstiges', value: 'miscellaneous' },
];

export default function Document({
  filename,
  filetype,
  size,
  uploadDate,
  downloadLink,
  deleteCallback,
  category = '',
  patientId,
  documentId,
  refetchDocuments,
  uploaded_by_shortname,
  uploaded_by_fullname,
  uploaded_by_picture,
}: DocumentProps) {
  const [selectedCategory, setSelectedCategory] = useState(category);
  const selectWrapperRef = useRef() as any;
  const deleteWrapperRef = useRef() as any;
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);
  const { setError } = useContext(GlobalErrorContext);

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation();
    deleteCallback && deleteCallback();
  };

  const handleDownload = (e: MouseEvent) => {
    if (e.target === selectWrapperRef.current || selectWrapperRef.current.contains(e.target))
      return;
    if (e.target === deleteWrapperRef.current || deleteWrapperRef.current.contains(e.target))
      return;
    window.open(downloadLink);
  };

  const handleSelectCategory = async (e: ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    setSelectedCategory(category);
    try {
      setLoading(true);
      setFinished(false);
      await http.patch(`/medical/documents/${documentId}?patient=${patientId}`, {
        content_type: category,
      });
      await refetchDocuments();
    } catch (error: any) {
      setError('Etwas ist schiefgelaufen. Bitte versuchen Sie es erneut.');
      setFinished(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  const baseButton = 'px-4 py-1 font-radikal rounded-[4px] w-full text-xs';

  return (
    <Box className="cursor-pointer transition-shadow hover:shadow-sm">
      <div className="flex flex-col sm:flex-row justify-between" onClick={handleDownload}>
        <div
          className="flex justify-between py-2 px-4 pb-0 sm:pb-2 cursor-default"
          ref={selectWrapperRef}
        >
          <div className="flex">
            <div className="flex flex-col">
              <DocumentCategorySelect
                options={fileCategories}
                selected={selectedCategory}
                handleChange={handleSelectCategory}
                name="filetype_select"
              />
              <div className="flex items-center">
                <span className="text-xs text-swopa-secondary-grey">{filename}</span>
                {loading ? (
                  <Spinner className="swopa-grey-2 w-3 h-3 ml-2" />
                ) : (
                  <>{!finished && <CheckMark className="fill-white w-3 h-3 ml-2" />}</>
                )}
              </div>
            </div>
            {size && <span className="text-swopa-secondary-grey text-documents-info">{size}</span>}
          </div>
        </div>
        <div className="flex shrink">
          <div className="flex items-center">
            <Popper
                triggerOnHover
                className="flex"
                isDoctorCircle
                trigger={
                  <span
                    className={classnames(
                      'block overflow-hidden bg-[#16DF9F] border border-white rounded-full mr-2 text-center text-white flex-none',
                      'ml-[-16px] leading-[2.15rem] w-9 h-9  text-xs',
                    )}
                  >
                    {uploaded_by_picture && (
                      <img className="rounded-full" src={uploaded_by_picture} alt="" />
                    )}
                    {!uploaded_by_picture && <span>{uploaded_by_shortname}</span>}
                  </span>
                }
                content={() =>
                  <div className="bg-white p-3 w-max drop-shadow rounded-lg text-center">
                    <span className="text-swopa-primary-dark-blue text-xs">{uploaded_by_fullname}</span>
                  </div>
                }
              />
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue py-2 px-4 text-xs">
            <CalendarIcon className="stroke-swopa-primary-dark-blue mr-2" />
            <span>{format(uploadDate, 'dd.MM.yyyy')}</span>
          </div>
          <div className="flex items-center text-swopa-primary-dark-blue py-2 px-4 text-xs">
            <FileIcon className="mr-2" />
            <span className="uppercase w-48 flex-none text-ellipsis overflow-hidden">{filetype}</span>
          </div>
          <div className="flex pl-3 pr-4 py-3 ">
            <a
              href={downloadLink}
              target="_blank"
              rel="noopener noreferrer"
              download={filename}
              className="text-swopa-primary-dark-blue flex items-center transition-colors hover:text-swopa-secondary-light-blue-hover mr-4"
            >
              <DownloadIcon />
            </a>
            <div ref={deleteWrapperRef}>
              <Popper
                trigger={
                  <button className="p-2 mt-1 text-swopa-primary-dark-blue">
                    <TrashIcon className='text-swopa-primary-dark-blue'/>
                  </button>
                }
                content={({ setVisible }) => (
                  <div className="bg-swopa-primary-white flex flex-col rounded p-2 drop-shadow">
                    <button
                      onClick={(e: MouseEvent) => {
                        handleDelete(e);
                        setVisible(false);
                      }}
                      className={classNames(
                        baseButton,
                        'bg-swopa-warning-red hover:bg-swopa-warning-red-hover text-white shadow-md',
                      )}
                    >
                      Löschen
                    </button>
                    <button
                      onClick={() => setVisible(false)}
                      className={classNames(
                        baseButton,
                        'mt-2 text-swopa-grey-4 hover:text-swopa-secondary-light-blue',
                      )}
                    >
                      Abbrechen
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
