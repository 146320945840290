import { CompanyInformationLocalization } from './types';

const companyInformation: CompanyInformationLocalization = {
  name: {
    de: 'Swiss Alpinopharma GmbH',
    ch: 'Topmed AG',
  },
  street: {
    de: 'Kurfürstendamm 207-208',
    ch: 'Steinenberg 23',
  },
  city: {
    de: '10719 Berlin',
    ch: '4051 Basel',
  },
  phone: {
    de: '+49 30 30807330',
    ch: '+41 061 416 08 00',
  },
  email: {
    de: 'service@help.enmedify.com',
    ch: 'support@enmedify.ch',
  },
};

export default companyInformation;
