import classNames from 'classnames';
import styles from './Toggle.module.css';

export type ToggleProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { label: string };

export default function Toggle({ label, className, ...props }: ToggleProps) {
  return (
    <div className={classNames('flex items-start', className)}>
      <div className="flex">
        <input {...props} className={styles.input} type="checkbox" />
        <label className={styles.label} htmlFor={props.name}></label>
      </div>

      <div className="ml-2">{label}</div>
    </div>
  );
}
