import { useContext, createContext, useReducer, ReactNode } from 'react';

const SidebarContext = createContext({});

export type SidebarContextState = {
  showMyDocumentsScreen: boolean;
  uploadableFiles: Array<any>;
  isEditing: boolean;
  isCanceling: boolean;
};

export type Action = { type: string; payload: any };

type State = {
  state: SidebarContextState;
  dispatch: (obj: Action) => void;
};

const reducer = (state: SidebarContextState, action: Action) => {
  switch (action.type) {
    case 'SHOW_MY_DOCUMENTS_SCREEN':
      return {
        ...state,
        showMyDocumentsScreen: action.payload.show,
        uploadableFiles: action.payload.uploadableFiles,
      };
    case 'SET_IS_EDITING':
      return {
        ...state,
        isEditing: !state.isEditing,
      };
    case 'SET_IS_CANCELING':
      return {
        ...state,
        isCanceling: !state.isCanceling,
      };
    default:
      return state;
  }
};

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const initialState = {
    showMyDocumentsScreen: false,
    uploadableFiles: [],
    isEditing: false,
    isCanceling: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SidebarContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext) as State;
