import { resetPassword } from 'data/resetPassword';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';
import Input from './inputs/Input';

export default function ResetPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  return submitted ? (
    <div>
      <h2 className="text-swopa-secondary-light-blue mb-6">Link versendet</h2>
      <p className="mb-4">
        Wir haben Ihnen einen Link per E-Mail gesendet, mit dem Sie Ihr Passwort zurück setzen
        können. Überprüfen Sie Ihren Spam-Ordner, falls Sie in den nächsten Minuten noch keine
        E-Mail von uns erhalten haben.
      </p>
      <Button onClick={() => navigate('/login')}>Zur Anmeldung</Button>
    </div>
  ) : (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          setLoading(true);
          await resetPassword({ email });
          setSubmitted(true);
        } catch (e) {
          // TODO
          console.log(e);
        } finally {
          setLoading(false);
        }
      }}
    >
      <h2 className="text-swopa-secondary-light-blue mb-6">Passwort wählen</h2>
      <Input
        type="email"
        placeholder="E-Mail Adresse"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
      />
      <Button className="mt-4" type="submit">
        Zurücksetzen
      </Button>
      <div className="mt-2">
        <span className="text-swopa-secondary-grey">
          Zurück zur{' '}
          <Link className="text-swopa-secondary-light-blue" to="/login">
            Anmeldung
          </Link>
        </span>
      </div>
    </form>
  );
}
