import { useFormikContext, useField } from 'formik';
import { useRef, useState, useCallback, useEffect } from 'react';
import { debounce, isEmpty, pick, has } from 'lodash';
import { handleEmptyStrings } from 'utils/handleEmptyStrings';
import { handleEmptyValues } from 'utils/handleEmptyValues';

export function useFieldAutosave({
  update,
  name,
}: {
  name: string;
  update: (values: any) => Promise<any>;
}) {
  const formik = useFormikContext<any>();
  const formikRef = useRef(formik);
  const [field] = useField(name as any);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [finished, setFinished] = useState(true);

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(async () => {
      const formik = formikRef.current;
      if (formik.isValid && (formik.touched[field.name] || formik.dirty) && !formik.isSubmitting) {
        setLoading(true);
        setFinished(false);
        setError(false);
        try {
          const value = handleEmptyStrings(pick(formik.values, ['id', field.name]));
          const response = await update(value);
          if (has(response, 'error')) {
            setError(response.msg);
          }
        } catch (e: any) {
          // todo: handle random errors
        } finally {
          setLoading(false);
        }
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (!formikRef.current.touched[field.name]) {
      formikRef.current?.setFieldTouched(field.name, true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  useEffect(() => {
    formikRef.current = formik;
  }, [formik.values]);

  useEffect(() => {
    debouncedUpdate();
  }, [debouncedUpdate, field.value]);

  return { loading, error, finished };
}

export function useFormAutosave({ update }: { update: (values: any) => Promise<any> }) {
  const formik = useFormikContext<any>();
  const formikRef = useRef(formik);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(async () => {
      const formik = formikRef.current;
      if (formik.isValid && (!isEmpty(formik.touched) || formik.dirty) && !formik.isSubmitting) {
        setLoading(true);
        setFinished(false);
        try {
          const values = handleEmptyValues(formik.values);
          await update(values);
        } catch (e) {
          console.log('error: ', e);
        } finally {
          setLoading(false);
        }
      }
    }, 500),
    [],
  );

  useEffect(() => {
    formikRef.current = formik;
  }, [formik]);

  useEffect(() => {
    debouncedUpdate();
  }, [debouncedUpdate, formik.values]);

  return { loading, finished };
}
