import { useRef, ChangeEvent, useEffect } from 'react';
import classNames from 'classnames';

import { ReactComponent as Delete } from 'icons/delete_circle.svg';
import { ReactComponent as Search } from 'icons/search.svg';
import { ReactComponent as EnterIcon } from 'icons/enter-icon.svg';
import DiagnosisAutocompleteSuggestion from 'components/DiagnosisAutocompleteSuggestion';

type Props = {
  results: Array<any>;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClearSearch: () => void;
  handleSelect: (value: any, certainty?: any) => any;
  handleRender: (value: any) => any;
  className?: string;
  isPatientSearch?: boolean;
  handleKeyDown: (e: any) => void;
  activeSuggestion: number;
  userInput: string;
  selected?: any;
  handleSelectOnEnter?: (value: any) => void;
  isDiagnosisSearch?: boolean;
};

export default function Autocomplete(props: Props) {
  const {
    results,
    handleSearch,
    handleClearSearch,
    handleSelect,
    handleRender,
    className,
    isPatientSearch,
    handleKeyDown,
    activeSuggestion,
    userInput,
    selected,
    handleSelectOnEnter,
    isDiagnosisSearch,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const inputFontSize = isPatientSearch ? 'text-xs' : 'text-sm';
  const inputBorderColor = isPatientSearch ? 'border-swopa-border' : 'border-swopa-grey-3';
  const inputPadding = isPatientSearch ? 'py-3' : 'p-2';

  const handleClickOutside = (e: any) => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (
        !wrapper.contains(e.target) &&
        inputRef.current !== e.target &&
        listRef.current &&
        !listRef.current.contains(e.target)
      ) {
        handleClearSearch();
      }
    }
  };

  useEffect(() => {
    if (selected) {
      handleSelectOnEnter && handleSelectOnEnter(selected);
    }
  }, [selected]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div ref={wrapperRef} className={classNames('flex w-full md:min-w-[20rem]', className)}>
      <div className="relative w-full md:min-w-[20rem]">
        <div className="relative">
          <input
            ref={inputRef}
            className={`${inputFontSize} w-full md:min-w-[20rem] ${inputPadding} px-8 rounded border ${inputBorderColor} text-swopa-primary-dark-blue focus:outline-none focus:border-swopa-secondary-light-blue placeholder:text-swopa-grey-4`}
            type="text"
            placeholder="Suche"
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            value={userInput}
          />
          <Search className="absolute left-3 top-[50%] translate-y-[-50%]" />
          {userInput && (
            <>
              {isPatientSearch ? (
                <button
                  onClick={handleSelect}
                  className="absolute right-3 top-[50%] translate-y-[-50%] cursor-pointer"
                >
                  <EnterIcon />
                </button>
              ) : (
                <button
                  onClick={handleClearSearch}
                  className="absolute right-3 top-[50%] translate-y-[-50%] cursor-pointer"
                >
                  <Delete />
                </button>
              )}
            </>
          )}
        </div>
        {results.length > 0 && (
          <div className="bg-white shadow-md rounded absolute top-14 left-0 z-10">
            <ul ref={listRef} className="overflow-y-scroll overflow-x-visible max-h-[400px]">
              {results?.map((result: any, i) => (
                <li key={i}>
                  {isDiagnosisSearch ? (
                    <DiagnosisAutocompleteSuggestion
                      index={i}
                      result={result}
                      handleSelect={handleSelect}
                      activeSuggestion={activeSuggestion}
                    />
                  ) : (
                    <div
                      className={classNames(
                        'text-swopa-primary-dark-blue hover:bg-swopa-grey-1 px-3 py-2 cursor-pointer max-w-[320px]',
                        { 'pointer-events-none hover:bg-none': typeof result === 'string' },
                        { 'bg-swopa-grey-1': activeSuggestion === i },
                      )}
                      onClick={() => handleSelect(result)}
                    >
                      {handleRender(result)}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
