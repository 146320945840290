import { useState, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';
import { usePatientAppointments } from 'data/useAppointments';
import { usePatient } from 'data/usePatients';

type Props = {
  title: string;
  content: ReactNode;
  status: string | null;
  date: any;
  doctor: any;
  format?: string;
  expanded: boolean;
  patient: any;
  eventType: string;
  appointment_id?: number;
};

export default function EventWrapper({
  title,
  content,
  status,
  date,
  doctor,
  format: meetingFormat,
  expanded,
  patient,
  eventType,
  appointment_id,
}: Props) {
  const [open, setOpen] = useState(expanded);
  const targetElementRef = useRef<HTMLDivElement>(null);
  const { mutate: mutateAppointment } = usePatientAppointments(patient.id);
  const { mutate: mutatePatient } = usePatient(patient.id);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (appointment_id?.toString() === params.get('open')) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [window.location.search]);

  useEffect(() => {
    // Scroll to the target element once the component is mounted
    if (targetElementRef.current && open) {
      targetElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [open]);

  const handleSetOpen = () => {
    setOpen(!open);
    // get latest server state when expanding the panel
    if (open) {
      if (eventType === 'appointment') {
        mutateAppointment();
      } else {
        mutatePatient();
      }
    }
  };

  const containerBorderColorHover = open
    ? 'hover:border'
    : 'hover:border-swopa-secondary-light-blue';
  const headerBgColor = open ? 'bg-swopa-secondary-light-blue' : 'bg-white';
  const headerBorderRadius = open ? 'rounded-t' : 'rounded';
  const headerHoverColor = open ? 'hover:bg-swopa-secondary-light-blue-hover' : 'hover:bg-white';
  const headingTextColor = open ? 'text-white' : 'text-swopa-secondary-light-blue';
  const rotateArrow180 = open && 'rotate-180';
  const arrowColor = open ? 'stroke-white' : 'stroke-swopa-secondary-light-blue';

  const EventWrapperInfo = () => {
    const doctorFirstName = doctor?.first_name || '';
    const doctorLastName = doctor?.last_name || '';

    const handleStatusColors = (status: string | null) => {
      switch (status) {
        case 'created':
          return 'bg-swopa-secondary-light-blue';
        case 'attended':
          return 'bg-swopa-accent-green';
        case 'not_attended':
          return 'bg-swopa-primary-orange';
        case 'positive':
          return 'bg-swopa-accent-green';
        case 'negative':
          return 'bg-swopa-warning-red';
        case 'cancelled':
          return 'bg-swopa-warning-red';
        case 'consultation':
          return 'bg-swopa-primary-orange';
        case 'open':
          return 'bg-swopa-secondary-light-blue';
        default:
          return 'bg-transparent';
      }
    };

    return (
      <>
        <span
          className={classNames(handleStatusColors(status), 'w-2 h-2 rounded-full block mr-4')}
        ></span>
        <span className="text-xs mr-4">{format(new Date(date), 'dd.MM.yyyy')}</span>
        <span className="text-xs mr-4 w-40 truncate">
          {doctorFirstName} {doctorLastName}
        </span>
        {meetingFormat ? (
          <span className="w-28 mr-4 py-1 text-center text-swopa-secondary-light-blue bg-[#e0edfe] rounded text-xxs font-radikal uppercase">
            {meetingFormat === 'in_person' ? 'Vor Ort' : 'Videoanruf'}
          </span>
        ) : (
          <span className="w-28 mr-4"></span>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(containerBorderColorHover, 'bg-white rounded border mb-2')}
      ref={targetElementRef}
    >
      <div
        className={classNames(
          headerBgColor,
          headerBorderRadius,
          headerHoverColor,
          'p-4 flex justify-between cursor-pointer',
        )}
        onClick={handleSetOpen}
      >
        <h2 className={classNames(headingTextColor, 'text-base')}>{title}</h2>
        <div className="group flex items-center">
          {!open && <EventWrapperInfo />}
          <button
            className={classNames(
              rotateArrow180,
              'flex items-center justify-center w-6 h-6 rounded-full group-hover:bg-white',
            )}
          >
            <ArrowDown
              className={classNames(
                arrowColor,
                'w-3 group-hover:stroke-swopa-secondary-light-blue',
              )}
            />
          </button>
        </div>
      </div>
      {open && <div className="p-4 shadow-md">{content}</div>}
    </div>
  );
}
