export const preassessmentStatuses = [
  {
    value: '',
    label: 'Vorbeurteilung',
    color: 'bg-transparent',
  },
  {
    value: 'submitted',
    label: 'Ausstehend',
    color: 'bg-swopa-secondary-light-blue',
  },
  {
    value: 'positive',
    label: 'Bestätigt',
    color: 'bg-swopa-accent-green',
  },
  {
    value: 'negative',
    label: 'Abgelehnt',
    color: 'bg-swopa-warning-red',
  },
  {
    value: 'consultation',
    label: 'Nachprüfung',
    color: 'bg-swopa-primary-orange',
  },
];
