import { useEffect } from 'react';
import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import { pick } from 'lodash';
import * as Yup from 'yup';
import classNames from 'classnames';

import { Patient, updatePatient, useMutatePatient } from 'data/usePatients';
import Spinner from 'components/Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import { ReactComponent as Calendar } from 'icons/calendar.svg';
import UnderlineInput, { UnderlineInputProps } from 'components/inputs/UnderlineInput';
import Toggle, { ToggleProps } from 'components/inputs/Toggle';
import Select, { SelectProps } from 'components/inputs/UnderlineSelect';
import { getPatientAge } from 'utils/getPatientAge';
import { useSidebarContext } from 'contexts/Sidebar';
import useLoading from 'hooks/useLoading';

type Props = {
  patient: Patient;
  className?: string;
};

const schema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  phone_number: Yup.number().required(),
  street: Yup.string().required(),
  postal_code: Yup.string().required(),
  city: Yup.string().required(),
  billing_address_is_different: Yup.boolean().required(),
  billing_first_name: Yup.string()
    .nullable()
    .when('billing_address_is_different', ([val], schema) => (val ? schema.required() : schema)),
  billing_last_name: Yup.string()
    .nullable()
    .when('billing_address_is_different', ([val], schema) => (val ? schema.required() : schema)),
  billing_street: Yup.string()
    .nullable()
    .when('billing_address_is_different', ([val], schema) => (val ? schema.required() : schema)),
  billing_postal_code: Yup.string()
    .nullable()
    .when('billing_address_is_different', ([val], schema) => (val ? schema.required() : schema)),
  billing_city: Yup.string()
    .nullable()
    .when('billing_address_is_different', ([val], schema) => (val ? schema.required() : schema)),
});

const getInsuranceTypeLabel = (type: any) => {
  switch (type) {
    case 'public':
      return 'Öffentlich';
    case 'private':
      return 'Privat';
    default:
      return 'Versicherungsart';
  }
};

const CancelEditingFormListener = () => {
  const { state, dispatch } = useSidebarContext();
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (state.isCanceling === true) {
      resetForm();
      dispatch({ type: 'SET_IS_CANCELING', payload: false });
    }
  }, [state.isCanceling]);

  return null;
};

export default function PatientDataForm({ patient }: Props) {
  const { state, dispatch } = useSidebarContext();
  const setEditFalse = () => dispatch({ type: 'SET_IS_EDITING', payload: false });
  const { loading, setLoading, finished, setFinished } = useLoading(setEditFalse, 250);
  const { mutate } = useMutatePatient(patient.id, true);

  const handleSubmit = async (values: any) => {
    try {
      let payload = values;
      if (values.billing_address_is_different === false) {
        payload = {
          ...values,
          billing_city: '',
          billing_first_name: '',
          billing_last_name: '',
          billing_postal_code: '',
          billing_street: '',
        };
      }
      setLoading(true);
      setFinished(false);
      await updatePatient(payload);
      await mutate();
      setLoading(false);
    } catch (error: any) {
      console.log('error', error);
    }
  };

  return (
    <Formik
      initialValues={{
        ...pick(patient, [
          'id',
          'date_of_birth',
          'height',
          'weight',
          'email',
          'phone_number',
          'street',
          'postal_code',
          'city',
          'billing_address_is_different',
          'billing_street',
          'billing_postal_code',
          'billing_city',
          'billing_first_name',
          'billing_last_name',
          'insurance_type',
          'insurance_company',
          'health_insurance_number',
          'first_name',
          'last_name',
        ]),
      }}
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <Form className="col-span-12 md:col-span-6 lg:col-span-12">
          <>
            {/* Personal data */}
            <div className="border-b px-4 pb-4 mb-4">
              <span className="text-swopa-grey-4 block mb-1 uppercase text-xs">Zur Person</span>
              <Field
                name="first_name"
                id="first_name"
                component={Input}
                placeholder={
                  !!(formikProps.touched.first_name && formikProps.errors.first_name)
                    ? 'Vorname ist ein Pflichtfeld'
                    : 'Vorname'
                }
                type="text"
                customClass={classNames({ '!border-0': !state.isEditing })}
                readOnly={!state.isEditing}
                error={!!(formikProps.touched.first_name && formikProps.errors.first_name)}
              />
              <Field
                name="last_name"
                id="last_name"
                component={Input}
                placeholder={
                  !!(formikProps.touched.last_name && formikProps.errors.last_name)
                    ? 'Nachname ist ein Pflichtfeld'
                    : 'Nachname'
                }
                type="text"
                customClass={classNames({ '!border-0': !state.isEditing })}
                readOnly={!state.isEditing}
                error={!!(formikProps.touched.last_name && formikProps.errors.last_name)}
              />
              <div className="flex justify-between items-center mb-1">
                <div className="w-[105px] relative">
                  <Field
                    name="date_of_birth"
                    id="date_of_birth"
                    component={Input}
                    placeholder="Geb. Datum"
                    type="date"
                    customClass="!border-0"
                    readOnly={!state.isEditing}
                  />
                  {state.isEditing && (
                    <button
                      type="button"
                      className="absolute top-0.5 right-0 bg-white pointer-events-none"
                    >
                      <Calendar className="stroke-swopa-primary-dark-blue" />
                    </button>
                  )}
                </div>
                {formikProps.values.date_of_birth && (
                  <span>{getPatientAge(formikProps.values.date_of_birth)} Jahre</span>
                )}
              </div>
              <div className="relative">
                <Field
                  name="height"
                  id="height"
                  component={Input}
                  placeholder="Größe"
                  type="text"
                  maxLength={3}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  readOnly={!state.isEditing}
                />
                {formikProps.values.height && (
                  <span className="absolute left-[30px] top-[50%] translate-y-[-50%] w-[20px] text-left pointer-events-none">
                    cm
                  </span>
                )}
              </div>
              <div className="relative">
                <Field
                  name="weight"
                  id="weight"
                  component={Input}
                  placeholder="Gewicht"
                  type="text"
                  maxLength={3}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  readOnly={!state.isEditing}
                />
                {formikProps.values.weight && (
                  <span className="absolute left-[30px] top-[50%] translate-y-[-50%] w-[20px] text-left pointer-events-none">
                    kg
                  </span>
                )}
              </div>
            </div>
            {/* Contact data */}
            <div className="border-b px-4 pb-4 mb-4">
              <div className="border-b pb-2">
                <span className="text-swopa-grey-4 block mb-1 uppercase text-xs">Kontakt</span>
                <Field
                  name="email"
                  id="email"
                  component={Input}
                  placeholder="E-Mail Adresse"
                  readOnly
                  customClass="!border-0"
                />
                <Field
                  name="phone_number"
                  id="phone_number"
                  component={Input}
                  placeholder="Tel.-Nummer"
                  readOnly={!state.isEditing}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  error={!!(formikProps.touched.phone_number && formikProps.errors.phone_number)}
                />
              </div>
              <div className="border-b py-2 mb-2">
                <span className="text-swopa-grey-4 block mb-1 uppercase text-xs">
                  Anschrift/Lieferadresse
                </span>
                <Field
                  name="street"
                  id="street"
                  component={Input}
                  placeholder={
                    !!(formikProps.touched.street && formikProps.errors.street)
                      ? 'Straße ist ein Pflichtfeld'
                      : 'Straße'
                  }
                  readOnly={!state.isEditing}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  error={!!(formikProps.touched.street && formikProps.errors.street)}
                />
                <Field
                  name="postal_code"
                  id="postal_code"
                  component={Input}
                  placeholder={
                    !!(formikProps.touched.postal_code && formikProps.errors.postal_code)
                      ? 'PLZ ist ein Pflichtfeld'
                      : 'PLZ'
                  }
                  readOnly={!state.isEditing}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  error={!!(formikProps.touched.postal_code && formikProps.errors.postal_code)}
                  maxLength={5}
                  type="number"
                  onInput={(e: any) => (e.target.value = e.target.value.slice(0, 5))}
                />
                <Field
                  name="city"
                  id="city"
                  component={Input}
                  placeholder={
                    !!(formikProps.touched.city && formikProps.errors.city)
                      ? 'Stadt ist ein Pflichtfeld'
                      : 'Stadt'
                  }
                  readOnly={!state.isEditing}
                  customClass={classNames({ '!border-0': !state.isEditing })}
                  error={!!(formikProps.touched.city && formikProps.errors.city)}
                />
              </div>
              <div>
                <Checkbox
                  className="text-sm flex"
                  label="Rechnungsadresse abweichend"
                  id="billing_address_is_different"
                  name="billing_address_is_different"
                  value={!!formikProps.values.billing_address_is_different as any}
                  checked={!!formikProps.values.billing_address_is_different}
                  onChange={formikProps.handleChange}
                  disabled={!state.isEditing}
                />
                {formikProps.values.billing_address_is_different && (
                  <div className="mt-2">
                    <Field
                      name="billing_first_name"
                      id="billing_first_name"
                      component={Input}
                      readOnly={!state.isEditing}
                      customClass={classNames({ '!border-0': !state.isEditing })}
                      placeholder={
                        !!(
                          formikProps.touched.billing_first_name &&
                          formikProps.errors.billing_first_name
                        )
                          ? 'Vorname ist ein Pflichtfeld'
                          : 'Vorname'
                      }
                      error={
                        !!(
                          formikProps.touched.billing_first_name &&
                          formikProps.errors.billing_first_name
                        )
                      }
                    />
                    <Field
                      name="billing_last_name"
                      id="billing_last_name"
                      component={Input}
                      readOnly={!state.isEditing}
                      customClass={classNames({ '!border-0': !state.isEditing })}
                      placeholder={
                        !!(
                          formikProps.touched.billing_last_name &&
                          formikProps.errors.billing_last_name
                        )
                          ? 'Nachname ist ein Pflichtfeld'
                          : 'Nachname'
                      }
                      error={
                        !!(
                          formikProps.touched.billing_last_name &&
                          formikProps.errors.billing_last_name
                        )
                      }
                    />
                    <Field
                      name="billing_street"
                      id="billing_street"
                      component={Input}
                      readOnly={!state.isEditing}
                      customClass={classNames({ '!border-0': !state.isEditing })}
                      placeholder={
                        !!(formikProps.touched.billing_street && formikProps.errors.billing_street)
                          ? 'Straße ist ein Pflichtfeld'
                          : 'Straße'
                      }
                      error={
                        !!(formikProps.touched.billing_street && formikProps.errors.billing_street)
                      }
                    />
                    <Field
                      name="billing_postal_code"
                      id="billing_postal_code"
                      component={Input}
                      readOnly={!state.isEditing}
                      customClass={classNames({ '!border-0': !state.isEditing })}
                      placeholder={
                        !!(formikProps.touched.billing_street && formikProps.errors.billing_street)
                          ? 'PLZ ist ein Pflichtfeld'
                          : 'PLZ'
                      }
                      error={
                        !!(
                          formikProps.touched.billing_postal_code &&
                          formikProps.errors.billing_postal_code
                        )
                      }
                    />
                    <Field
                      name="billing_city"
                      id="billing_city"
                      component={Input}
                      readOnly={!state.isEditing}
                      customClass={classNames({ '!border-0': !state.isEditing })}
                      placeholder={
                        !!(formikProps.touched.billing_city && formikProps.errors.billing_city)
                          ? 'Stadt ist ein Pflichtfeld'
                          : 'Stadt'
                      }
                      error={
                        !!(formikProps.touched.billing_city && formikProps.errors.billing_city)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Insurance */}
            <div className="border-b px-4 pb-4">
              <span className="text-swopa-grey-4 block mb-1 uppercase text-xs">Versicherung</span>
              <>
                {state.isEditing ? (
                  <Dropdown
                    name="insurance_type"
                    id="insurance_type"
                    options={[
                      { value: '', label: 'Versicherungsart' },
                      { value: 'public', label: 'Öffentlich' },
                      { value: 'private', label: 'Privat' },
                    ]}
                    value={formikProps.values.insurance_type}
                    onChange={formikProps.handleChange}
                    customClass={
                      formikProps.values.insurance_type === '' ? '!text-swopa-grey-4' : ''
                    }
                  />
                ) : (
                  <span>{getInsuranceTypeLabel(formikProps.values.insurance_type)}</span>
                )}
              </>
              <Field
                name="insurance_company"
                id="insurance_company"
                component={Input}
                placeholder="Versicherungsunternehmen"
                readOnly={!state.isEditing}
                customClass={classNames({ '!border-0': !state.isEditing })}
              />
              <Field
                name="health_insurance_number"
                id="health_insurance_number"
                component={Input}
                placeholder="Krankenversicherungsnummer"
                readOnly={!state.isEditing}
                customClass={classNames({ '!border-0': !state.isEditing })}
              />
            </div>
          </>
          {state.isEditing && (
            <div className="absolute top-3 right-4 flex items-center">
              {loading ? (
                <Spinner className="mr-2" />
              ) : (
                <>{!finished && <CheckMark className="mr-2" />}</>
              )}
              <button
                type="submit"
                className="bg-swopa-secondary-light-blue hover:bg-[#2B81EC] hover:ease-in-out duration-300 text-white uppercase rounded-sm font-radikal text-xxs py-0.5 px-3"
              >
                Speichern
              </button>
            </div>
          )}
          <CancelEditingFormListener />
        </Form>
      )}
    </Formik>
  );
}

function Input({
  field,
  form,
  customClass,
  ...props
}: UnderlineInputProps & FieldProps & { customClass: string }) {
  return (
    <UnderlineInput
      {...field}
      {...props}
      className="last:mb-0 mb-1 text-sm swopa-primary-dark-blue"
      inputClassName={customClass}
    />
  );
}

function Checkbox(props: ToggleProps & { name: string }) {
  return <Toggle {...props} checked={props.checked} type="checkbox" />;
}

function Dropdown<Options extends { value: string | number; label: string }>(
  props: SelectProps<Options> & { name: string } & { customClass: string },
) {
  return <Select {...props} />;
}
