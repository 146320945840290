import classNames from 'classnames';
import { PaginationInfo } from 'data/paginationHelper';
import { ReactComponent as Next } from 'icons/page_right.svg';
import { ReactComponent as Prev } from 'icons/page_left.svg';
import { take, takeRight, uniq } from 'lodash';
import { Fragment } from 'react';

export default function PaginationFooter({
  paginationInfo,
  onChange,
  className,
}: {
  paginationInfo: PaginationInfo;
  onChange: (page: number) => void;
  className?: string;
}) {
  const btnClass =
    'text-swopa-grey-4 hover:text-swopa-secondary-light-blue-hover active:text-swopa-secondary-light-blue-active';

  let next,
    prev,
    pages = [];

  if (paginationInfo.currentPage !== paginationInfo.endPage) {
    next = (
      <div className="m-2">
        <button
          className={classNames(btnClass)}
          onClick={() => onChange(paginationInfo.currentPage + 1)}
        >
          <Next
            onClick={() => onChange(paginationInfo.currentPage)}
            height="12"
            width="12"
            className="stroke-current"
          />
        </button>
      </div>
    );
  }

  if (paginationInfo.currentPage !== paginationInfo.startPage) {
    prev = (
      <div className="m-2">
        <button
          className={classNames(btnClass)}
          onClick={() => onChange(paginationInfo.currentPage - 1)}
        >
          <Prev
            onClick={() => onChange(paginationInfo.currentPage)}
            height="12"
            width="12"
            className="stroke-current"
          />
        </button>
      </div>
    );
  }

  if (paginationInfo.pages.length > 6) {
    if (paginationInfo.currentPage === paginationInfo.startPage) {
      pages = [...take(paginationInfo.pages, 5), paginationInfo.endPage];
    } else if (paginationInfo.currentPage === paginationInfo.endPage) {
      pages = [paginationInfo.startPage, ...takeRight(paginationInfo.pages, 5)];
    } else {
      pages = uniq([
        paginationInfo.startPage,
        paginationInfo.currentPage - 1,
        paginationInfo.currentPage,
        paginationInfo.currentPage + 1,
        paginationInfo.endPage,
      ]);
    }
  } else {
    pages = paginationInfo.pages;
  }

  return (
    <div className={classNames('flex justify-center h-full items-center select-none', className)}>
      {prev}
      {pages.map((page, i, arr) => {
        if (
          arr.length > 6 &&
          page === paginationInfo.endPage &&
          paginationInfo.currentPage !== paginationInfo.endPage &&
          paginationInfo.currentPage !== paginationInfo.endPage - 1
        ) {
          return (
            <Fragment key={page}>
              <div className="m-2">
                <span className={classNames(btnClass)}>...</span>
              </div>
              <div className="m-2">
                <button
                  className={classNames(btnClass, {
                    'text-swopa-secondary-light-blue': page === paginationInfo.currentPage,
                  })}
                  onClick={() => onChange(page)}
                >
                  {page}
                </button>
              </div>
            </Fragment>
          );
        }

        if (
          arr.length > 6 &&
          page === paginationInfo.startPage &&
          paginationInfo.currentPage !== paginationInfo.startPage &&
          paginationInfo.currentPage !== paginationInfo.startPage + 1
        ) {
          return (
            <Fragment key={page}>
              <div className="m-2">
                <button
                  className={classNames(btnClass, {
                    'text-swopa-secondary-light-blue': page === paginationInfo.currentPage,
                  })}
                  onClick={() => onChange(page)}
                >
                  {page}
                </button>
              </div>
              <div className="m-2">
                <span className={classNames(btnClass, 'select-none')}>...</span>
              </div>
            </Fragment>
          );
        }

        return (
          <div key={page} className="m-2">
            <button
              className={classNames(btnClass, {
                'text-swopa-secondary-light-blue': page === paginationInfo.currentPage,
              })}
              onClick={() => onChange(page)}
            >
              {page}
            </button>
          </div>
        );
      })}
      {next}
    </div>
  );
}
