import { useState, useEffect, useRef } from 'react';
import { Patient, usePatient } from 'data/usePatients';
import http from 'components/Profile/http';
import { isEmpty } from 'lodash';
import { getDiagnosisCertaintyLetter } from 'utils/getDiagnosisCertaintyLetter';
import InfoBox from './InfoBox';
import { ReactComponent as AddDoctorIcon } from 'icons/add-doctor.svg';
import Spinner from 'components/Spinner';
import classnames from 'classnames';
import { Doctor } from 'types/doctor';
import { assignDoctor } from 'data/usePatients';
import SearchableList from './SearchableList';
import DoctorsCircles from './DoctorsCircles';
import DoctorsList from './DoctorsList';
import CertaintySymbol from './CertaintySymbol';
import useUser from 'data/useUser';

type Props = {
  patient: Patient;
  className?: string;
};

export default function PatientHeader({ patient }: Props) {
  const { mutate: mutatePatient } = usePatient(patient.id);
  const { user } = useUser();
  const [isDoctorsPopOpened, setIsDoctorsPopOpened] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [results, setResults] = useState([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  /*
  const handleClickOutside = (e: any) => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (!wrapper.contains(e.target)) {
        setIsDoctorsPopOpened(false);
      }
    }
  };

  useEffect(() => {
    if (isDoctorsPopOpened) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isDoctorsPopOpened]);
  */

  const onDoctorSelect = async (doctor: Doctor) => {
    setIsDoctorsPopOpened(false);
    setUserInput('');
    setAssigning(true);
    const doctors = [...patient.doctors, doctor.id];
    await assignDoctor({
      patient: patient.id,
      doctors: doctors,
    });
    await mutatePatient();
    setAssigning(false);
  };

  const getDoctors = async (value?: string) => {
    const data = await http.get(`medical/doctors/?search=${value || ''}`);
    setResults(
      data.results.length === 0
        ? [{ id: 0, short_name: 'KE', label: 'Kein Ergebnis' }]
        : data.results,
    );
  };

  useEffect(() => {
    getDoctors();
  }, []);

  return (
    <div className="pb-10 border-b-solid border-b-[1px] border-b-swopa-grey-3">
      <div className="flex">
        <div className="grow">
          <h1 className="text-swopa-secondary-light-blue">
            {patient.first_name} {patient.last_name} ({`ID: ${patient.id}`})
          </h1>
        </div>
        <div
          className={classnames('flex-none flex mt-[-3.7rem]', { 'mr-[-0.50rem]': !user.is_admin })}
        >
          <DoctorsCircles assignedDoctors={patient.doctors_list} patient={patient} />
          {user.is_admin && (
            <div className="doctors-selector cursor-pointer relative">
              {assigning && <Spinner className="fill-[#aaaaaa] w-7 h-7" />}
              {!assigning && (
                <AddDoctorIcon onClick={() => setIsDoctorsPopOpened(!isDoctorsPopOpened)} />
              )}
              {isDoctorsPopOpened && (
                <div ref={wrapperRef}>
                  <SearchableList
                    userInput={userInput}
                    setUserInput={setUserInput}
                    placeholder="An Ärzte zuordnen"
                    results={results}
                    renderResults={(results: Array<Doctor>) => (
                      <DoctorsList
                        removeAssigned={true}
                        doctors={results}
                        assignedDoctors={patient.doctors}
                        onClick={onDoctorSelect}
                        allowDelete={false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex">
        <div className="grow">
          {!isEmpty(patient.diagnosis) && (
            <ul className="-m-2 mt-2">
              {patient.diagnosis.map((diagnosis, i) => (
                <li key={i} className="inline-block">
                  <InfoBox className="flex">
                    <CertaintySymbol
                      certainty={getDiagnosisCertaintyLetter(diagnosis.certainty)}
                      className="mr-1"
                    />
                    <span>
                      {diagnosis.diagnose.name} ({diagnosis.diagnose.icd_10_gm})
                    </span>
                  </InfoBox>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
