import { useState, ChangeEvent } from 'react';

import { addPatientDiagnosis } from 'data/usePatients';
import http from 'components/Profile/http';

type Props = {
  patient: any;
  appointmentId: number;
  mutatePatient: () => any;
  setShowEmptyDiagnosis: (bool: boolean) => any;
};

export default function useDiagnosisSearch(props: Props) {
  const { patient, appointmentId, mutatePatient, setShowEmptyDiagnosis } = props;
  const [results, setResults] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(null) as any;
  const [userInput, setUserInput] = useState('');

  const handleSearch = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserInput(value);
    const data = await http.get(`medical/diagnosis/?search=${value}`);
    const results = value === '' ? [] : data.results;
    setResults(data.results.length === 0 ? ['Kein Ergebnis'] : results);
  };

  const handleClearSearch = () => {
    setActiveSuggestion(null);
    setResults([]);
    setUserInput('');
  };

  const handleSelect = async (result: any, certainty: string) => {
    handleClearSearch();
    const hasDiagnosis = !!patient.diagnosis.find(
      (diagnosis: any) => diagnosis.diagnose.id === result.id,
    );
    if (hasDiagnosis) return;
    await addPatientDiagnosis({
      patient,
      diagnosis: result.id,
      appointmentId,
      certainty,
    });
    await mutatePatient();
    setShowEmptyDiagnosis(false);
  };

  const handleRender = (result: any) => {
    if (typeof result === 'string') {
      return <span className="text-sm">{result}</span>;
    }
    return (
      <div className="flex flex-col">
        <span className="font-bold text-sm block mb-1">{result.name}</span>
        <span className="text-sm">{result.icd_10_gm}</span>
      </div>
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setActiveSuggestion(0);
      handleSelect(results[activeSuggestion], '');
    }
    if (e.key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        // we're at the start
        return setActiveSuggestion(results.length - 1);
      }
      setActiveSuggestion(activeSuggestion - 1);
    }
    if (e.key === 'ArrowDown') {
      if (activeSuggestion === null) {
        return setActiveSuggestion(0);
      }
      if (activeSuggestion === results.length - 1) {
        // we're at the end
        return setActiveSuggestion(0);
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  return {
    results,
    handleSearch,
    handleClearSearch,
    handleSelect,
    handleRender,
    handleKeyDown,
    activeSuggestion,
    userInput,
  };
}
