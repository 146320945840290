import { useState } from 'react';
import { format, isSameDay } from 'date-fns';
import classNames from 'classnames';

import MessageForm from './MessageForm';
import { ConsultationMessage } from 'data/useConsultation';
import useUser from 'data/useUser';

type MessageFeedProps = {
  messages: Array<ConsultationMessage>;
  senderDoctor: any;
  refetchData: () => void;
  recieverId: number;
};

const DoctorAvatar = ({ initials }: { initials: string }) => {
  return (
    <span className="bg-swopa-accent-green text-white text-xs uppercase font-radikal flex items-center justify-center text-center rounded-full w-8 h-8 mr-2 flex-shrink-0">
      {initials}
    </span>
  );
};

const Message = ({
  name,
  date,
  message,
  shortName,
  showAvatar,
}: {
  name: any;
  date: string;
  message: string;
  messages: any;
  shortName: string;
  index: number;
  showAvatar: boolean;
}) => {
  return (
    <div className="flex">
      {shortName && showAvatar ? (
        <DoctorAvatar initials={shortName} />
      ) : (
        <span className="w-10 flex-shrink-0"></span>
      )}
      <div className="py-2 px-3 rounded border border-swopa-grey-2 text-xs bg-white w-full">
        {showAvatar && (
          <div className="flex items-center mb-1">
            <span className="font-bold mr-2 text-swopa-primary-dark-blue">{name}</span>
            <span className="text-swopa-grey-4">{date}</span>
          </div>
        )}
        <p className="text-swopa-primary-dark-blue">{message}</p>
      </div>
    </div>
  );
};

export default function MessageFeed(props: MessageFeedProps) {
  const { messages, senderDoctor, refetchData } = props;
  const [showMessageForm, setShowMessageForm] = useState(false);
  const handleShowMessageForm = () => setShowMessageForm(true);
  const isOldestMessage = messages.length - 1;
  const isNewestMessage = messages.at(0);
  const oldestMessageId = messages[isOldestMessage].id;
  const consultingDoctor = messages[isOldestMessage].reciever?.id;
  const { user: { managed_doctor } } = useUser();
  return (
    <ul>
      {messages
        .map((message, index) => {
          const first = messages.length - 1;
          const current = messages[index];
          const next = messages[index + 1] === undefined ? messages[first] : messages[index + 1];
          const sameDay = isSameDay(new Date(current.created_at), new Date(next.created_at));
          const showAvatar =
            !(current?.sender?.id === next?.sender?.id && sameDay) || index === first;

          return (
            <li
              key={index}
              className={classNames('pl-10 first:pl-0 flex flex-col mb-2', { 'mt-4': showAvatar })}
            >
              <Message
                index={index}
                name={message.sender.label}
                date={format(new Date(message.created_at), 'dd.MM.yyyy')}
                message={message.message}
                messages={messages}
                shortName={message.sender.short_name}
                showAvatar={showAvatar}
              />
              {
                managed_doctor &&
                managed_doctor.id &&
                isNewestMessage === message &&
                (
                  <div className="mt-4">
                    {showMessageForm ? (
                      <MessageForm
                        senderId={senderDoctor.id}
                        recieverId={consultingDoctor}
                        appointmentId={message.appointment}
                        patientId={message.patient}
                        label={senderDoctor.label}
                        parent={oldestMessageId}
                        refetchData={refetchData}
                        hideMessageForm={() => setShowMessageForm(false)}
                      />
                    ) : (
                      <button
                        className="text-swopa-secondary-light-blue text-xs inline-flex"
                        onClick={handleShowMessageForm}
                      >
                        Antworten
                      </button>
                    )}
                  </div>
                )}
            </li>
          );
        })
        .reverse()}
    </ul>
  );
}
