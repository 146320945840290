import classNames from 'classnames';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & { error?: string; touched?: boolean; bgWhite?: boolean; errorDots?: boolean };

export default function Input(props: InputProps) {
  const { error, touched, bgWhite, errorDots, ...rest } = props;

  const className = classNames(`${props.className} rounded w-full py-3 px-4 text-link`, {
    'focus:outline-[1px] focus:outline-solid focus:outline-swopa-secondary-light-blue text-swopa-primary-dark':
      !error || !touched,
    'border border-solid border-swopa-warning-red focus:outline-[1px] focus:outline-solid focus:outline-swopa-warning-red text-swopa-warning-red':
      error && touched,
    'bg-swopa-hover-grey': !bgWhite,
    'bg-white': bgWhite,
  });

  return (
    <>
      <input {...rest} className={className} />
      {touched && error && (
        <ul
          className={`text-swopa-warning-red mt-2 space-y-1 ${errorDots ? 'list-disc pl-5' : ''}`}
        >
          {error?.split('\n').map((msg: string) => (
            <li>{msg}</li>
          ))}
        </ul>
      )}
    </>
  );
}
