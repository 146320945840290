import Popper from "./Popper";
import { ReactComponent as PublicIcon } from 'icons/public.svg';

const PublicTooltip = () => {
  const triggerIcon = (
   <PublicIcon />
  );
  return (
    <div className='flex-none mr-2'>
      <Popper
        triggerOnHover
        trigger={triggerIcon}
        content={() => (
          <div className="bg-white p-3 drop-shadow rounded-lg max-w-[275px] text-center">
            <span className="text-swopa-primary-dark-blue block text-xs">Für Patient/-in sichtbar.</span>
          </div>
        )}
      />
    </div>
  )
};

export default PublicTooltip;