import { ReactComponent as Thrash } from 'icons/trash.svg';
import styles from './DeleteIcon.module.css';
import classNames from 'classnames';

export default function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <Thrash
      {...props}
      className={classNames(
        styles.icon,
        'text-swopa-grey-4 hover:text-swopa-warning-red cursor-pointer',
      )}
    />
  );
}
