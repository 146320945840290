import classNames from 'classnames';
import { useRef, useState, ChangeEvent, MouseEvent, FormEvent } from 'react';

import { todaysDate } from 'utils/getTodaysDate';
import { createConsultationRequest } from 'data/useConsultation';
import { updateTherapyState } from 'data/useAppointments';
import Spinner from 'components/Spinner';

type MessageFormProps = {
  senderId: number;
  recieverId: number;
  appointmentId: number;
  patientId: number;
  label: string;
  parent: number | null;
  refetchData: () => void;
  hideMessageForm?: () => void;
};

export default function MessageForm(props: MessageFormProps) {
  const {
    label,
    appointmentId,
    patientId,
    senderId,
    recieverId,
    parent,
    refetchData,
    hideMessageForm,
  } = props;
  const textAreaRef = useRef(null) as any;
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e: ChangeEvent) => {
    const textarea = e.target as HTMLTextAreaElement;
    setMessage(textarea.value);
  };

  const handleClear = (e: MouseEvent | FormEvent) => {
    e.preventDefault();
    setMessage('');
    if (textAreaRef.current) {
      textAreaRef.current.value = '';
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      from_doctor: senderId,
      to_doctor: recieverId,
      appointment: appointmentId,
      patient: patientId,
      message: message,
      parent: parent,
    };
    try {
      await createConsultationRequest(payload);
      // if initial request, we also want to set the therapy state
      if (parent === null) {
        await updateTherapyState({ id: appointmentId, state: 'consultation_requested' });
      }
      handleClear(e);
      await refetchData();
    } catch (error) {
      // TODO: handle errors
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <div className="flex justify-between items-center">
        <span className="text-swopa-primary-dark-blue text-xs mb-2 block">{label}</span>
        <span className="text-swopa-grey-4 text-xs mb-2 block">{todaysDate}</span>
      </div>
      <textarea
        className="border border-swopa-grey-3 rounded p-2 w-full mb-4 text-xs focus:border-swopa-primary-dark-blue outline-none"
        placeholder="Beschreiben Sie Ihre Konsilanfrage…"
        ref={textAreaRef}
        onChange={handleChange}
      ></textarea>
      <div className="flex items-center">
        <button
          disabled={!message || recieverId === 0}
          className={classNames(
            'bg-swopa-secondary-light-blue text-white rounded py-3 px-8 mr-4 font-radikal hover:ease-in-out duration-300 hover:bg-[#2B81EC] text-xs uppercase min-w-[130px] flex justify-center',
            {
              'opacity-50 pointer-events-none': !message || recieverId === 0,
            },
          )}
        >
          {loading ? <Spinner className="fill-white w-4 h-4" /> : 'Speichern'}
        </button>
        <button
          className={'text-swopa-grey-4'}
          onClick={hideMessageForm ? hideMessageForm : handleClear}
        >
          Abbrechen
        </button>
      </div>
    </form>
  );
}
