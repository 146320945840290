import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format, addDays, subDays } from 'date-fns';
import Datepicker from 'react-datepicker';
import classNames from 'classnames';

import { usePaginatedAppointments, Appointment } from 'data/useAppointments';
import { ReactComponent as ArrowLeft } from 'icons/arrow_left.svg';
import { Table, Thead, Tbody, Tr, Td, Th } from 'components/Table';
import Button from 'components/Button';
import { ReactComponent as RightArrow } from 'icons/arrow_right.svg';
import PaginationFooter from './PaginationFooter';
import Spinner from './Spinner';
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';
import DoctorsCircles from './DoctorsCircles';
import StatusSelect from './inputs/StatusSelect';

const THERAPY_STATE = [
  {
    value: '',
    label: 'Therapiestatus',
    color: 'bg-swopa-secondary-light-blue',
  },
  {
    value: 'not_set',
    label: 'Nicht festgelegt',
    color: 'bg-swopa-secondary-light-blue',
  },
  {
    value: 'started_continued',
    label: 'Starten/fortführen',
    color: 'bg-swopa-secondary-light-blue',
  },
  {
    value: 'denied',
    label: 'Ablehnen',
    color: 'bg-swopa-warning-red',
  },
  {
    value: 'paused',
    label: 'Pausieren',
    color: 'bg-swopa-primary-orange',
  },
  {
    value: 'finished',
    label: 'Abgeschlossen',
    color: 'bg-swopa-accent-green',
  },
  {
    value: 'waiting_for_feedback',
    label: 'Warten auf Patientenrückmeldung',
    color: 'bg-swopa-primary-orange',
  },
  {
    value: 'consultation_requested',
    label: 'Konsil ausstehend',
    color: 'bg-swopa-primary-orange',
  },
];

const APPOINTMENT_STATE = [
  {
    value: '',
    label: 'Anwesenheit',
  },
  {
    value: 'created',
    label: 'Erstellt',
  },
  {
    value: 'attended',
    label: 'Erschienen',
  },
  {
    value: 'not_attended',
    label: 'Nicht Erschienen',
  },
  {
    value: 'cancelled',
    label: 'Annulliert',
  },
];

export default function AppointmentSearch() {
  const initialState = {
    selectedDatePickerDate:
      sessionStorage.getItem('selectedDatePickerDate') !== null
        ? sessionStorage.getItem('selectedDatePickerDate') !== 'null'
          ? new Date(JSON.parse(sessionStorage.getItem('selectedDatePickerDate') as any))
          : null
        : (new Date() as any),
    selectedFilterDate:
      sessionStorage.getItem('selectedFilterDate') !== null
        ? sessionStorage.getItem('selectedFilterDate') !== 'null'
          ? format(
              new Date(JSON.parse(sessionStorage.getItem('selectedFilterDate') as any)),
              'yyyy-MM-dd',
            )
          : null
        : format(new Date(), 'yyyy-MM-dd'),
  };
  const [selectedDatePickerDate, setSelectedDatePickerDate] = useState(
    initialState.selectedDatePickerDate,
  );
  const [selectedStatus, setFilterTherapyStatus] = useState('');
  const [selectedAppointmentState, setFilterAppointmentState] = useState('');
  const [selectedFilterDate, setSelectedFilterDate] = useState(initialState.selectedFilterDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get('page') ?? '1');
  const { appointments, paginationInfo, isLoading } = usePaginatedAppointments({
    search: selectedStatus,
    state: selectedAppointmentState,
    page,
    date: selectedFilterDate,
  });

  const setSessionStorage = (selectedDate: Date) => {
    sessionStorage.setItem(
      'selectedDatePickerDate',
      selectedDate !== null ? JSON.stringify(new Date(selectedDate)) : JSON.stringify(null),
    );
    sessionStorage.setItem(
      'selectedFilterDate',
      selectedDate !== null
        ? JSON.stringify(format(new Date(selectedDate), 'yyyy-MM-dd'))
        : JSON.stringify(null),
    );
  };

  const resetPagination = () => setSearchParams('');
  const handleSetFilter = (selectedDatePickerDate: Date) => {
    resetPagination();
    setSelectedFilterDate(format(new Date(selectedDatePickerDate), 'yyyy-MM-dd'));
    setSessionStorage(selectedDatePickerDate);
    if (selectedDatePickerDate === null) {
      setSelectedDatePickerDate(new Date());
    }
    setDatePickerOpen(false);
  };

  const handleClearFilter = () => {
    setSelectedDatePickerDate(null as any);
    setSelectedFilterDate(null as any);
    setSessionStorage(null as any);
    setDatePickerOpen(false);
  };

  const getAppointmentInfo = (appointment: Appointment) => {
    return {
      date: format(new Date(appointment.created_at), 'dd.MM.yyyy'),
      time: format(new Date(appointment.created_at), 'HH:mm').concat(' Uhr'),
    };
  };

  const handlePrevDay = () => {
    if (selectedDatePickerDate) {
      const temp = subDays(selectedDatePickerDate, 1);
      setSelectedDatePickerDate(temp);
      setSelectedFilterDate(format(temp, 'yyyy-MM-dd'));
      setSessionStorage(temp);
    }
  };

  const handleNextDay = () => {
    if (selectedDatePickerDate) {
      const temp = addDays(selectedDatePickerDate, 1);
      setSelectedDatePickerDate(temp);
      setSelectedFilterDate(format(temp, 'yyyy-MM-dd'));
      setSessionStorage(temp);
    }
  };

  const handleStatusChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFilterTherapyStatus(e.target.value);
    resetPagination();
    handleClearFilter();
  };

  const handleAppointmentStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setFilterAppointmentState(e.target.value);
    resetPagination();
    handleClearFilter();
  };

  return (
    <>
      <div className="mt-4 flex">
        <div
          className={classNames(
            'border-[#ececec] border border-r-0 rounded-r-none rounded-[4px] cursor-pointer w-10 bg-white flex items-center justify-center',
            {
              'pointer-events-none': !selectedDatePickerDate,
            },
          )}
          onClick={handlePrevDay}
        >
          <ArrowLeft className="stroke-swopa-grey-4 w-3 h-3" />
        </div>
        <Datepicker
          selected={selectedDatePickerDate}
          onChange={(date: Date) => setSelectedDatePickerDate(date)}
          showPopperArrow={false}
          dateFormat="dd.MM.yyyy"
          placeholderText="Termin"
          shouldCloseOnSelect={false}
          open={datePickerOpen}
          onInputClick={() => setDatePickerOpen(true)}
          onClickOutside={() => setDatePickerOpen(false)}
          todayButton={
            <span
              className="hover:text-swopa-secondary-light-blue-hover"
              onClick={() => handleSetFilter(new Date())}
            >
              Auf heute setzen
            </span>
          }
          calendarStartDay={1}
          className={classNames('text-swopa-grey-4', {
            'font-bold': selectedDatePickerDate,
            'text-swopa-secondary-light-blue': selectedDatePickerDate,
          })}
        >
          <div className="flex flex-col mx-auto mt-2 mb-2 w-full">
            <Button
              onClick={() => handleSetFilter(selectedDatePickerDate)}
              className="w-11/12 mx-auto mb-10 !text-xxs shadow-none py-2"
            >
              Filter setzen
            </Button>
            <span
              className="text-swopa-grey-4 cursor-pointer today-button absolute bottom-4 right-4 hover:text-swopa-warning-red-hover"
              onClick={handleClearFilter}
            >
              Filter Löschen
            </span>
          </div>
        </Datepicker>
        <div
          className={classNames(
            'border-[#ececec] border border-l-0 rounded-l-none rounded-[4px] cursor-pointer w-10 bg-white flex items-center justify-center',
            {
              'pointer-events-none': !selectedDatePickerDate,
            },
          )}
          onClick={handleNextDay}
        >
          <ArrowLeft className="stroke-swopa-grey-4 w-3 h-3 rotate-180" />
        </div>
        <div className="ml-4">
          <StatusSelect
            name="pre_assessment"
            options={THERAPY_STATE}
            handleChange={handleStatusChange}
            activeColor={'bg-transparent'}
            defaultValue={selectedStatus}
            selectedValue={selectedStatus}
            className="border-swopa-border text-swopa-primary-dark-blue"
          />
        </div>
        <div className="ml-4">
          <StatusSelect
            name="state"
            options={APPOINTMENT_STATE}
            handleChange={handleAppointmentStateChange}
            activeColor={'bg-transparent'}
            defaultValue={selectedAppointmentState}
            selectedValue={selectedAppointmentState}
            className="border-swopa-border text-swopa-primary-dark-blue"
          />
        </div>
      </div>
      <Table className="mt-4">
        <Thead>
          <tr>
            <Th>ID</Th>
            <Th className="flex items-center">Name</Th>
            <Th>Termin</Th>
            <Th>Uhrzeit</Th>
            <Th>Termin Arzt</Th>
            <Th>Arzt/Ärztin</Th>
            <Th>Anwesenheit</Th>
            <Th>Therapie</Th>
          </tr>
        </Thead>
        <Tbody>
          {appointments?.map((appointment, i) => (
            <Tr
              key={`${appointment.id}-${i}`}
              onClick={() => {
                navigate(`/patients/${appointment.patient?.id}?open=${appointment.id}`);
              }}
              className={classNames({
                'text-swopa-warning-red': appointment.state === 'cancelled',
              })}
            >
              <Td>{appointment.patient.id}</Td>
              <Td className="font-bold">
                {appointment.patient.first_name} {appointment.patient.last_name}
              </Td>
              <Td>{getAppointmentInfo(appointment).date}</Td>
              <Td>{getAppointmentInfo(appointment).time}</Td>
              <Td>
                {appointment.doctor?.first_name} {appointment.doctor?.last_name}
              </Td>
              <Td>
                <DoctorsCircles
                  moreOnLeft={true}
                  sizeClass="w-6 h-6 leading-[1.4rem] text-[0.6rem] ml-[-12px]"
                  assignedDoctors={appointment.patient.doctors_list}
                  patient={appointment.patient}
                />
              </Td>
              <Td>
                <div className="flex flex-row items-center">
                  <div
                    className={classNames('w-2 h-2  rounded-full mr-2', {
                      'bg-swopa-secondary-light-blue': appointment.state === 'created',
                      'bg-swopa-accent-green': appointment.state === 'attended',
                      'bg-swopa-primary-orange': appointment.state === 'not_attended',
                      'bg-swopa-warning-red': appointment.state === 'cancelled',
                    })}
                  />
                  {appointment.state === 'created' && 'Bitte auswählen'}
                  {appointment.state === 'attended' && 'Erschienen'}
                  {appointment.state === 'not_attended' && 'Nicht erschienen'}
                  {appointment.state === 'cancelled' && 'Annulliert'}
                </div>
              </Td>
              <Td>
                <div className="flex flex-row items-center">
                  <div
                    className={classNames('w-2 h-2  rounded-full mr-2', {
                      'bg-swopa-secondary-light-blue':
                        appointment.therapy_state === 'started_continued',
                      'bg-swopa-warning-red': appointment.therapy_state === 'denied',
                      'bg-swopa-primary-orange':
                        appointment.therapy_state === 'paused' ||
                        appointment.therapy_state === 'waiting_for_feedback' ||
                        appointment.therapy_state === 'consultation_requested',
                      'bg-swopa-accent-green': appointment.therapy_state === 'finished',
                    })}
                  />
                  {appointment.therapy_state === 'waiting_for_feedback' &&
                    'Warten auf Patientenrückmeldung'}
                  {appointment.therapy_state === 'started_continued' && 'Starten/fortführen'}
                  {appointment.therapy_state === 'denied' && 'Ablehnen'}
                  {appointment.therapy_state === 'paused' && 'Pausieren'}
                  {appointment.therapy_state === 'finished' && 'Abgeschlossen'}
                  {appointment.therapy_state === 'consultation_requested' && 'Konsil ausstehend'}
                </div>
              </Td>
              <Td>
                <RightArrow />
              </Td>
            </Tr>
          ))}
        </Tbody>
        {appointments.length > 0 && paginationInfo && paginationInfo.totalPages > 1 && (
          <tfoot>
            <tr>
              <td colSpan={6}>
                <PaginationFooter
                  className="mt-4"
                  paginationInfo={paginationInfo}
                  onChange={(p) => {
                    searchParams.set('page', p.toString());
                    setSearchParams(searchParams, { replace: false });
                  }}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
      {appointments.length === 0 && (
        <div className="mx-auto mt-12 flex justify-center items-center flex-col max-w-xs">
          {isLoading ? (
            <Spinner className="fill-swopa-secondary-light-blue w-5 h-5" />
          ) : (
            <>
              <span className="text-swopa-grey-4 text-base mb-4">Kein Ergebnis</span>
              <Button onClick={handleClearFilter}>Alle Filter zurücksetzen</Button>
            </>
          )}
        </div>
      )}
    </>
  );
}
