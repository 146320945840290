export const getDocumentCategoryName = (content_type: string) => {
  switch (content_type) {
    case 'preliminary_diagnostic':
      return 'Ärztlicher Vorbefund';
    case 'doctor_letter_inpatient':
      return 'Arztbrief (stationär)';
    case 'doctor_letter_outpatient':
      return 'Arztbrief (ambulant)';
    case 'assessment':
      return 'Gutachten';
    case 'bill':
      return 'Rechnung';
    case 'recipe':
      return 'Rezept';
    case 'transfer':
      return 'Überweisung';
    case 'privacy_policy':
      return 'Datenschutzerklärung';
    case 'data_processing_agreement':
      return 'Datenverarbeitungsvereinbarung';
    case 'agb':
      return 'AGB';
    case 'enlightenment':
      return 'Aufklärung';
    case 'private_medical_treatment':
      return 'Information über privatärztliche Behandlung';
    case 'id':
      return 'Personalausweis';
    case 'passport':
      return 'Reisepass';
    case 'health_insurance_card':
      return 'Krankenkassenkarte';
    case 'miscellaneous':
      return 'Sonstiges';
    default:
      return '';
  }
};
