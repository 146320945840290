import PatientSearch from 'components/PatientSearch';
import { Helmet } from 'react-helmet-async';

export default function Patients() {
  return (
    <div>
      <Helmet>
        <title>Enmedify | Ihre Patienten</title>
      </Helmet>
      <h1 className="mt-8 font-radikal text-2xl border-b-solid border-b-[1px] border-b-swopa-grey2">
        Ihre Patienten
      </h1>
      <PatientSearch />
    </div>
  );
}
