import AppointmentSearch from 'components/AppointmentSearch';
import { Helmet } from 'react-helmet-async';

export default function Appointments() {
  return (
    <div className="select-none">
      <Helmet>
        <title>Enmedify | Ihre Termine</title>
      </Helmet>
      <h1 className="mt-8 font-radikal text-2xl border-b-solid border-b-[1px] border-b-swopa-grey2">
        Ihre Termine
      </h1>
      <AppointmentSearch />
    </div>
  );
}
