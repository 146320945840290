import classNames from 'classnames';
import ExpandPanel from 'components/ExpandPanel';
import ProgressInput from 'components/inputs/ProgressInput';
import Select, { SelectProps } from 'components/inputs/Select';
import TextArea, { TextAreaProps } from 'components/inputs/TextArea';
import Toggle, { ToggleProps } from 'components/inputs/Toggle';
import { Anamnesis, useAnamneses, deleteAnamnesis, updateAnamnesis } from 'data/useAnamnesis';
import { format } from 'date-fns';
import { Field, Formik } from 'formik';
import { useFieldAutosave } from 'hooks/useAutoSave';
import { ReactComponent as Info } from 'icons/info.svg';
import { pick, sum } from 'lodash';
import { PropsWithChildren } from 'react';
import DeleteMenu from './DeleteMenu';
import SectionWrapper from './SectionWrapper';
import SectionFooter from './SectionFooter';
import { useAppointmentContext } from 'contexts/Appointment';

export default function AnamnesisSection({
  item,
  expanded,
  appointmentId,
}: {
  item: Anamnesis;
  expanded: boolean;
  appointmentId: number;
}) {
  const { mutate } = useAnamneses(item.patient, appointmentId);
  const { refreshState } = useAppointmentContext();

  const handleRefetchData = async () => await mutate();

  const depressionFields: Array<[string, string, Array<string>]> = [
    [
      'hamilton_depression_scale_disgruntlement',
      'Depressive Verstimmung (Traurigkeit, melancholisch sein, weinerlich sein). Pat. fragen: Fühlten Sie sich in der letzten Woche traurig oder depressiv? Wie oft und wie lange fühlten Sie sich so?',
      [
        'fehlend',
        'wird nur auf Nachfrage berichtet',
        'spontan verbal berichtet',
        'nicht verbale Äußerungen (Gesichtsausdruck, Stimme, Weinerlichkeit)',
        'der Patient berichtet, verbal und nonverbal, dass er sich ausschließlich in diesem Zustand befunden hat.',
      ],
    ],
    [
      'hamilton_depression_scale_feeling_of_guild',
      'Schuldgefühle (Kritik an sich selbst und Selbstvorwürfe). Pat. fragen: Fühlten Sie sich in der vergangenen Woche schuldig wegen etwas, was Sie getan haben, oder dass Sie andere schlecht behandelt haben? Fühlen Sie sich durch ihre Krankheit gestraft?',
      [
        'fehlend',
        'Selbstvorwürfe',
        'Schuldgefühle, Grübeln über Fehler der Vergangenheit',
        'gegenwärtige Krankheit wird als Strafe empfunden, wahnhafte Schuld',
        'der Patient hört eine beschuldigende Stimme oder hat bedrohliche visuelle Halluzinationen',
      ],
    ],
    [
      'hamilton_depression_scale_activity_level',
      'Interesse, Freude, Aktivitätslevel (Arbeit und Aktivitäten des täglichen Lebens). Pat. fragen: Sind Sie bei der Arbeit und zu Hause so produktiv wie früher? Haben Sie weiter Interesse an den Dingen, die Sie normalerweise interessieren?',
      [
        'keine Schwierigkeiten',
        'Erschöpfung, Schwächegefühl, Gedanken nicht zur Arbeit oder den Hobbies in der Lage zu sein',
        'Interesseverlust an Aktivitäten (direkt oder indirekt durch Antriebslosigkeit, Lustlosigkeit, Teilnahmslosigkeit, Unschlüssigkeit oder Entscheidungsunfähigkeit)',
        'verminderte Leistungsfähigkeit oder verminderte Zeit mit Aktivität.',
        'der Patient hat wegen seiner Symptome aufgehört zu arbeiten',
      ],
    ],
    [
      'hamilton_depression_scale_nervousness',
      'Anspannung und Nervosität (psychische Angst). Pat. fragen: Fühlten Sie sich stärker angespannt oder nervös während der letzten Woche? Haben Sie vermehrt gegrübelt?',
      [
        'keine Schwierigkeiten',
        'subjektive Anspannung und Reizbarkeit',
        'Sich Sorgen machen über Kleinigkeiten',
        'offensichtlicher ängstlicher Gesichtsausdruck',
        'Angst wird ohne Nachfragen berichtet',
      ],
    ],
    [
      'hamilton_depression_scale_anxiety',
      'Körperliche Angstsymptome. Pat. fragen, ob er in der letzten Woche etwas von diesen Symptomen hatte und wie sehr ihn das insgesamt belastet hat: Gastrointestinale Symptome: trockener Mund, Blähungen, Durchfall, Bauchkrämpfe; Gefäßsymptome: Herzklopfen, Kopfschmerzen; Atemwege: Hyperventilation, Luftnot; andere: Harndrang, Schweißausbrüche.',
      ['keine', 'leicht', 'mäßig', 'schwer', 'stark behindernd'],
    ],
    [
      'hamilton_depression_scale_energy_level',
      'Energielevel (körperliche Symptome). Pat. fragen: Wie energievoll waren Sie die letzte Woche? Fühlten Sie sich müde? Hatten Sie irgendwelche Schmerzen oder ein Schweregefühl in ihren Gliedern, dem Rücken oder dem Kopf?',
      ['keine', 'leicht', 'mäßig', 'schwer', 'stark behindernd'],
    ],
    [
      'hamilton_depression_scale_suicidal_tendencies',
      'Suizidalität (Gedanken, Pläne, Versuche). Hatten Sie Gedanken, dass das Leben nicht lebenswert ist, und es besser wäre, wenn Sie tot wären? Hatten Sie Gedanken sich selbst zu verletzen oder sich das Leben zu nehmen? Haben sie sich selbst verletzt?',
      [
        'keine',
        'empfindet sein Leben nicht als lebenswert',
        'wünscht sich zu sterben (oder alle Gedanken, dass er lieber sterben würde)',
        'Suizidgedanken',
        'Suizidversuch (für jeden Suizidversuch 4 Punkte)',
      ],
    ],
  ];

  const anxietyFields: Array<[string, string]> = [
    [
      'hamilton_anxiety_scale_behaviour_on_assessment',
      'Verhalten beim Gespräch (Zappeligkeit, Ticks, Schwitzen)',
    ],
    [
      'hamilton_anxiety_scale_cardiologic_symptoms',
      'Kardio-vaskuläre Symptome (Herzklopfen, Tachykardie)',
    ],
    [
      'hamilton_anxiety_scale_depressive_mood',
      'Depressive Stimmung (Intressensverlust, Niedergeschlagenheit)',
    ],
    ['hamilton_anxiety_scale_fear', 'Furcht (vor Dingen/Menschen/Situationen)'],
    [
      'hamilton_anxiety_scale_gastro_intestinal_symptoms',
      'Gastro-intestinale Symptome (Bauchschmerzen, Durchfall, Verstopfungen)',
    ],
    [
      'hamilton_anxiety_scale_intellectual_impairment',
      'Intellektuelle Leistungsbeeinträchtigung (Konzentrationsschwierigkeiten)',
    ],
    [
      'hamilton_anxiety_scale_neuro_vegetitaive',
      'Neurovegetative Symptome (Schwitzen, Schwindel, Kopfschmerz)',
    ],
    [
      'hamilton_anxiety_scale_respiratory_symptoms',
      'Respiratorische Symptome (Druck auf der Brust, Dyspnoe)',
    ],
    ['hamilton_anxiety_scale_scared_mood', 'Ängstliche Stimmung (Sorgen,Reizbarkeit)'],
    ['hamilton_anxiety_scale_sleeplessness', 'Schlaflosigkeit (Schlafstörungen)'],
    [
      'hamilton_anxiety_scale_somatic_symptoms_muscular',
      'Allgemeine somatische Symptome (muskulär: Schmerzen, Tonus)',
    ],
    [
      'hamilton_anxiety_scale_somatic_symptoms_sensoric',
      'Allgemeine somatische Symptome (sensorisch: Tinnitus, Verschwommensehen, Hitzewallungen)',
    ],
    ['hamilton_anxiety_scale_tension', 'Spannung (Unruhe, Erschöpfbarkeit)'],
    [
      'hamilton_anxiety_scale_uro_genital_symptoms',
      'Uro-genitale Symptome (Harndrang, Libidoverlust, unregelmäßige Menstruation)',
    ],
  ];

  const isiFields: Array<[string, string]> = [
    ['isi_problems_falling_asleep', 'Schwierigkeit einzuschlafen'],
    ['isi_problems_sleeping_through', 'Schwierigkeit durchzuschlafen'],
    ['isi_problems_getting_up_in_the_morning', 'Schwierigkeit am Morgen früh aufzuwachen'],
    ['isi_dissatisfaction_with_sleep', 'Unzufriedenheit mit Schlafverhalten'],
    [
      'isi_influence_on_daily_routine',
      'Beeinflussung im Alltag (z.B. Tagesmüdigkeit, Konzentration, Erinnerung, Stimmung)',
    ],
    ['isi_influence_on_life_quality', 'Beeinflussung der Lebensqualität'],
    ['isi_concerns_regarding_sleep', 'Eigene Besorgnis durch Schlafprobleme'],
  ];

  const painFields = [
    ['symptoms_for_chronic_pain_scale', 'Schmerzskala/Beschwerdeskala'],
    ['symptoms_for_chronic_pain_sleep', 'Schlaf'],
    ['symptoms_for_chronic_pain_mood', 'Stimmung'],
    ['symptoms_for_chronic_pain_appetite', 'Appetit'],
    ['symptoms_for_chronic_pain_sexuality', 'Sexualität'],
    ['symptoms_for_chronic_daily_routine', 'Beruf/alltägliche Arbeit'],
    ['symptoms_for_chronic_daily_work', 'Allgemeine Aktivität / Körperliche Belastbarkeit'],
    ['symptoms_for_chronic_pain_concentraction', 'Geistige Tätigkeiten / Konzentration'],
  ];

  const doctorFirstName = item?.doctor?.first_name || '';
  const doctorLastName = item?.doctor?.last_name || '';

  return (
    <SectionWrapper
      handleRefetchData={handleRefetchData}
      expanded={expanded}
      headline={`Anamnese`}
      content={
        <>
          <div>
            <Formik
              onSubmit={() => {}}
              initialValues={pick(item, [
                'id',
                'patient',
                'created_at',
                'general_anamnesis',
                'adverse_drug_reaction',
                'symptoms',
                'earlier_self_therapy_with_cannabis',
                'earlier_therapies',
                'psychiatric_exploration',
                'inability_to_work',
              ])}
              enableReinitialize
            >
              {({ values, handleChange }) => (
                <>
                  <AutoSaveTextArea
                    label="Generelle Anamnese"
                    name="general_anamnesis"
                    id="general_anamnesis"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.general_anamnesis}
                    onChange={handleChange}
                  />
                  <AutoSaveTextArea
                    label="Unerwünschte Arzneimittelwirkungen, insb. psychiatrisch durch Cannabinoid-Verschreibung"
                    name="adverse_drug_reaction"
                    id="adverse_drug_reaction"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.adverse_drug_reaction}
                    onChange={handleChange}
                  />
                  <AutoSaveTextArea
                    label="Symptome"
                    name="symptoms"
                    id="symptoms"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.symptoms}
                    onChange={handleChange}
                  />
                  <AutoSaveTextArea
                    label="Bisher getestete Therapieversuche (medikamentös und konservativ) mit ggf. UAW"
                    name="earlier_therapies"
                    id="earlier_therapies"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.earlier_therapies}
                    onChange={handleChange}
                  />
                  <AutoSaveTextArea
                    label="Psychiatrische Exploration"
                    name="psychiatric_exploration"
                    id="psychiatric_exploration"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.psychiatric_exploration}
                    onChange={handleChange}
                  />
                  <AutoSaveSelect
                    label="Arbeitsunfähigkeit (AU) auf Grund einer der Diagnosen innerhalb des letzten halben Jahres"
                    name="inability_to_work"
                    className="mb-4"
                    options={[
                      { value: 'no', label: 'Nein' },
                      { value: 'less_than_1_week', label: 'Weniger als 1 Woche' },
                      { value: '2_4_weeks', label: '2 bis 4 Wochen' },
                      { value: 'more_than_4_weeks', label: 'Mehr als 4 Wochen' },
                    ]}
                    value={values.inability_to_work}
                    onChange={handleChange}
                  />
                  <AutoSaveTextArea
                    label="Cannabis-Eigentherapieversuch (inkl Symptonlinderung, UAW oder psych. Beeinträchtigung)"
                    name="earlier_self_therapy_with_cannabis"
                    id="earlier_self_therapy_with_cannabis"
                    placeholder="Hier eintippen ..."
                    className="mb-4"
                    value={values.earlier_self_therapy_with_cannabis}
                    onChange={handleChange}
                  />
                </>
              )}
            </Formik>
          </div>

          <ExpandPanel
            className="mb-4"
            headline="Insomnia Severity Index (ISI)"
            handleRefetchData={handleRefetchData}
            content={
              <>
                <Formik
                  initialValues={pick(item, [
                    'id',
                    'patient',
                    'created_at',
                    'isi_concerns_regarding_sleep',
                    'isi_dissatisfaction_with_sleep',
                    'isi_influence_on_daily_routine',
                    'isi_influence_on_life_quality',
                    'isi_problems_falling_asleep',
                    'isi_problems_getting_up_in_the_morning',
                    'isi_problems_sleeping_through',
                  ])}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ values, handleChange }: any) => (
                    <>
                      {isiFields.map(([name, label], i) => (
                        <Field
                          component={AutoSaveProgressInput}
                          key={i}
                          id={name}
                          name={name}
                          label={label}
                          value={values[name]}
                          onChange={handleChange}
                          min="0"
                          max="4"
                        />
                      ))}
                    </>
                  )}
                </Formik>
              </>
            }
            footer={
              <div className="text-swopa-secondary-light-blue flex">
                <Info />
                <span className="ml-2">0=Keine; 1=Leicht; 2=Mäßig; 3=Stark; 4=Sehr stark</span>
              </div>
            }
          />

          <ExpandPanel
            className="mb-4"
            headline="Symptomatik für chronische Schmerzen"
            handleRefetchData={handleRefetchData}
            content={
              <>
                <Formik
                  initialValues={pick(item, [
                    'id',
                    'patient',
                    'created_at',
                    'symptoms_for_chronic_pain_scale',
                    'symptoms_for_chronic_pain_sleep',
                    'symptoms_for_chronic_pain_mood',
                    'symptoms_for_chronic_pain_appetite',
                    'symptoms_for_chronic_pain_sexuality',
                    'symptoms_for_chronic_daily_routine',
                    'symptoms_for_chronic_daily_work',
                    'symptoms_for_chronic_pain_concentraction',
                  ])}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ values, handleChange }: any) => (
                    <>
                      {painFields.map(([name, label], i) => (
                        <Field
                          component={AutoSaveProgressInput}
                          key={i}
                          id={name}
                          name={name}
                          label={label}
                          value={values[name]}
                          onChange={handleChange}
                          min="0"
                          max="10"
                        />
                      ))}
                    </>
                  )}
                </Formik>
              </>
            }
            footer={
              <div className="text-swopa-secondary-light-blue flex">
                <Info />
                <span className="ml-2">0=Keine; 10=Stärkste</span>
              </div>
            }
          />

          <ExpandPanel
            className="mb-4"
            headline="Hamilton Depressionsskala (7-Fragen Kurzform)"
            handleRefetchData={handleRefetchData}
            content={
              <>
                <Formik
                  initialValues={pick(item, [
                    'id',
                    'patient',
                    'created_at',
                    'hamilton_depression_scale_activity_level',
                    'hamilton_depression_scale_anxiety',
                    'hamilton_depression_scale_disgruntlement',
                    'hamilton_depression_scale_energy_level',
                    'hamilton_depression_scale_feeling_of_guild',
                    'hamilton_depression_scale_nervousness',
                    'hamilton_depression_scale_suicidal_tendencies',
                  ])}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ values, handleChange }: any) => (
                    <>
                      {depressionFields.map(([name, label, severity], i) => (
                        <div key={i} className="mb-4">
                          <Field
                            component={AutoSaveProgressInput}
                            id={name}
                            name={name}
                            label={label}
                            value={values[name]}
                            onChange={handleChange}
                            min="0"
                            max="4"
                          />
                          <InfoBox>{severity[values[name]]}</InfoBox>
                        </div>
                      ))}
                      <TotalScore
                        score={sum(
                          Object.values(
                            pick(
                              values,
                              'hamilton_depression_scale_activity_level',
                              'hamilton_depression_scale_anxiety',
                              'hamilton_depression_scale_disgruntlement',
                              'hamilton_depression_scale_energy_level',
                              'hamilton_depression_scale_feeling_of_guild',
                              'hamilton_depression_scale_nervousness',
                              'hamilton_depression_scale_suicidal_tendencies',
                            ),
                          ),
                        )}
                      />
                    </>
                  )}
                </Formik>
              </>
            }
            footer={
              <div className="text-swopa-secondary-light-blue flex">
                <Info />
                <span className="ml-2">Individuelle Bewertung</span>
              </div>
            }
          />

          <ExpandPanel
            headline="Hamiltonskala für Angst"
            handleRefetchData={handleRefetchData}
            content={
              <>
                <Formik
                  initialValues={pick(item, [
                    'id',
                    'patient',
                    'created_at',
                    'hamilton_anxiety_scale_behaviour_on_assessment',
                    'hamilton_anxiety_scale_cardiologic_symptoms',
                    'hamilton_anxiety_scale_depressive_mood',
                    'hamilton_anxiety_scale_fear',
                    'hamilton_anxiety_scale_gastro_intestinal_symptoms',
                    'hamilton_anxiety_scale_intellectual_impairment',
                    'hamilton_anxiety_scale_neuro_vegetitaive',
                    'hamilton_anxiety_scale_respiratory_symptoms',
                    'hamilton_anxiety_scale_scared_mood',
                    'hamilton_anxiety_scale_sleeplessness',
                    'hamilton_anxiety_scale_somatic_symptoms_muscular',
                    'hamilton_anxiety_scale_somatic_symptoms_sensoric',
                    'hamilton_anxiety_scale_tension',
                    'hamilton_anxiety_scale_uro_genital_symptoms',
                  ])}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ values, handleChange }: any) => (
                    <>
                      {anxietyFields.map(([name, label], i) => (
                        <Field
                          component={AutoSaveProgressInput}
                          key={i}
                          id={name}
                          name={name}
                          label={label}
                          value={values[name]}
                          onChange={handleChange}
                          min="0"
                          max="4"
                        />
                      ))}
                      <TotalScore
                        score={sum(
                          Object.values(
                            pick(
                              values,
                              'hamilton_anxiety_scale_behaviour_on_assessment',
                              'hamilton_anxiety_scale_cardiologic_symptoms',
                              'hamilton_anxiety_scale_depressive_mood',
                              'hamilton_anxiety_scale_fear',
                              'hamilton_anxiety_scale_gastro_intestinal_symptoms',
                              'hamilton_anxiety_scale_intellectual_impairment',
                              'hamilton_anxiety_scale_neuro_vegetitaive',
                              'hamilton_anxiety_scale_respiratory_symptoms',
                              'hamilton_anxiety_scale_scared_mood',
                              'hamilton_anxiety_scale_sleeplessness',
                              'hamilton_anxiety_scale_somatic_symptoms_muscular',
                              'hamilton_anxiety_scale_somatic_symptoms_sensoric',
                              'hamilton_anxiety_scale_tension',
                              'hamilton_anxiety_scale_uro_genital_symptoms',
                            ),
                          ),
                        )}
                      />
                    </>
                  )}
                </Formik>
              </>
            }
            footer={
              <div className="text-swopa-secondary-light-blue flex">
                <Info />
                <span className="ml-2">0=Keine; 4=Stärkste</span>
              </div>
            }
          />
        </>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.created_at), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    await deleteAnamnesis(item.id);
                    await mutate();
                    refreshState();
                  }}
                />
              </div>
            </div>
          }
          right={
            <span className="text-xs">
              {doctorFirstName} {doctorLastName}
            </span>
          }
        />
      }
    />
  );
}

function AutoSaveProgressInput({ field, form, ...props }: any) {
  const { loading, finished } = useFieldAutosave({ update: updateAnamnesis, name: field.name });

  return (
    <ProgressInput {...props} finished={finished} step="1" loading={loading} name={field.name} />
  );
}

function InfoBox({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <span
      className={classNames(
        'p-2 rounded-md text-swopa-secondary-light-blue bg-opacity-10 bg-swopa-secondary-light-blue inline-block',
        className,
      )}
    >
      {children}
    </span>
  );
}

function AutoSaveTextArea(props: TextAreaProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updateAnamnesis, name: props.name });

  return <TextArea finished={finished} {...props} loading={loading} />;
}

function AutoSaveCheckbox(props: ToggleProps & { name: string }) {
  const { loading } = useFieldAutosave({ update: updateAnamnesis, name: props.name });
  return <Toggle {...props} checked={props.checked} type="checkbox" disabled={loading} />;
}

function AutoSaveSelect<Options extends { value: string | number; label: string }>(
  props: SelectProps<Options> & { name: string },
) {
  const { loading, finished } = useFieldAutosave({ update: updateAnamnesis, name: props.name });
  return <Select finished={finished} {...props} loading={loading} />;
}

function TotalScore({ score }: { score: number }) {
  return (
    <div>
      Gesamtscore: <InfoBox className="text-lg">{score}</InfoBox>
    </div>
  );
}
