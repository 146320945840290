import { useState } from 'react';
import useSWR from 'swr';
import { post, httpDelete, patch } from './http';
import { useInfiniteScroll } from './useInfiniteScroll';
import { negate, isNil } from 'lodash';
import { pickBy } from 'lodash/fp';
import { Doctor } from 'types/doctor';

type AnamnesisData = {
  results: Array<Anamnesis>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Anamnesis = {
  id: number;
  patient: number;

  adverse_drug_reaction: string;
  earlier_self_therapy_with_cannabis: string;
  earlier_therapies: string;
  general_anamnesis: string;
  inability_to_work: string;
  psychiatric_exploration: string;
  symptoms: string;

  checked_for_psychological_diseases: boolean;
  signed_disclosure_form: boolean;

  hamilton_anxiety_scale_behaviour_on_assessment: number;
  hamilton_anxiety_scale_cardiologic_symptoms: number;
  hamilton_anxiety_scale_depressive_mood: number;
  hamilton_anxiety_scale_fear: number;
  hamilton_anxiety_scale_gastro_intestinal_symptoms: number;
  hamilton_anxiety_scale_intellectual_impairment: number;
  hamilton_anxiety_scale_neuro_vegetitaive: number;
  hamilton_anxiety_scale_respiratory_symptoms: number;
  hamilton_anxiety_scale_scared_mood: number;
  hamilton_anxiety_scale_sleeplessness: number;
  hamilton_anxiety_scale_somatic_symptoms_muscular: number;
  hamilton_anxiety_scale_somatic_symptoms_sensoric: number;
  hamilton_anxiety_scale_tension: number;
  hamilton_anxiety_scale_uro_genital_symptoms: number;

  hamilton_depression_scale_activity_level: number;
  hamilton_depression_scale_anxiety: number;
  hamilton_depression_scale_disgruntlement: number;
  hamilton_depression_scale_energy_level: number;
  hamilton_depression_scale_feeling_of_guild: number;
  hamilton_depression_scale_nervousness: number;
  hamilton_depression_scale_suicidal_tendencies: number;

  isi_concerns_regarding_sleep: number;
  isi_dissatisfaction_with_sleep: number;
  isi_influence_on_daily_routine: number;
  isi_influence_on_life_quality: number;
  isi_problems_falling_asleep: number;
  isi_problems_getting_up_in_the_morning: number;
  isi_problems_sleeping_through: number;

  symptoms_for_chronic_daily_routine: number;
  symptoms_for_chronic_daily_work: number;
  symptoms_for_chronic_pain_appetite: number;
  symptoms_for_chronic_pain_concentraction: number;
  symptoms_for_chronic_pain_mood: number;
  symptoms_for_chronic_pain_scale: number;
  symptoms_for_chronic_pain_sexuality: number;
  symptoms_for_chronic_pain_sleep: number;

  created_at: string;
  doctor: Doctor;
};

export function useAnamneses(patientId: number, appointmentId: number) {
  const [search, setSearch] = useState('');

  const paginationData = useInfiniteScroll<AnamnesisData>({
    url: `medical/anamnesis/?search=${search}&patient_id=${patientId}&appointment_id=${appointmentId}`,
    pageSize: 99,
  });

  let data = {
    ...paginationData,
    results: paginationData.results.map(initializeAnamnesis),
  };

  return { search, setSearch, ...data };
}

export function useAnamnesis(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Anamnesis>(`medical/anamnesis/${id}/`);

  let anamnesis;
  if (data) {
    anamnesis = initializeAnamnesis(data);
  }

  return { data: anamnesis, error, mutate };
}

export function createAnamnesis({
  id,
  appointmentId,
  created_at = new Date(),
}: {
  id: number;
  appointmentId: number;
  created_at?: Date;
}) {
  return post('medical/anamnesis/', {
    patient: id,
    appointment: appointmentId,
    created_at,
    isi_concerns_regarding_sleep: 0,
    isi_dissatisfaction_with_sleep: 0,
    isi_influence_on_daily_routine: 0,
    isi_influence_on_life_quality: 0,
    isi_problems_falling_asleep: 0,
    isi_problems_getting_up_in_the_morning: 0,
    isi_problems_sleeping_through: 0,
  });
}

export function updateAnamnesis(anamnesis: { id: number } & Partial<Anamnesis>) {
  return patch(`medical/anamnesis/${anamnesis.id}/`, anamnesis);
}

export function deleteAnamnesis(id: number) {
  return httpDelete(`medical/anamnesis/${id}/`);
}

function initializeAnamnesis(anamnesis: Anamnesis) {
  return {
    isi_concerns_regarding_sleep: 0,
    isi_dissatisfaction_with_sleep: 0,
    isi_influence_on_daily_routine: 0,
    isi_influence_on_life_quality: 0,
    isi_problems_falling_asleep: 0,
    isi_problems_getting_up_in_the_morning: 0,
    isi_problems_sleeping_through: 0,
    symptoms_for_chronic_daily_routine: 0,
    symptoms_for_chronic_daily_work: 0,
    symptoms_for_chronic_pain_appetite: 0,
    symptoms_for_chronic_pain_concentraction: 0,
    symptoms_for_chronic_pain_mood: 0,
    symptoms_for_chronic_pain_scale: 0,
    symptoms_for_chronic_pain_sexuality: 0,
    symptoms_for_chronic_pain_sleep: 0,
    hamilton_depression_scale_activity_level: 0,
    hamilton_depression_scale_anxiety: 0,
    hamilton_depression_scale_disgruntlement: 0,
    hamilton_depression_scale_energy_level: 0,
    hamilton_depression_scale_feeling_of_guild: 0,
    hamilton_depression_scale_nervousness: 0,
    hamilton_depression_scale_suicidal_tendencies: 0,
    hamilton_anxiety_scale_behaviour_on_assessment: 0,
    hamilton_anxiety_scale_cardiologic_symptoms: 0,
    hamilton_anxiety_scale_depressive_mood: 0,
    hamilton_anxiety_scale_fear: 0,
    hamilton_anxiety_scale_gastro_intestinal_symptoms: 0,
    hamilton_anxiety_scale_intellectual_impairment: 0,
    hamilton_anxiety_scale_neuro_vegetitaive: 0,
    hamilton_anxiety_scale_respiratory_symptoms: 0,
    hamilton_anxiety_scale_scared_mood: 0,
    hamilton_anxiety_scale_sleeplessness: 0,
    hamilton_anxiety_scale_somatic_symptoms_muscular: 0,
    hamilton_anxiety_scale_somatic_symptoms_sensoric: 0,
    hamilton_anxiety_scale_tension: 0,
    hamilton_anxiety_scale_uro_genital_symptoms: 0,
    ...omitNullish(anamnesis),
  };
}

const omitNullish: any = pickBy(negate(isNil));
