import { Field } from 'formik';
import { useState } from 'react';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye_open.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/eye_closed.svg';

interface PasswordInputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [showValue, setShowValue] = useState(false);

  return (
    <div className={`w-full ${props.className ? props.className : ''}`}>
      <div className="relative">
        <div className="absolute right-0 top-2/4 -translate-y-2/4 mr-5 cursor-pointer">
          {showValue ? (
            <EyeOpenIcon
              className={'text-swopa-primary-dark-blue'}
              onClick={() => setShowValue(false)}
            />
          ) : (
            <EyeClosedIcon
              className={'text-swopa-secondary-grey'}
              onClick={() => setShowValue(true)}
            />
          )}
        </div>
        <Field
          id={props.id}
          name={props.name}
          type={showValue ? 'text' : 'password'}
          placeholder={props.placeholder}
          className={`appearance-none border ${
            props.error
              ? 'border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red'
              : 'border-swopa-border text-swopa-secondary-light-blue placeholder:text-swopa-secondary-grey'
          } rounded w-full py-3 px-4 bg-white  text-link focus:outline-swopa-secondary-light-blue focus:text-swopa-secondary-light-blue focus:placeholder:text-swopa-secondary-light-blue pr-12`}
        />
      </div>
      {props.error &&
        props.errorMessage &&
        props.errorMessage
          .split('\n')
          .map((msg: string) => (
            <span className="text-swopa-warning-red text-field-validation mt-2 block">{msg}</span>
          ))}
    </div>
  );
}
