import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { FileWithPath } from 'react-dropzone';

import Document from 'components/Documents/Document';
import { readFileAsync } from 'utils/files';
import DocumentUpload from './DocumentUpload';
import { useDocuments, Document as DocumentType } from 'data/useDocuments';
import http from 'components/Profile/http';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { useSidebarContext } from 'contexts/Sidebar';
import DocumentUploadScreen from './DocumentUploadScreen';
import DocumentDropZone from './DocumentDropZone';
import { GlobalErrorContext } from 'contexts/GlobalError';
import UploadFileButton from './UploadFileButton';
import useShowUploadOverlayState from 'hooks/useShowUploadOverlayState';

function CloseButton() {
  const { dispatch } = useSidebarContext();
  const handleClose = () =>
    dispatch({ type: 'SHOW_MY_DOCUMENTS_SCREEN', payload: { show: false, uploadableFiles: [] } });
  return (
    <button onClick={handleClose}>
      <CloseIcon className="stroke-swopa-grey-4 w-5" />
    </button>
  );
}

export default function MyDocuments({ patientId }: { patientId: number }) {
  const [uploadableFiles, setUploadableFiles] = useState<
    Array<{ filename: string; asset: string }> | undefined
  >(undefined);
  const [sort] = useState('date');
  const { data: documents, mutate: refetchDocuments } = useDocuments(patientId);
  const showUpload = useShowUploadOverlayState(uploadableFiles);
  const { setError } = useContext(GlobalErrorContext);
  const { state } = useSidebarContext();

  const handleDocumentUpload = useCallback(
    async (files: Array<FileWithPath>) => {
      const TYPES = ['image/jpeg', 'application/pdf', 'image/png'];
      for (let i = 0; i < files.length; ++i) {
        const filesize = (files[i].size / 1024 / 1024).toFixed(4);
        if (Number(filesize) > 20) {
          setError('Ihre Datei ist leider größer als 20MB. Bitte versuchen Sie es erneut');
          return;
        }
        if (!TYPES.find((type) => type === files[i].type)) {
          setError('Das Dateiformat wird leider nicht unterstützt.');
          return;
        }
      }
      const assets = await Promise.all(files.map((file) => readFileAsync(file)));
      setUploadableFiles(
        files.map(({ name }, index) => ({
          filename: name,
          asset: assets[index] + '',
        })),
      );
    },
    [setUploadableFiles, setError],
  );

  const handleDeleteDocument = useCallback(
    async (id: number) => {
      await http.delete(`/medical/documents/${id}/?patient=${patientId}`, {});
      await refetchDocuments();
    },
    [refetchDocuments],
  );

  const documentData = useMemo(() => {
    if (!documents) return [];
    const res = documents.map(({
      name,
      asset,
      uploaded_at,
      id,
      content_type,
      uploaded_by_fullname,
      uploaded_by_shortname,
      uploaded_by_picture,
    }: DocumentType) => {
      const filetype = asset.substring(asset.lastIndexOf('.') + 1, asset.indexOf('?'));
      return {
        filename: name,
        filetype,
        uploadDate: new Date(uploaded_at),
        downloadLink: asset,
        deleteCallback: () => handleDeleteDocument(id),
        patientId: patientId,
        documentId: id,
        category: content_type,
        refetchDocuments,
        uploaded_by_fullname,
        uploaded_by_shortname,
        uploaded_by_picture,
      };
    });
    if (sort === 'date') {
      return res.sort(
        ({ uploadDate: a }: any, { uploadDate: b }: any) => b.getTime() - a.getTime(),
      );
    } else {
      return res.sort(({ filetype: a }: any, { filetype: b }: any) => b.localeCompare(a));
    }
  }, [documents, sort, handleDeleteDocument]);

  useEffect(() => {
    if (state.uploadableFiles.length > 0) {
      handleDocumentUpload(state.uploadableFiles);
    }
  }, [state.uploadableFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-swopa-grey-1 rounded max-w-5xl w-full">
      <div className="flex justify-between items-center px-6 py-3">
        <span className="text-swopa-secondary-light-blue font-radikal text-3xl">Dokumente</span>
        <div className="flex space-x-8 items-center ">
          <UploadFileButton onOpen={() => setUploadableFiles([])} onUpload={handleDocumentUpload} />
          <CloseButton />
        </div>
      </div>
      <hr className="bg-swopa-border" />
      <div className="grid space-y-2 p-6 max-h-[418px] overflow-y-scroll">
        {uploadableFiles && (
          <>
            {uploadableFiles.map((val) => (
              <DocumentUpload
                {...val}
                patientId={patientId}
                key={val.filename}
                onCancel={() =>
                  setUploadableFiles((prev) =>
                    prev ? prev.filter((x) => x.filename !== val.filename) : prev,
                  )
                }
                onComplete={() =>
                  refetchDocuments().then(() =>
                    setUploadableFiles((prev) =>
                      prev ? prev.filter((x) => x.filename !== val.filename) : prev,
                    ),
                  )
                }
              />
            ))}
          </>
        )}
        {!!!documentData.length && (
          <span className="text-swopa-grey-4 text-center">Keine dokumente</span>
        )}
        {documentData.map((vals: any, index: number) => (
          <Document {...vals} key={index} />
        ))}
      </div>
      <hr className="bg-swopa-border" />
      <span className="text-xs text-swopa-secondary-grey py-2 px-6 block">
        Zulässige Dateiformate: PDF, PNG, JPEG | Maximal 20MB pro Datei
      </span>
      {showUpload && (
        <DocumentUploadScreen content={<DocumentDropZone onDrop={handleDocumentUpload} />} />
      )}
    </div>
  );
}
