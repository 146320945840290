import { SWRConfig } from 'swr';
import { baseFetcher as fetcher } from 'data/baseFetcher';
import { PropsWithChildren, useCallback, useState } from 'react';
import { GlobalErrorContext } from 'contexts/GlobalError';

export default function SWRProvider({ children }: PropsWithChildren<{}>) {
  const [error, setError] = useState<null | Error | string>(null);
  const [success, setSuccess] = useState<null | string>(null);

  const clearSuccess = useCallback(() => {
    setSuccess(null);
  }, [setSuccess]);

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (error, key) => {
          if (error.status !== 401 && error.status !== 403 && error.status !== 404) {
            setError(error);
          }
        },
      }}
    >
      <GlobalErrorContext.Provider
        value={{ error, success, setError, clearError, setSuccess, clearSuccess }}
      >
        {children}
      </GlobalErrorContext.Provider>
    </SWRConfig>
  );
}
