import SectionWrapper from './SectionWrapper';
import React, { useState, useEffect } from 'react';
import { ReactComponent as BracketIcon } from 'icons/bracket.svg';
import { Preassessment } from 'data/usePatients';

type ConditionalLinkWrapperProps = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => any;
  children: React.ReactNode;
};

type QuestionnaireItemProps = {
  question: string;
  answer: string;
  isLink: boolean;
};

export default function PreassessmentSection({
  item: { questionnaire },
  expanded,
}: {
  item: Preassessment;
  expanded: boolean;
}) {
  const [data, setData] = useState<QuestionnaireItemProps[]>([]);

  useEffect(() => {
    const array: QuestionnaireItemProps[] = [];
    const excludedKeys = ['patient-id', 'rnd'];
    Object.entries(questionnaire).forEach(([key, value]: any) => {
      if (
        typeof value !== 'string' ||
        excludedKeys.includes(key) ||
        key.toLowerCase().includes('uploads')
      )
        return;
      array.push({
        question: key,
        answer: value,
        isLink: value.includes('http'),
      });
    });
    setData(array);
  }, [questionnaire]);

  const ConditionalLinkWrapper = ({ condition, wrapper, children }: ConditionalLinkWrapperProps) =>
    condition ? wrapper(children) : children;

  return (
    <SectionWrapper
      expanded={expanded}
      headline={'Patientenfragebogen'}
      content={
        <>
          <ul>
            {data?.map((item: QuestionnaireItemProps) => (
              <li className="flex flex-col mb-3 break-words" key={item.question}>
                <span className="text-swopa-primary-dark-blue mb-1">{item.question}</span>
                <span className="relative text-swopa-secondary-light-blue font-bold pl-7">
                  <BracketIcon className="absolute left-3 top-1" />
                  <ConditionalLinkWrapper
                    condition={item.isLink}
                    wrapper={(children) => (
                      <a
                        href={item.answer}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:text-swopa-secondary-light-blue-hover"
                      >
                        {children}
                      </a>
                    )}
                  >
                    {item.answer}
                  </ConditionalLinkWrapper>
                </span>
              </li>
            ))}
          </ul>
        </>
      }
    />
  );
}
