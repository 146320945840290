import { Form, Formik } from 'formik';
import { format } from 'date-fns';
import { uniqueId } from 'lodash';

import SectionWrapper from './SectionWrapper';
import { Note, deleteNote, updateNote, useNotes } from 'data/useNotes';
import TextArea, { TextAreaProps } from 'components/inputs/TextArea';
import { useFieldAutosave } from 'hooks/useAutoSave';
import SectionFooter from './SectionFooter';
import DeleteMenu from './DeleteMenu';
import { useAppointmentContext } from 'contexts/Appointment';

export default function NoteSection({
  item,
  expanded,
  appointmentId,
}: {
  item: Note;
  expanded: boolean;
  appointmentId: number;
}) {
  const { mutate } = useNotes(item.patient, appointmentId);
  const { refreshState } = useAppointmentContext();

  const handleRefetchData = async () => await mutate();

  const doctorFirstName = item?.doctor?.first_name || '';
  const doctorLastName = item?.doctor?.last_name || '';
  const id = uniqueId();

  return (
    <SectionWrapper
      expanded={expanded}
      handleRefetchData={handleRefetchData}
      headline={'Notiz'}
      content={
        <Formik initialValues={item} onSubmit={() => {}} enableReinitialize>
          {({ values, handleChange }) => (
            <Form>
              <AutoSaveTextArea
                label="Inhalt"
                name="content"
                id={id}
                placeholder="Neue Notiz..."
                className="mb-4"
                value={values.content}
                onChange={handleChange}
              />
            </Form>
          )}
        </Formik>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.created_at), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    await deleteNote(item.id);
                    await mutate();
                    refreshState();
                  }}
                />
              </div>
            </div>
          }
          right={
            <span className="text-xs">
              {doctorFirstName} {doctorLastName}
            </span>
          }
        />
      }
    />
  );
}

function AutoSaveTextArea(props: TextAreaProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updateNote, name: props.name });
  return <TextArea {...props} loading={loading} finished={finished} />;
}
