import useSWR from 'swr';
import { post, patch, httpDelete } from './http';
import { Doctor } from 'types/doctor';
import { useInfiniteScroll } from './useInfiniteScroll';

type IndicationData = {
  results: Array<Indication>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Indication = {
  id: number;
  doctor: Doctor;
  patient: number;
  content: string;
  created_at: string;
  indication_for_cannabis_therapy: boolean;
  indication_for_cbd_therapy: boolean;
  request_conciliatory_assessment: boolean;
  for_medial_triage: string;
};

export function useIndications(patientId: number, appointmentId: number) {
  const paginationData = useInfiniteScroll<IndicationData>({
    url: `medical/indications/?patient_id=${patientId}&appointment_id=${appointmentId}`,
    pageSize: 99,
  });

  return paginationData;
}

export function useIndication(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Indication>(`medical/indications/${id}/`);

  return { data, error, mutate };
}

export function createIndication({
  patient,
  appointmentId,
  content = '...',
  created_at = new Date(),
}: {
  patient: number;
  appointmentId: number;
  content?: string;
  created_at?: Date;
}) {
  return post('medical/indications/', { patient, appointment: appointmentId, content, created_at });
}

export function updateIndication(indication: { id: number } & Partial<Indication>) {
  return patch(`medical/indications/${indication.id}/`, indication);
}

export const deleteIndication = (id: number) => {
  return httpDelete(`medical/indications/${id}/`);
};
