import { useState, useRef } from 'react';
import { FileWithPath } from 'react-dropzone';
import { fromEvent } from 'file-selector';

import { ReactComponent as UploadIcon } from 'icons/upload.svg';

interface UploadFileButtonProps {
  onOpen?: () => void;
  onUpload: (files: Array<FileWithPath>) => void | Promise<void>;
  className?: string;
  noIcon?: boolean;
  children?: any;
}

export default function UploadFileButton({ onOpen, onUpload, className, children, noIcon }: UploadFileButtonProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // this state key is used to re-mount the input element
  // without this the input will keep it's value and selecting
  // the same file twice after another won't call the onChange a second time
  // this happens for example after uploading and deleting and then trying to upload the same file again
  const [inputKey, setInputKey] = useState(1);

  return (
    <>
      <input
        type={'file'}
        key={inputKey}
        accept="image/jpeg, application/pdf, image/png"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={async (evt) => {
          onUpload((await fromEvent(evt.nativeEvent)) as Array<FileWithPath>);
          setInputKey((k) => k + 1);
        }}
      />
      <button
        onClick={() => {
          onOpen && onOpen();
          inputRef.current && inputRef.current.click();
        }}
        className={className}
      >
        {
          !noIcon && (<UploadIcon className="stroke-swopa-grey-4" />)
        }
        {
          noIcon && (children)
        }
      </button>
    </>
  );
}
