import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ReactComponent as ChatIcon } from 'icons/chat.svg';
import DoctorsSelect from './DoctorsSelect';
import MessageForm from './MessageForm';
import MessageFeed from './MessageFeed';
import { useConsultingDoctors } from 'data/useDoctors';
import useUser from 'data/useUser';
import { useConsultations } from 'data/useConsultation';

type ConsultationProps = {
  patientId: number;
  appointmentId: number;
};

export default function Consultation(props: ConsultationProps) {
  const { patientId, appointmentId } = props;
  const placeholderOption = { id: 0, label: 'Bitte auswählen' };
  const [independentDoctors, setIndependentDoctors] = useState([]) as any;
  const [independentDoctor, setIndependentDoctor] = useState(placeholderOption) as any;
  const [initialRequest, setInitialRequest] = useState({}) as any;
  const [open, setOpen] = useState(false);
  const { data } = useConsultingDoctors();
  const {
    user: { managed_doctor },
  } = useUser();
  const { data: messages, mutate: mutateConsultations } = useConsultations(appointmentId);

  const isActiveConsultationRequest =
    messages?.length && initialRequest.appointment === appointmentId;
  const bgColor =
    isActiveConsultationRequest || open ? 'bg-swopa-secondary-light-blue' : 'bg-swopa-grey-1';
  const textColor =
    isActiveConsultationRequest || open ? 'text-white' : 'text-swopa-secondary-light-blue';
  const strokeColor =
    isActiveConsultationRequest || open ? 'stroke-white' : 'stroke-swopa-secondary-light-blue';
  const borderRadius = open ? 'rounded-t' : 'rounded';
  const borderColor = open || !(isActiveConsultationRequest || (managed_doctor && managed_doctor.id)) ? 'border-none' : 'border border-swopa-border';

  const handleToggle = () => setOpen(!open);

  const handleChange = (e: any) => {
    const selected = independentDoctors.filter(
      (doctor: any) => doctor.id === Number(e.target.value),
    )[0];
    setIndependentDoctor(selected);
  };

  useEffect(() => {
    const placeholderOption = { id: 0, label: 'Bitte auswählen' };
    if (data?.length) {
      setIndependentDoctors([placeholderOption, ...data]);
    }
    if (messages?.length) {
      const firstMessage = messages.at(messages.length - 1);
      setIndependentDoctor(firstMessage);
      setInitialRequest(firstMessage);
    }
  }, [data, messages]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('open')) {
      setOpen(true);
    }
  }, [window.location.search]);

  return (
    <div className={classNames('mb-6 rounded', borderColor)}>
      {
        (
          isActiveConsultationRequest ||
          (managed_doctor && managed_doctor.id)
        ) && (
          <button
            onClick={handleToggle}
            className={classNames(
              'flex items-center justify-center w-full py-2',
              bgColor,
              textColor,
              borderRadius,
              { 'hover:bg-swopa-secondary-light-blue-hover': isActiveConsultationRequest },
            )}
          >
            <ChatIcon className={classNames('mr-2', strokeColor)} />
            {isActiveConsultationRequest
              ? open
                ? 'Konsil ausblenden'
                : 'Konsil einblenden'
              : 'Konsil anfragen'}
          </button>
        )
      }

      {open && (
        <div className="p-4 bg-[#f7fbff] border border-swopa-secondary-light-blue rounded-b">
          {isActiveConsultationRequest ? (
            <>
              <span className="max-w-[50%] mb-6 bg-white w-full block text-xs text-swopa-primary-dark-blue p-3 border border-swopa-border rounded opacity-70">
                {independentDoctor?.reciever?.label}
              </span>
              <MessageFeed
                senderDoctor={managed_doctor}
                recieverId={initialRequest.from_doctor}
                messages={messages}
                refetchData={mutateConsultations}
              />
            </>
          ) : (
            <>
              <DoctorsSelect
                label="Konsiliararzt/-ärztin"
                name="doctors"
                options={independentDoctors}
                handleChange={handleChange}
                selectedValue={independentDoctor.id}
                className="border-swopa-border text-swopa-primary-dark-blue"
              />
              {
                managed_doctor && managed_doctor.id && (
                  <MessageForm
                    senderId={managed_doctor.id}
                    recieverId={independentDoctor.id}
                    appointmentId={appointmentId}
                    patientId={patientId}
                    parent={null}
                    refetchData={mutateConsultations}
                    label={`Konsilanfrage (${managed_doctor.label.trim()})`}
                    hideMessageForm={() => setOpen(false)}
                  />
                )
              }
            </>
          )}
        </div>
      )}
    </div>
  );
}
