// Backend doesn't accept empty strings for these values so let's convert them to null
export const handleEmptyStrings = (value: any) => {
  const keysWhosValuesCantBeEmptyStrings = [
    'amount',
    'share_of_thc',
    'share_of_cbd',
    'height',
    'weight',
    'rr',
    'spo2',
    'pulse_rate',
    'single_dosis',
    'max_daily_dosis',
  ];
  if (
    keysWhosValuesCantBeEmptyStrings.includes(Object.keys(value)[1]) &&
    Object.values(value)[1] === ''
  ) {
    return { ...value, [Object.keys(value)[1]]: null };
  }
  return value;
};
