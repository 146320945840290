import { useEffect, useState, ChangeEvent } from 'react';
import { Formik, Form } from 'formik';

import StatusSelect from 'components/inputs/StatusSelect';
import EventButton from 'components/EventButton';
import { ReactComponent as Info } from '../../../icons/info2.svg';
import { ReactComponent as CheckMark } from '../../../icons/checkmark.svg';
import Popper from 'components/Popper';
import Spinner from 'components/Spinner';

type StatusFormProps = {
  options: Array<any>;
  selectedOption: any;
  tooltip: string;
  label: string;
  name: string;
  handleSubmit: (status: string) => any;
  loading: boolean;
  finished: boolean;
  setFinished: (value: boolean) => any;
  error?: boolean;
};

export default function StatusForm(props: StatusFormProps) {
  const {
    options,
    selectedOption,
    tooltip,
    label,
    name,
    handleSubmit,
    loading,
    finished,
    setFinished,
    error = false,
  } = props;
  const defaultValue = options.filter((option) => option.value === selectedOption)[0];
  const [selected, setSelected] = useState(defaultValue);
  const [activeColor, setActiveColor] = useState('bg-transparent');
  const [touched, setTouched] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selected = options.filter((option) => option.value === e.target.value)[0];
    setSelected(selected);
    if (!touched) {
      setTouched(true);
    }
  };

  useEffect(() => {
    setActiveColor(selected?.color);
  }, [selected]);

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => setFinished(true), 2000);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  return (
    <Formik initialValues={options} onSubmit={() => handleSubmit(selected.value)}>
      {({ values }) => (
        <Form>
          <div className="grid grid-cols-2 gap-x-4">
            <StatusSelect
              label={label}
              name={name}
              options={values}
              handleChange={handleChange}
              activeColor={activeColor}
              defaultValue={selected?.value}
              selectedValue={selected?.value}
              error={error}
            />
            <div className="flex self-end items-center">
              <EventButton
                disabled={
                  !touched ||
                  !selected.value ||
                  selected.value === 'not_set' ||
                  selected.value === 'created' ||
                  selected.value === selectedOption
                }
                type="submit"
                className="mr-2 max-w-[135px] p-0 flex justify-center"
              >
                {loading ? (
                  <Spinner className="fill-white w-4 h-4" />
                ) : (
                  <>{!finished ? <CheckMark className="fill-white w-4 h-4" /> : 'Speichern'}</>
                )}
              </EventButton>
              <Popper
                triggerOnHover
                trigger={<Info />}
                content={() => (
                  <div className="bg-white p-3 drop-shadow rounded-lg max-w-[275px] text-center">
                    <span className="text-swopa-primary-dark-blue block text-xs">{tooltip}</span>
                  </div>
                )}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
