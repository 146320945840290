import classNames from 'classnames';
import Popper from 'components/Popper';
import DeleteIcon from './DeleteIcon';

type Props = {
  onDelete: () => void;
};

export default function DeleteMenu({ onDelete }: Props) {
  const baseButton = 'px-4 py-1 font-radikal rounded-[4px] w-full text-xs';
  return (
    <Popper
      trigger={<DeleteIcon className="hover:bg-swopa-warning-red-hover" />}
      content={({ setVisible }) => (
        <div className="bg-swopa-primary-white flex flex-col rounded p-2 drop-shadow">
          <button
            onClick={() => {
              setVisible(false);
              onDelete();
            }}
            className={classNames(
              baseButton,
              'bg-swopa-warning-red hover:bg-swopa-warning-red-hover text-white shadow-md',
            )}
          >
            Löschen
          </button>
          <button
            onClick={() => setVisible(false)}
            className={classNames(
              baseButton,
              'mt-2 text-swopa-grey-4 hover:text-swopa-secondary-light-blue',
            )}
          >
            Abbrechen
          </button>
        </div>
      )}
    />
  );
}
