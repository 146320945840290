import { Helmet } from 'react-helmet-async';
import { Link, Outlet } from 'react-router-dom';
import ErrorAlert from './ErrorAlert';
import Footer from './Footer';

export default function LightboxLayout() {
  return (
    <>
      <ErrorAlert />
      <Helmet>
        <title>Enmedify | Doctor App</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center bg-swopa-secondary-light-blue min-h-screen">
        <div className="grow flex items-center justify-center">
          <div className="p-4 md:p-0 relative">
            <Link to="/login" className="absolute -top-8 md:-top-12 left-4 md:left-0 ">
              <img
                src="logo.svg"
                alt="Enmedify | Doctor App"
                className="mb-4 self-start w-[116px]"
              />
            </Link>
            <div className="rounded-md bg-white px-4 md:px-20 py-6 flex flex-col xl:w-[580px] lg:w-[568px] md:w-[568px]">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="grow-0 self-stretch">
          <Footer invert />
        </div>
      </div>
    </>
  );
}
