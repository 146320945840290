import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export default function BlurryBackground({ content }: { content?: ReactNode }) {
  const portalDiv = document.getElementById('portal') || document.createElement('div');
  return createPortal(
    <div className="fixed top-0 left-0 w-full h-screen filter backdrop-blur-sm z-40">
      {content}
    </div>,
    portalDiv,
  );
}
