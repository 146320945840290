import SectionWrapper from './SectionWrapper';
import { Indication, deleteIndication, updateIndication, useIndications } from 'data/useIndication';
import { useFieldAutosave } from 'hooks/useAutoSave';
import { Form, Formik } from 'formik';
import SectionFooter from './SectionFooter';
import { format } from 'date-fns';
import Toggle, { ToggleProps } from 'components/inputs/Toggle';
import DeleteMenu from './DeleteMenu';
import { useAppointmentContext } from 'contexts/Appointment';

export default function IndicationSection({
  item,
  expanded,
  appointmentId,
}: {
  item: Indication;
  expanded: boolean;
  appointmentId: number;
}) {
  const { mutate } = useIndications(item.patient, appointmentId);

  const handleRefetchData = async () => await mutate();

  const { refreshState } = useAppointmentContext();

  const doctorFirstName = item?.doctor?.first_name || '';
  const doctorLastName = item?.doctor?.last_name || '';

  return (
    <SectionWrapper
      expanded={expanded}
      handleRefetchData={handleRefetchData}
      headline={'Indikation'}
      content={
        <Formik initialValues={item} onSubmit={() => {}} enableReinitialize>
          {({ values, handleChange }) => (
            <Form>
              <AutoSaveCheckbox
                className="mt-4"
                label={'Indikation für Therapieversuch mit med. Cannabis ist (weiterhin) gegeben.'}
                id={'indication_for_cannabis_therapy'}
                name={'indication_for_cannabis_therapy'}
                value={values.indication_for_cannabis_therapy.toString()}
                checked={values.indication_for_cannabis_therapy}
                onChange={handleChange}
              />
              <AutoSaveCheckbox
                className="mt-4"
                label={'CBD indiziert?'}
                id={'indication_for_cbd_therapy'}
                name={'indication_for_cbd_therapy'}
                value={values.indication_for_cbd_therapy.toString()}
                checked={values.indication_for_cbd_therapy}
                onChange={handleChange}
              />
              <AutoSaveCheckbox
                className="mt-4"
                label={
                  'Konsiliarische Mitbeurteilung bzgl. ganzheitlicher Therapieplanung erbeten.'
                }
                id={'request_conciliatory_assessment'}
                name={'request_conciliatory_assessment'}
                value={values.request_conciliatory_assessment.toString()}
                checked={values.request_conciliatory_assessment}
                onChange={handleChange}
              />
            </Form>
          )}
        </Formik>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.created_at), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    await deleteIndication(item.id);
                    await mutate();
                    refreshState();
                  }}
                />
              </div>
            </div>
          }
          right={
            <span className="text-xs">
              {doctorFirstName} {doctorLastName}
            </span>
          }
        />
      }
    />
  );
}

function AutoSaveCheckbox(props: ToggleProps & { name: string }) {
  const { loading } = useFieldAutosave({ update: updateIndication, name: props.name });
  return <Toggle {...props} checked={props.checked} type="checkbox" disabled={loading} />;
}
