import { object, string } from 'yup';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import PasswordInput from 'components/inputs/FormikPasswordInput';
import {
  PasswordRepeatSubForm,
  PasswordRepeatSubFormFields,
  validationSchema as passwordValidationSchema,
  defaultValues as passwordDefaultValues,
} from 'components/Profile/PasswordRepeat';
import { patch } from 'data/http';
import { useContext } from 'react';
import { GlobalErrorContext } from 'contexts/GlobalError';

type ChangePasswordFormValues = {
  currentPassword: string;
} & PasswordRepeatSubFormFields;

const initialValues: ChangePasswordFormValues = {
  currentPassword: '',
  ...passwordDefaultValues,
};

const PASSWORD_REQUIRED_ERROR = 'Bitte geben Sie ein Passwort ein.';

const validatonSchema = object({
  currentPassword: string().required(PASSWORD_REQUIRED_ERROR),
}).concat(passwordValidationSchema);

export default function ChangePasswordForm() {
  const { setSuccess, setError } = useContext(GlobalErrorContext);

  return (
    <Formik<ChangePasswordFormValues>
      initialValues={initialValues}
      validationSchema={validatonSchema}
      validateOnChange={false}
      onSubmit={async (formData, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const response = await patch('auth/users/me/', {
            password: formData.password,
            old_password: formData.currentPassword,
          });
          if (response.status === 204) {
            setSuccess('Passwort erfolgreich geändert');
            resetForm();
          } else {
            setError(response.msg);
          }
        } catch (err: any) {
          // todo: handle errors
          console.log('err', err);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <div className="max-w-sm">
            <label className="text-swopa-secondary-light-blue uppercase text-pre-title font-radikal">
              Altes Passwort
            </label>
            <PasswordInput
              id="currentPassword"
              name="currentPassword"
              error={!!(formikProps.touched.currentPassword && formikProps.errors.currentPassword)}
              errorMessage={formikProps.errors.currentPassword}
              className="mb-6"
            />
            <PasswordRepeatSubForm />
          </div>
          <div className="w-[220px]">
            <Button type="submit" disabled={formikProps.isSubmitting}>
              PASSWORT ÄNDERN
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
