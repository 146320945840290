import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

export default function DocumentUploadScreen({ content }: { content: ReactNode }) {
  const portalDiv = document.getElementById('portal') || document.createElement('div');
  return createPortal(
    <div className="p-16 fixed top-0 left-0 w-full h-screen bg-swopa-secondary-light-blue/50 filter backdrop-blur-md text-swopa-primary-white z-40 flex items-center justify-center">
      {content}
    </div>,
    portalDiv,
  );
}
