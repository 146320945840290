import { useState, useEffect, useMemo, useContext } from 'react';
import Box from './Box';
import { ReactComponent as CheckIcon } from 'icons/checkmark.svg';
import { ReactComponent as CancelIcon } from 'icons/delete.svg';
import http from 'components/Profile/http';
import axios from 'axios';
import { GlobalErrorContext } from 'contexts/GlobalError';

interface DocumentUploadProps {
  filename: string;
  asset: string;
  onCancel: () => void;
  onComplete: () => void;
  patientId: number;
}

export default function DocumentUpload({
  filename,
  asset,
  onCancel,
  onComplete,
  patientId,
}: DocumentUploadProps) {
  const { setError } = useContext(GlobalErrorContext);
  const [progress, setProgress] = useState(0);
  const [finished, setFinished] = useState(false);
  const cancelToken = useMemo(() => {
    return axios.CancelToken.source();
  }, []);

  useEffect(() => {
    const config = {
      cancelToken: cancelToken.token,
      onUploadProgress: (progressEvent: any) =>
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
    };
    if (!asset) return;
    http.post(`/medical/documents/?patient=${patientId}`, { asset, name: filename }, config).then(
      (res) => {
        setFinished(true);
        onComplete();
      },
      () => {
        setError(`Beim Upload von ${filename} ist ein Fehler aufgetreten`);
      },
    );
    // this useEffect is not specifying all dependencies on purpose:
    // We don't want to re-run it if a callback changes because that would upload the same file twice
  }, [asset, filename]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box border={finished ? 'green' : undefined}>
      <div className={`${finished ? 'bg-transparent' : 'bg-swopa-border'} relative`}>
        <div className="relative flex py-[14px] px-4 sm:px-6 z-10 sm:flex-row justify-between sm:items-center">
          <div className="grid">
            <span className="font-radikal text-swopa-primary-dark-blue">{filename}</span>
            <span
              className={`${
                finished ? 'text-swopa-primary-dark-blue' : 'text-swopa-secondary-grey'
              } text-documents-info`}
            >
              {finished ? 'Die Datei wurde erfolgreich hochgeladen.' : 'Datei wird hochgeladen ...'}
            </span>
          </div>
          {progress !== 100 ? (
            <CancelIcon
              className="text-swopa-warning-red cursor-pointer"
              onClick={() => {
                cancelToken.cancel('Upload cancelled');
                onCancel();
              }}
            />
          ) : (
            <CheckIcon className="text-swopa-accent-green w-6 h-6" />
          )}
        </div>
        <div
          style={{ right: 100 - progress + '%' }}
          className={`absolute rounded-lg ${
            finished ? 'bg-swopa-accent-green opacity-[0.08]' : 'bg-white'
          } top-0 z-0 left-0 bottom-0`}
        ></div>
      </div>
    </Box>
  );
}
