import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import Popper from 'components/Popper';
import DoctorsList from './DoctorsList';
import { assignDoctor, useMutatePatient, Patient } from 'data/usePatients';
import { Doctor } from 'types/doctor';
import useUser from 'data/useUser';

type Props = {
  assignedDoctors: Array<any>;
  sizeClass?: string;
  moreOnLeft?: boolean;
  patient: Patient;
};

const DoctorsCircles = (props: Props) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { assignedDoctors, moreOnLeft, sizeClass, patient } = props;
  const { mutate: mutatePatient } = useMutatePatient(patient.id, shouldFetch);
  const { user } = useUser();
  const [showList, setShowList] = useState(false);
  const visibleDoctors = assignedDoctors.slice(0, 3);
  const remaingDoctors = assignedDoctors.slice(3);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      if (!wrapper.contains(e.target)) {
        setShowList(false);
      }
    }
  };

  const onDoctorDelete = async (doctor: Doctor) => {
    const doctors = patient.doctors.filter((id: number) => id !== doctor.id);
    await assignDoctor({
      patient: patient.id,
      doctors: doctors,
    });
    setShouldFetch(true);
    setShowList(false);
  };

  useEffect(() => {
    const refetchPatientData = async () => {
      await mutatePatient();
    };
    refetchPatientData();
  }, [shouldFetch]);

  useEffect(() => {
    if (showList) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showList]);

  return (
    <div
      className="doctors-list flex cursor-pointer relative"
      onClick={() => setShowList(!showList)}
    >
      {!moreOnLeft && remaingDoctors.length > 0 && (
        <div
          className={classnames(
            'rounded-full mr-2 text-center text-swopa-grey-4 flex-none ',
            sizeClass || 'ml-[-16px] w-9 h-9 leading-[2.15rem] text-xs',
          )}
        >
          +{remaingDoctors.length}
        </div>
      )}
      {visibleDoctors.map((doctor, index) => (
        <Popper
          key={index}
          triggerOnHover
          className="flex"
          isDoctorCircle
          trigger={
            <span
              className={classnames(
                'block overflow-hidden bg-[#16DF9F] border border-white rounded-full mr-2 text-center text-white flex-none',
                sizeClass || 'ml-[-16px] leading-[2.15rem] w-9 h-9  text-xs',
              )}
            >
              {doctor.profile_picture && (
                <img className="rounded-full" src={doctor.profile_picture} alt="" />
              )}
              {!doctor.profile_picture && <span>{doctor.short_name}</span>}
            </span>
          }
          content={() =>
            !showList && (
              <div className="bg-white p-3 w-max drop-shadow rounded-lg text-center">
                <span className="text-swopa-primary-dark-blue text-xs">{doctor.label}</span>
              </div>
            )
          }
        />
      ))}
      {moreOnLeft && remaingDoctors.length > 0 && (
        <div
          className={classnames(
            'rounded-full mr-2 text-center text-swopa-grey-4 flex-none',
            sizeClass || 'ml-[-16px] w-9 h-9 leading-[2.15rem] text-xs',
          )}
        >
          +{remaingDoctors.length}
        </div>
      )}
      {showList && (
        <div
          ref={wrapperRef}
          className="shadow rounded searchable-list pb-[2px] pt-[3px] absolute w-64 z-50 bg-white top-[40px] right-0"
        >
          <DoctorsList
            allowDelete={user.is_admin}
            doctors={assignedDoctors}
            onClick={() => {}}
            onDelete={onDoctorDelete}
          />
        </div>
      )}
    </div>
  );
};

export default DoctorsCircles;
