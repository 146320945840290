import classNames from 'classnames';
import { NavLink, LinkProps } from 'react-router-dom';
import { ReactComponent as Patients } from 'icons/patients.svg';
import { ReactComponent as Calendar } from 'icons/calendar.svg';
import { ReactComponent as Logout } from 'icons/logout.svg';
import { ReactComponent as Menu } from 'icons/menu.svg';
import { ReactComponent as Close } from 'icons/close.svg';
import useUser from 'data/useUser';
import { useEffect, useState } from 'react';

export default function Navigation() {
  const { logout } = useUser();
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (showNav) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, [showNav]);

  return (
    <>
      <nav className="flex justify-between items-center h-[calc(80px-0.2rem)]">
        <img className="p-2 max-w-[150px]" src="/logo.svg" alt="Enmedify | Doctor App" />
        <div className="hidden lg:flex self-center items-stretch h-full">
          <CustomLink to="/patients">
            <Patients className="mr-4" />
            Patienten
          </CustomLink>
          <CustomLink to="/appointments">
            <Calendar className="mr-4" />
            Termine
          </CustomLink>
        </div>
        <div className="hidden lg:flex self-center items-stretch h-full">
          <CustomLink to="/profile">
            <Avatar />
            <Logout
              className="ml-4"
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
            />
          </CustomLink>
        </div>
        <div className="xs:flex lg:hidden flex space-between self-center items-center h-full cursor-pointer stroke-white p-4">
          <Menu width="24" height="24" onClick={() => setShowNav(true)} />
        </div>
      </nav>
      {showNav && (
        <div className="h-full w-full top-0 left-0 absolute bg-swopa-secondary-light-blue z-10">
          <div className="flex justify-between items-center h-[calc(80px-0.2rem)] container mx-auto">
            <div
              className="text-swopa-primary-white flex items-center p-4"
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
            >
              <Logout className="mx-4 stroke-current" />
              <span>Abmelden</span>
            </div>
            <div className="p-4">
              <Close
                className="stroke-white"
                width="24"
                height="24"
                onClick={() => setShowNav(false)}
              />
            </div>
          </div>
          <div className="flex flex-col items-center p-20 h-full text-swopa-primary-white">
            <h1 className="text-swopa-primary-white mb-4">Menü</h1>
            <CustomMobileLink to="/patients" onClick={() => setShowNav(false)}>
              <Patients className="mr-4" />
              Patienten
            </CustomMobileLink>
            <CustomMobileLink to="/appointments" onClick={() => setShowNav(false)}>
              <Calendar className="mr-4" />
              Termine
            </CustomMobileLink>
          </div>
        </div>
      )}
    </>
  );
}

function CustomLink({ children, to, ...props }: LinkProps) {
  const shared =
    'border-b-solid border-b-2 flex items-center p-4 hover:bg-swopa-secondary-light-blue-hover';
  const activeClass =
    'bg-swopa-secondary-light-blue-active border-b-swopa-accent-green text-swopa-accent-green stroke-swopa-accent-green';
  const inactiveClass =
    'border-b-swopa-secondary-light-blue hover:border-b-swopa-secondary-light-blue-hover stroke-white';
  return (
    <NavLink
      className={({ isActive }) => classNames(shared, isActive ? activeClass : inactiveClass)}
      to={to}
      {...props}
    >
      <span className="flex items-center">{children}</span>
    </NavLink>
  );
}

function CustomMobileLink({ children, to, ...props }: LinkProps) {
  const shared = 'flex items-center p-4 hover:bg-swopa-secondary-light-blue-hover';
  const activeClass = 'text-swopa-accent-green stroke-swopa-accent-green';
  const inactiveClass = 'stroke-white';
  return (
    <NavLink
      className={({ isActive }) => classNames(shared, isActive ? activeClass : inactiveClass)}
      to={to}
      {...props}
    >
      <span className="flex items-center">{children}</span>
    </NavLink>
  );
}

function Avatar() {
  const { user } = useUser();

  return <div>{user?.email}</div>;
}
