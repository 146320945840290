import { ReactNode } from 'react';

type Props = {
  left?: ReactNode;
  right?: ReactNode;
};

export default function SectionFooter({ left, right }: Props) {
  return (
    <div className="flex justify-between">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
}
