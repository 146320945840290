import classNames from 'classnames';
import Spinner from '../Spinner';

export type SelectProps<Options extends { value: string | number; label: string }> =
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    error?: string;
    loading?: boolean;
    label?: string;
    options: Array<Options>;
    customClass?: string;
  };

export default function Select<Options extends { value: string | number; label: string }>(
  props: SelectProps<Options>,
) {
  const { error, label, loading, options, customClass, ...rest } = props;

  const className = classNames(
    'w-full bg-transparent outline-none border-b border-transparent focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue placeholder-swopa-grey-4',
    {
      'text-swopa-primary-dark-blue': !error,
      'text-swopa-warning-red border-current': error,
    },
  );

  return (
    <div className={classNames(props.className, '')}>
      {label && (
        <label htmlFor={rest.name} className="block text-xs mb-1">
          {label}
        </label>
      )}
      <div className="flex group text-swopa-primary-dark-blue w-full">
        <select {...rest} className={classNames(className, customClass)}>
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {loading && <Spinner className="self-center fill-current ml-2" />}
      </div>
      {error && <div className="text-swopa-warning-red">{error}</div>}
    </div>
  );
}
