export const baseUrl = process.env.REACT_APP_BACKEND_URL;

export async function baseFetcher(resource: RequestInfo, init?: RequestInit | undefined) {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': 'de',
    accept: 'application/json',
  };
  let options = { ...init, headers: { ...headers, ...(init?.headers as any) } };
  let urlOrRequest = resource;

  if (typeof resource === 'string') {
    urlOrRequest = new URL(resource, baseUrl).toString();
  }

  if (token) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        authorization: `Bearer ${token}`,
      },
    };
  }

  try {
    const res = await fetch(urlOrRequest, options);

    if (res.status === 204) {
      return { status: 204 };
    }

    if (res.status === 400) {
      const json = await res.json();
      const msg = (Object?.values(json)[0] as any)[0] as any;
      return { error: true, status: 400, msg };
    }

    if (res.status === 401) {
      const json = await res.json();
      const msg = Object?.values(json)[0] as any;
      return { error: true, status: 401, msg };
    }

    if (!res.ok) {
      const error: any = new Error('An error occurred while fetching the data.');
      error.status = res.status;
      throw error;
    }

    const json = await res.json();
    return json;
  } catch (error) {
    // TODO: handle other errors
  }
}
