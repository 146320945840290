import { useState, useEffect } from 'react';

export default function useLoading(functionOnFinish: any = false, delay = 2000) {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(true);

  useEffect(() => {
    let timeout: any;
    if (!loading && !finished) {
      timeout = setTimeout(() => {
        setFinished(true);
        functionOnFinish && functionOnFinish();
      }, delay);
    }
    return () => clearTimeout(timeout);
  }, [finished, setFinished, loading]);

  return {
    loading,
    setLoading,
    finished,
    setFinished,
  };
}
