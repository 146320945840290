import { ChangeEvent, useState, useEffect } from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';
import { ReactComponent as CheckMark } from 'icons/checkmark.svg';
import PublicTooltip from 'components/PublicTooltip';
import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';
import { ReactComponent as Info } from 'icons/info3.svg';

export type SelectProps<Options extends { value: string | number; label: string }> =
  React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & {
    error?: string;
    loading?: boolean;
    finished?: boolean;
    isPublic?: boolean;
    isFullWidth?: boolean;
    overwriteList?: string[];
    avoidOverwriteList?: string[];
    label: string;
    options: Array<Options>;
  };

export default function Select<Options extends { value: string | number; label: string }>(
  props: SelectProps<Options>,
) {
  const {
    error,
    label,
    loading,
    finished,
    options,
    isPublic,
    overwriteList,
    avoidOverwriteList,
    ...rest
  } = props;
  const { onChange } = rest;
  const [isOverwritten, setIsOverwritten] = useState<boolean>(false);

  const doChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setIsOverwritten(false);
    onChange && onChange(ev);
  };

  useEffect(() => {
    if (overwriteList) {
      setIsOverwritten(overwriteList?.indexOf(props.name || '') !== -1);
    } else {
      setIsOverwritten(false);
    }
  }, [overwriteList]);

  const className = classNames(
    `rounded-md relative p-2 bg-swopa-white text-sm focus:outline-none focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue`,
    {
      'text-swopa-primary-dark-blue': !error,
      'text-swopa-warning-red border-current': error,
      'w-full': rest.isFullWidth || false,
    },
    {
      'border-solid border-swopa-grey-3 border bg-white': !isOverwritten,
    },
    {
      'border border-solid border-swopa-secondary-light-blue bg-sky-100': isOverwritten,
    },
  );

  return (
    <div className={classNames(props.className, '')}>
      <div className="flex">
        {label && (
          <div className="text-xs mb-2 grow flex">
            <label htmlFor={rest.name}>{label}</label>
            {avoidOverwriteList && avoidOverwriteList?.indexOf(rest.name as string) !== -1 && (
              <div className="ml-1">
                <Info />
              </div>
            )}
          </div>
        )}
        {isPublic && <PublicTooltip />}
      </div>
      <div className="flex group text-swopa-primary-dark-blue">
        <div className={classNames(className, 'w-full')}>
          <ArrowDown className="absolute top-4 right-4 stroke-swopa-secondary-grey pointer-events-none" />
          <select {...rest} onChange={doChange} className="outline-none w-full">
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {loading ? (
          <Spinner className="self-center fill-current ml-2" />
        ) : (
          <>{!finished && <CheckMark className="self-center ml-2" />}</>
        )}
      </div>
      {error && <div className="text-swopa-warning-red">{error}</div>}
    </div>
  );
}
