import { useEffect, useContext } from 'react';
import { FileWithPath } from 'react-dropzone';
import classNames from 'classnames';

import { ReactComponent as EditIcon } from 'icons/edit.svg';
import DocumentUploadScreen from 'components/Documents/DocumentUploadScreen';
import MyDocuments from 'components/Documents/MyDocuments';
import { useSidebarContext } from 'contexts/Sidebar';
import { Document } from 'data/useDocuments';
import UploadFileButton from 'components/Documents/UploadFileButton';
import { readFileAsync } from 'utils/files';
import { GlobalErrorContext } from 'contexts/GlobalError';
import http from 'components/Profile/http';
import useShowUploadOverlayState from 'hooks/useShowUploadOverlayState';
import DocumentDropZone from 'components/Documents/DocumentDropZone';
import { ReactComponent as ArrowDown } from 'icons/arrow_down.svg';

export default function DocumentActions({
  documents,
  patientId,
  setLoading,
  setFinished,
  refetchDocuments,
  handleToggle,
  open,
}: {
  documents: Array<Document>;
  patientId: number;
  setLoading: (value: boolean) => void;
  setFinished: (value: boolean) => void;
  refetchDocuments: () => any;
  handleToggle: () => any;
  open: boolean;
}) {
  const { state, dispatch } = useSidebarContext();
  const { setError } = useContext(GlobalErrorContext);
  const showUpload = useShowUploadOverlayState(state.uploadableFiles);

  const showDocumentUploadScreen = () => {
    dispatch({ type: 'SHOW_MY_DOCUMENTS_SCREEN', payload: { show: true, uploadableFiles: [] } });
  };

  const showMyDocumentsScreen = (files: any) => {
    dispatch({ type: 'SHOW_MY_DOCUMENTS_SCREEN', payload: { show: true, uploadableFiles: files } });
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        dispatch({
          type: 'SHOW_MY_DOCUMENTS_SCREEN',
          payload: { show: false, uploadableFiles: [] },
        });
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const handleDocumentUpload = async (files: Array<FileWithPath>) => {
    const TYPES = ['image/jpeg', 'application/pdf', 'image/png'];

    for (let i = 0; i < files.length; ++i) {
      const filesize = (files[i].size / 1024 / 1024).toFixed(4);
      if (Number(filesize) > 20) {
        setError('Ihre Datei ist leider größer als 20MB. Bitte versuchen Sie es erneut');
        return;
      }
      if (!TYPES.find((type) => type === files[i].type)) {
        setError('Das Dateiformat wird leider nicht unterstützt.');
        return;
      }
    }

    const assets = await Promise.all(files.map((file) => readFileAsync(file)));

    const forUpload = files.map(({ name }, index) => ({
      filename: name,
      asset: assets[index] + '',
    }));

    const { filename, asset } = forUpload[0];

    try {
      setLoading(true);
      setFinished(false);
      await http.post(`/medical/documents/?patient=${patientId}`, { asset, name: filename });
      refetchDocuments && (await refetchDocuments());
      setLoading(false);
    } catch (error: any) {
      setError(`Beim Upload von ${filename} ist ein Fehler aufgetreten`);
      setLoading(false);
    }
  };

  return (
    <>
      {documents?.length > 0 && (
        <button className="outline-none py-3 px-2 h-full" onClick={showDocumentUploadScreen}>
          <EditIcon className="stroke-swopa-grey-4" />
        </button>
      )}

      <UploadFileButton className="py-3 px-2 h-full" onUpload={handleDocumentUpload} />

      <button onClick={handleToggle} className="py-3 pl-2 pr-4 h-full">
        <ArrowDown
          className={classNames('stroke-swopa-grey-4 cursor-pointer', {
            'rotate-180': open,
          })}
        />
      </button>

      {state.showMyDocumentsScreen && (
        <DocumentUploadScreen content={<MyDocuments patientId={patientId} />} />
      )}

      {showUpload && !state.showMyDocumentsScreen && (
        <DocumentUploadScreen content={<DocumentDropZone onDrop={showMyDocumentsScreen} />} />
      )}
    </>
  );
}
