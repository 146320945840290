import { baseFetcher } from './baseFetcher';

export function post<T>(url: string, body: T) {
  return baseFetcher(url, { method: 'POST', body: JSON.stringify(body) });
}

export function put<T>(url: string, body: T) {
  return baseFetcher(url, { method: 'PUT', body: JSON.stringify(body) });
}

export function patch<T>(url: string, body: T) {
  return baseFetcher(url, { method: 'PATCH', body: JSON.stringify(body) });
}

export function httpDelete(url: string, body?: any) {
  if (body) {
    return baseFetcher(url, { method: 'DELETE', body: JSON.stringify(body) });
  } else {
    return baseFetcher(url, { method: 'DELETE' });
  }
}
