import { useState } from 'react';
import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { Note } from 'data/useNotes';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const NoteRenderer = ({ item }: { item: Note }) => {
  const [isExpanded, setIsExpended] = useState(false);
  const [charactersLimit, setCharactersLimit] = useState(150);
  const navigate = useNavigate();
  const date = format(new Date(item.created_at), 'dd.MM.yyyy');
  const appointmentDate = format(new Date(item.created_at), 'dd.MM.yyyy');

  const openAppointment = (item: any) => {
    navigate(`/patients/${item.patient}?open=${item.appointment}&now=${Date.now()}`);
  };

  return (
    <div key={item.id} className="border-b last-of-type:border-0 pb-3 mb-2 border-swopa-border ">
      <div className="flex">
        <div className="grow">
          <span>{item?.doctor?.label}</span>
        </div>
        <div className="flex-none text-swopa-grey-4">{date}</div>
      </div>
      <div>
        <p className={classNames('text-swopa-grey-4')}>
          {item.content.length > charactersLimit && !isExpanded
            ? `${item.content.substring(0, charactersLimit)}...`
            : item.content}
        </p>
        {item.content.length > charactersLimit && (
          <span
            onClick={() => setIsExpended(!isExpanded)}
            className="underline text-swopa-secondary-light-blue cursor-pointer"
          >
            {isExpanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </span>
        )}
      </div>
      <div>
        {
          (item.appointment && item.appointment !== 0) && (
            <div
              onClick={() => openAppointment(item)}
              className="w-full cursor-pointer my-3 py-2 text-center text-swopa-secondary-light-blue bg-[#e0edfe] rounded text-xs font-radikal uppercase">
              TERMIN VOM {appointmentDate}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default function NotesList({ notes }: { notes: Array<Note> }) {
  const [showLimit, setShowLimit] = useState(3);
  if (!notes?.length) {
    return (
      <div className="px-4 pb-4 mb-4">
        <div className="border text-center opacity-70 p-8 rounded border-dashed border-swopa-secondary-light-blue">
          <span className="text-swopa-secondary-light-blue">keine Notizen</span>
        </div>
      </div>
    );
  }

  let items = orderBy(notes, ['created_at'], ['desc']);

  items = showLimit === 0 ? items : items.slice(0, 3);

  return (
    <div className="pb-3">
      <div
        className="border-b mx-4 pb-2 mb-2 last-of-type:border-0 last-of-type:pb-0 last-of-type:mb-0"
      >
        {
          items.map((item) => <NoteRenderer key={item.id} item={item} />)
        }
        {
          showLimit > 0 && (
            <div className="py-2">
              <span
                onClick={() => setShowLimit(0)}
                className="text-sm cursor-pointer text-swopa-secondary-light-blue">
                + Weitere einblenden
              </span>
            </div>
          )
        }
      </div>
    </div>
  );
}
