import classNames from 'classnames';

import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg';

type Props = {
  options: Array<any>;
  handleChange: (e: any) => any;
  activeColor?: string;
  defaultValue: string;
  name: string;
  label?: string;
  selectedValue?: string | any;
  error?: boolean;
  className?: string;
};

export default function StatusSelect(props: Props) {
  const {
    options,
    handleChange,
    activeColor,
    defaultValue,
    name,
    label,
    error,
    className,
    selectedValue,
  } = props;
  return (
    <div className="flex w-full flex-col">
      {label && (
        <span
          className={classNames('text-swopa-primary-dark-blue text-xs mb-2 block', {
            'text-swopa-warning-red': error,
          })}
        >
          {label}
        </span>
      )}
      <div className="relative">
        <ArrowDown className="absolute top-[50%] translate-y-[-50%] right-4 stroke-swopa-secondary-grey pointer-events-none" />
        {activeColor && (
          <span
            className={classNames(
              activeColor,
              'w-2 h-2 rounded-full block absolute left-3 top-[50%] translate-y-[-50%] pointer-events-none',
            )}
          ></span>
        )}
        <select
          onChange={handleChange}
          className={classNames(
            `w-full border-solid border-swopa-grey-3 border bg-white rounded-md py-3 ${
              activeColor ? 'px-6' : 'px-2'
            } bg-swopa-white text-xs focus:outline-none focus:border-swopa-secondary-light-blue hover:border-swopa-secondary-light-blue group-hover:border-swopa-secondary-light-blue mr-4`,
            { 'border-swopa-warning-red': error },
            className,
            {
              '!text-swopa-grey-4 pl-2':
                selectedValue === '' ||
                selectedValue === 'not_set' ||
                selectedValue === 'created' ||
                defaultValue === 'not_set' ||
                (defaultValue === options[0].value && options[0].value === ''),
            },
          )}
          name={name}
          value={defaultValue}
        >
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
