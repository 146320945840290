import { useState } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';

import { ReactComponent as EyeOn } from 'icons/eye_on.svg';
import { ReactComponent as EyeOff } from 'icons/eye_off.svg';

interface InputProps {
  id?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  isPasswordInput?: boolean;
}

export default function Input(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const type = showPassword ? 'text' : props.type;

  const styles =
    'rounded w-full py-3 px-4 text-link focus:outline-[1px] focus:outline-solid focus:outline-swopa-secondary-light-blue text-swopa-primary-dark bg-swopa-hover-grey';
  return (
    <div className={`relative w-full ${props.className ? props.className : ''}`}>
      <div className="relative">
        <Field
          id={props.id}
          name={props.name}
          type={type}
          placeholder={props.placeholder}
          className={classNames(styles, {
            'border border-swopa-warning-red text-swopa-warning-red placeholder:text-swopa-warning-red':
              props.error,
          })}
        />
        {props.isPasswordInput && (
          <>
            {showPassword ? (
              <EyeOn
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer absolute right-2 top-[50%] translate-y-[-50%]"
              />
            ) : (
              <EyeOff
                onClick={() => setShowPassword(!showPassword)}
                className="cursor-pointer absolute right-2 top-[50%] translate-y-[-50%] stroke-swopa-secondary-light-blue"
              />
            )}
          </>
        )}
      </div>
      {props.error && props.errorMessage && (
        <span className="text-swopa-warning-red text-field-validation mt-2">
          {props.errorMessage}
        </span>
      )}
    </div>
  );
}
